import Model from './Model';

class Offer extends Model {
  contractCompositionId = '';
  description = '';
  name = '';
  shortDescription = '';
  startingRate = 0;
  termInMonths = '';
  title = '';
  type = '';
  minimumFico8Score = '';
  isLmi = false;
  subscriberReferralsEnabled = false;

  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(Offer.fromJSON);
    }

    const offer = new Offer();

    return offer.mapValues(offer, json);
  }
}

export default Offer;
