import { handleActions } from 'redux-actions';

const initialState = {
  residentialSignup: {
    inquiry: {
      title: 'Let&rsquo;s see if there are active solar projects in your area',
      body: 'This process should take you <strong>5 minutes or less</strong> to complete.'
    },

    inquirySuccess: {
      title: 'Good News!',
      body:
        'There are community solar projects in your area, let&rsquo;s get you an account so we can track your progress.'
    },

    inquiryFailure: {
      title: 'Sorry, we don&rsquo;t have a project for you',
      body: 'We&rsquo;ll keep in touch if that changes.'
    },

    verifyAccount: {
      title: 'Great News!',
      body:
        'There are community solar projects in your area. Let us set you up with an account so we can track your progress.'
    },

    setPassword: {
      title: 'Thank you for confirming your account',
      body: ''
    },

    creditCheck: {
      title: 'Let&rsquo;s check your credit',
      body:
        'To participate in the program you selected for this project, community solar subscribers need a credit score of X or higher. We will confirm your eligibility with a soft credit check. A soft credit check does not appear on your credit report, and will not affect your credit score.'
    },

    connectUtility: {
      title: 'Let&rsquo;s Connect to your Utility',
      body:
        'We will use this login to securely connect to your utility to pull up your account(s), and access your and rate information. If you would like more information about this connection, please refer to our FAQs below.'
    },

    addUtility: {
      title: 'Let&rsquo;s add your Utility Information',
      body: ''
    },

    confirmUtility: {
      title: 'Confirm Utility Information',
      body:
        'We will need to confirm this information in order to ensure that we give you the most accurate rate information and that you receive credits on your utility bill.'
    },

    plans: {
      title: 'Select Your Plan',
      body:
        'Select the plan that is best for you. You can get in-depth details about all of the plans in our FAQs below.'
    },

    homepage: {
      title: 'Choose Solar.<br />Go, Save, and Get Green.',
      subtitle: '',
      lead:
        "Community Solar is becoming a popular method to access solar power around the country and getting access to one in New York State is now easier than ever. Whether you rent, live in multi-dwelling building, or solar on your home or business doesn't quite make sense, enroll in Community Solar today. No investment required!",
      blocks: [
        {
          title: 'What is a Community Solar Farm?',
          body:
            'Community Solar Farms are nearby arrays of solar panels filled with subscribers like you. Locally-produced solar power provides utility bill credits at a discount to utility rates.'
        },
        {
          title: 'Does my home qualify?',
          body:
            'If you are within the right area and are served by the same utility company as the Farm, you should qualify without an issue.'
        }
      ],
      media:
        "<div style='position: relative; padding-bottom: 50%; padding-top: 30px; height: 0px; overflow: hidden;'><iframe allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen='' frameborder='0' height='315' src='https://www.youtube.com/embed/vmV_3trbsQo' title='Community Solar' width='560' style='position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;'></iframe></div>",
      form: { title: 'Start your savings & impact' }
    },

    shouldShowNotYetCompleteMessage: false
  }
};

export const reducer = handleActions(
  {},

  initialState
);
