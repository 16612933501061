import React from 'react';
import { Button } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import styles from './styles';

export function LoginForm() {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
      <div css={styles}>
        <Button block color="primary" size="lg" onClick={() => loginWithRedirect()}>
          Continue to Login
        </Button>
      </div>
    </>
  );
}
