import { createAction } from 'redux-actions';

const MODULE = 'app/invoices';

const CLEAR_PREFLIGHT = `${MODULE}/CLEAR_PREFLIGHT`;
const CREATE_FROM_FILTERS = `${MODULE}/CREATE_FROM_FILTERS`;
const CREATE_REMAINING = `${MODULE}/CREATE_REMAINING`;
const CREATE_WITH_CUSTOMERS = `${MODULE}/CREATE_WITH_CUSTOMERS`;
const DELETE_CUSTOMER_FROM_BILLING = `${MODULE}/DELETE_CUSTOMER_FROM_BILLING`;
const DELETE_ALL_CUSTOMERS_FROM_BILLING = `${MODULE}/DELETE_ALL_CUSTOMERS_FROM_BILLING`;
const DELETE_ALL_CUSTOMERS_FROM_BILLING_PREFLIGHT = `${MODULE}/DELETE_ALL_CUSTOMERS_FROM_BILLING_PREFLIGHT`;
const DELETE_INVOICES = `${MODULE}/DELETE_INVOICES`;
const FETCHING_INVOICES = `${MODULE}/FETCHING_INVOICES`;
const FINALIZE_INVOICES = `${MODULE}/FINALIZE_INVOICES`;
const GENERATE_INVOICES_STATUS_EXPORT = `${MODULE}/GENERATE_INVOICES_STATUS_EXPORT`;
const GET_BILLING = `${MODULE}/GET_BILLING`;
const GET_BILLING_DATES = `${MODULE}/GET_BILLING_DATES`;
const GET_BILLING_DATES_ERROR = `${MODULE}/GET_BILLING_DATES_ERROR`;
const GET_ESTIMATE = `${MODULE}/GET_ESTIMATE`;
const INVOICES_BILLING_PREFLIGHT_CHECK = `${MODULE}/INVOICES_BILLING_PREFLIGHT_CHECK`;
const INVOICES_ERROR = `${MODULE}/INVOICES_ERROR`;
const INVOICES_GENERATE_INVOICES = `${MODULE}/INVOICES_GENERATE_INVOICES`;
const INVOICES_GENERATE_PREFLIGHT_EXPORT = `${MODULE}/INVOICES_GENERATE_PREFLIGHT_EXPORT`;
const INVOICES_GENERATING_EXPORT_START = `${MODULE}/INVOICES_GENERATING_EXPORT_START`;
const INVOICES_ACTIVITY_START = `${MODULE}/INVOICES_ACTIVITY_START`;
const INVOICES_ACTIVITY_END = `${MODULE}/INVOICES_ACTIVITY_END`;
const IS_GETTING_BILLING_DATES = `${MODULE}/IS_GETTING_BILLING_DATES`;

export const clearPreflight = createAction(CLEAR_PREFLIGHT);
export const createFromFilters = createAction(CREATE_FROM_FILTERS);
export const createRemaining = createAction(CREATE_REMAINING);
export const createWithCustomers = createAction(CREATE_WITH_CUSTOMERS);
export const deleteCustomerFromBilling = createAction(DELETE_CUSTOMER_FROM_BILLING);
export const deleteAllCustomersFromBilling = createAction(DELETE_ALL_CUSTOMERS_FROM_BILLING);
export const deleteAllCustomersFromBillingPreflight = createAction(DELETE_ALL_CUSTOMERS_FROM_BILLING_PREFLIGHT);
export const deleteInvoices = createAction(DELETE_INVOICES);
export const fetchingInvoices = createAction(FETCHING_INVOICES);
export const finalizeInvoices = createAction(FINALIZE_INVOICES);
export const generateInvoicesStatusExport = createAction(GENERATE_INVOICES_STATUS_EXPORT);
export const getBilling = createAction(GET_BILLING);
export const getBillingDates = createAction(GET_BILLING_DATES);
export const getBillingDatesError = createAction(GET_BILLING_DATES_ERROR);
export const getEstimate = createAction(GET_ESTIMATE);
export const invoicesBillingPreflightCheck = createAction(INVOICES_BILLING_PREFLIGHT_CHECK);
export const invoicesError = createAction(INVOICES_ERROR);
export const invoicesGenerateInvoices = createAction(INVOICES_GENERATE_INVOICES);
export const invoicesGeneratePreflightExport = createAction(INVOICES_GENERATE_PREFLIGHT_EXPORT);
export const invoicesGeneratingExportStart = createAction(INVOICES_GENERATING_EXPORT_START);
export const invoicesActivityStart = createAction(INVOICES_ACTIVITY_START);
export const invoicesActivityEnd = createAction(INVOICES_ACTIVITY_END);
export const isGettingBillingDates = createAction(IS_GETTING_BILLING_DATES);
