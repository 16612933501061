export class PlaidService {
  http;

  constructor(http) {
    this.http = http;
  }

  async fetchToken(client_user_id, token, redirect_uri) {
    const userId = client_user_id ? client_user_id : 'test';
    let queryString = `client_user_id=${userId}&redirect_uri=${redirect_uri}`;
    if (token) {
      queryString += `&access_token=${token}`;
    }
    return this.http.get(`/plaid/create_link_token?${queryString}`);
  }

  async exchangeToken(public_token) {
    return this.http.get(`/plaid/exchange_token?public_token=${public_token}`);
  }

  async createStripeToken(access_token, account_id) {
    return this.http.get(`/plaid/create_stripe_token?access_token=${access_token}&account_id=${account_id}`);
  }

  async getAccounts(access_token, customerId) {
    return this.http.get(`/plaid/get_accounts?access_token=${access_token}&customerId=${customerId}`);
  }

  async getPlaidEnvironment() {
    return this.http.get('/plaid/get_environment');
  }

  async registerStripeCustomer(stripe_bank_account_token) {
    return this.http.get(`GET /plaid/register_stripe_customer?stripe_account_token=${stripe_bank_account_token}`);
  }
}
