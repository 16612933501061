import invert from 'lodash/invert';

const ActionType = {
  Create: 0,
  Read: 1,
  Update: 2,
  Delete: 3
};

export default Object.freeze({
  ...ActionType,
  ...invert(ActionType)
});
