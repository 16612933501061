import { combineReducers } from 'redux';

import { reducer as activities } from 'shared/store/modules/activities';
import { reducer as allocations } from 'shared/store/modules/allocations';
import { reducer as autoallocations } from 'shared/store/modules/autoAllocations';
import { reducer as bulkedit } from 'shared/store/modules/bulkedit';
import { reducer as contracts } from 'shared/store/modules/contracts';
import { reducer as creditBands } from 'shared/store/modules/creditBands';
import { reducer as dashboard } from 'shared/store/modules/dashboard';
import { reducer as deferredCharges } from 'shared/store/modules/deferredCharges';
import { reducer as disclosures } from 'shared/store/modules/disclosures';
import { reducer as documents } from 'shared/store/modules/documents';
import { reducer as history } from 'shared/store/modules/history';
import { reducer as imports } from 'shared/store/modules/imports';
import { reducer as loading } from 'shared/store/modules/loading';
import { reducer as offers } from 'shared/store/modules/offers';
import { reducer as portfolios } from 'shared/store/modules/portfolios';
import { reducer as premises } from 'shared/store/modules/premises';
import { reducer as projects } from 'shared/store/modules/projects';
import { reducer as rateTypes } from 'shared/store/modules/rateTypes';
import { reducer as readings } from 'shared/store/modules/readings';
import { reducer as referralCodes } from 'shared/store/modules/referralCodes';
import { reducer as schedules } from 'shared/store/modules/schedules';
import { reducer as settlementAccounts } from 'shared/store/modules/settlementAccounts';
import { reducer as subscribers } from 'shared/store/modules/subscribers';
import { reducer as subscriptions } from 'shared/store/modules/subscriptions';
import { reducer as users } from 'shared/store/modules/users';
import { reducer as utilities } from 'shared/store/modules/utilities';

export default combineReducers({
  activities,
  allocations,
  autoallocations,
  bulkedit,
  contracts,
  creditBands,
  dashboard,
  deferredCharges,
  disclosures,
  documents,
  history,
  imports,
  loading,
  offers,
  portfolios,
  premises,
  projects,
  rateTypes,
  readings,
  referralCodes,
  schedules,
  settlementAccounts,
  subscribers,
  subscriptions,
  users,
  utilities
});
