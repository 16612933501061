import { createAction } from 'redux-actions';

const MODULE = 'app/admin/deferredCharges';

const CREATE_DEFERRED_CHARGE_REQUEST = `${MODULE}/CREATE_DEFERRED_CHARGE_REQUEST`;
const CREATE_DEFERRED_CHARGE_RESPONSE = `${MODULE}/CREATE_DEFERRED_CHARGE_RESPONSE`;
const DELETE_DEFERRED_CHARGE_REQUEST = `${MODULE}/DELETE_DEFERRED_CHARGE_REQUEST`;
const DELETE_DEFERRED_CHARGE_RESPONSE = `${MODULE}/DELETE_DEFERRED_CHARGE_RESPONSE`;
const EDIT_DEFERRED_CHARGE_REQUEST = `${MODULE}/EDIT_DEFERRED_CHARGE_REQUEST`;
const EDIT_DEFERRED_CHARGE_RESPONSE = `${MODULE}/EDIT_DEFERRED_CHARGE_RESPONSE`;
const FETCH_DEFERRED_CHARGES_REQUEST = `${MODULE}/FETCH_DEFERRED_CHARGES_REQUEST`;
const FETCH_DEFERRED_CHARGES_RESPONSE = `${MODULE}/FETCH_DEFERRED_CHARGES_RESPONSE`;

export const createDeferredChargeRequest = createAction(CREATE_DEFERRED_CHARGE_REQUEST);
export const createDeferredChargeResponse = createAction(CREATE_DEFERRED_CHARGE_RESPONSE);
export const deleteDeferredChargeRequest = createAction(DELETE_DEFERRED_CHARGE_REQUEST);
export const deleteDeferredChargeResponse = createAction(DELETE_DEFERRED_CHARGE_RESPONSE);
export const editDeferredChargeRequest = createAction(EDIT_DEFERRED_CHARGE_REQUEST);
export const editDeferredChargeResponse = createAction(EDIT_DEFERRED_CHARGE_RESPONSE);
export const fetchDeferredChargesRequest = createAction(FETCH_DEFERRED_CHARGES_REQUEST);
export const fetchDeferredChargesResponse = createAction(FETCH_DEFERRED_CHARGES_RESPONSE);
