import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { AuthLayout } from 'shared/components/AuthLayout';
import { useAccounts } from 'shared/store/modules/accounts/hooks';
import { useAuth0Session } from 'shared/store/modules/auth/hooks';
import Loading from 'shared/components/Loading';

function AccountPickerContainer() {
  const history = useHistory();
  const { accounts, setActiveAccountId } = useAccounts();
  const { isAdmin } = useAuth0Session();

  function handleClick(accountId: string) {
    setActiveAccountId(accountId);
    history.push('/');
  }

  if (isAdmin()) {
    history.push('/admin');
  }

  if (accounts.length === 1) {
    handleClick(accounts[0].id)
  }

  return (
    <AuthLayout title="Login">
      <p className="text-center font-weigh-bold">Select an Account</p>

      {accounts.length === 0 ? (
        <Loading />
      ) : (
        <ListGroup>
          {accounts.map(account => (
            <ListGroupItem action key={account.id} onClick={() => handleClick(account.id)} tag="button">
              {account.name}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}

      <small className="d-block text-center mt-3">
        <Link to="/logout">Sign out</Link>
      </small>
    </AuthLayout>
  );
}

export const AccountPicker = withAuthenticationRequired(AccountPickerContainer);
