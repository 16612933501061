import { createAction } from 'redux-actions';

const MODULE = 'app/admin/offers';

const CREATE_OFFER_REQUEST = `${MODULE}/CREATE_OFFER_REQUEST`;
const CREATE_OFFER_RESPONSE = `${MODULE}/CREATE_OFFER_RESPONSE`;
const FETCH_OFFER_REQUEST = `${MODULE}/FETCH_OFFER_REQUEST`;
const FETCH_OFFER_RESPONSE = `${MODULE}/FETCH_OFFER_RESPONSE`;
const SET_DEFAULT_OFFER_REQUEST = `${MODULE}/SET_DEFAULT_OFFER_REQUEST`;
const SET_DEFAULT_OFFER_RESPONSE = `${MODULE}/SET_DEFAULT_OFFER_RESPONSE`;
const UPDATE_OFFER_COUNTIES_REQUEST = `${MODULE}/UPDATE_OFFER_COUNTIES_REQUEST`;
const UPDATE_OFFER_COUNTIES_RESPONSE = `${MODULE}/UPDATE_OFFER_COUNTIES_RESPONSE`;
const UPDATE_OFFER_PROJECTS_REQUEST = `${MODULE}/UPDATE_OFFER_PROJECTS_REQUEST`;
const UPDATE_OFFER_PROJECTS_RESPONSE = `${MODULE}/UPDATE_OFFER_PROJECTS_RESPONSE`;
const UPDATE_OFFER_REFERRAL_CODES_REQUEST = `${MODULE}/UPDATE_OFFER_REFERRAL_CODES_REQUEST`;
const UPDATE_OFFER_REFERRAL_CODES_RESPONSE = `${MODULE}/UPDATE_OFFER_REFERRAL_CODES_RESPONSE`;
const UPDATE_OFFER_REQUEST = `${MODULE}/UPDATE_OFFER_REQUEST`;
const UPDATE_OFFER_RESPONSE = `${MODULE}/UPDATE_OFFER_RESPONSE`;

export const createOfferRequest = createAction(CREATE_OFFER_REQUEST);
export const createOfferResponse = createAction(CREATE_OFFER_RESPONSE);
export const fetchOfferRequest = createAction(FETCH_OFFER_REQUEST);
export const fetchOfferResponse = createAction(FETCH_OFFER_RESPONSE);
export const setDefaultOfferRequest = createAction(SET_DEFAULT_OFFER_REQUEST);
export const setDefaultOfferResponse = createAction(SET_DEFAULT_OFFER_RESPONSE);
export const updateOfferCountiesRequest = createAction(UPDATE_OFFER_COUNTIES_REQUEST);
export const updateOfferCountiesResponse = createAction(UPDATE_OFFER_COUNTIES_RESPONSE);
export const updateOfferProjectsRequest = createAction(UPDATE_OFFER_PROJECTS_REQUEST);
export const updateOfferProjectsResponse = createAction(UPDATE_OFFER_PROJECTS_RESPONSE);
export const updateOfferReferralCodesRequest = createAction(UPDATE_OFFER_REFERRAL_CODES_REQUEST);
export const updateOfferReferralCodesResponse = createAction(UPDATE_OFFER_REFERRAL_CODES_RESPONSE);
export const updateOfferRequest = createAction(UPDATE_OFFER_REQUEST);
export const updateOfferResponse = createAction(UPDATE_OFFER_RESPONSE);
