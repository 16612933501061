import TagManager from 'react-gtm-module';
import axios from 'axios';
import { Provider } from 'react-redux';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import Loading from 'shared/components/Loading';
import { ApiProvider } from 'shared/components/ApiProvider';
import { ReactQueryProvider } from 'shared/components/ReactQueryProvider';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Bugsnag } from 'shared/components/Bugsnag';
import { ShardError } from 'shared/components/ShardError';
import { initializeStore } from 'shared/store';

export function ShardProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const [data, setData] = useState<undefined | { api: { gtmId: string; monitoringKey: string } }>();
  const [ready, setReady] = useState(false);
  const gtmId = useMemo(() => data?.api.gtmId, [data?.api.gtmId]);

  useEffect(() => {
    if (gtmId) {
      TagManager.initialize({
        gtmId
      });
    }
  }, [gtmId]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/entry`)
      .then(res => setData(res.data))
      .finally(() => setReady(true));
  }, []);

  if (!ready) {
    return <Loading />;
  }

  if (!data) {
    return <ShardError />;
  }

  const { api, store } = initializeStore(data);

  return (
    <Bugsnag key={data.api.monitoringKey}>
      <Provider store={store}>
        <ApiProvider api={api}>
          <ReactQueryProvider>
            {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={false} />}
            {children}
          </ReactQueryProvider>
        </ApiProvider>
      </Provider>
    </Bugsnag>
  );
}
