import { BroadcastChannel } from 'broadcast-channel';
import { Dispatch, Store, Action } from 'redux';

export function broadcastMiddleware(allowlist: string[] = [], channelName = 'state-channel') {
  const channel = new BroadcastChannel(channelName);

  return (store: Store) => {
    channel.onmessage = message => {
      store.dispatch({
        ...message,
        isBroadcastMessage: true
      });
    };

    return (next: Dispatch) => (action: Action & { isBroadcastMessage?: boolean }) => {
      if (action instanceof Function) {
        return next(action);
      }

      const { isBroadcastMessage, ...nextAction } = action;

      if (!isBroadcastMessage && allowlist.includes(action.type)) {
        channel.postMessage(action);
      }

      return next(nextAction);
    };
  };
}
