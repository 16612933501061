import { LOCATION_CHANGE } from 'connected-react-router';
import { handleActions } from 'redux-actions';

import {
  clearPreflight,
  createFromFilters,
  createRemaining,
  createWithCustomers,
  deleteCustomerFromBilling,
  deleteAllCustomersFromBilling,
  deleteAllCustomersFromBillingPreflight,
  deleteInvoices,
  fetchingInvoices,
  finalizeInvoices,
  generateInvoicesStatusExport,
  getBilling,
  getBillingDates,
  getEstimate,
  invoicesBillingPreflightCheck,
  invoicesError,
  invoicesGenerateInvoices,
  invoicesGeneratePreflightExport,
  invoicesGeneratingExportStart,
  invoicesActivityStart,
  invoicesActivityEnd,
  isGettingBillingDates
} from 'shared/store/modules/invoices/actions';

const defaultState = {
  isFetching: false,
  isInvoicingBusy: false,
  error: null,
  data: {
    alerts: [],
    invoiceGenerationDetails: []
  },
  recentlyDeleted: [],
  preflightExportData: null,
  invoiceStatusExportData: null,
  billingDates: null
};

// Reducer
// TODO: use combineActions

export const reducer = handleActions(
  {
    [LOCATION_CHANGE]: (_state, { payload }) => ({
      ..._state,
      isFetching: true,
      isComplete: false,
      invoiceStatusExportData: null,
      preflightExportData: null,
      data: defaultState.data
    }),

    [fetchingInvoices]: (_state, { payload }) => ({
      ..._state,
      isFetching: false,
      error: null
    }),

    [isGettingBillingDates]: (_state, { payload }) => ({
      ..._state,
      isFetchingBillingDates: true
    }),

    [getBillingDates]: (_state, { payload }) => ({
      ..._state,
      billingDates: payload,
      isFetchingBillingDates: false
    }),

    [invoicesGeneratingExportStart]: (_state, { payload }) => ({
      ..._state,
      isFetching: true,
      preflightExportData: null,
      invoiceStatusExportData: null
    }),

    [invoicesActivityStart]: (_state, { payload }) => ({
      ..._state,
      isComplete: false,
      preflightCheck: null,
      error: null,
      isInvoicingBusy: true
    }),

    [invoicesError]: (_state, { payload }) => ({
      ..._state,
      error: payload.response,
      isFetching: false,
      isComplete: true
    }),

    [invoicesBillingPreflightCheck]: (_state, { payload }) => ({
      ..._state,
      preflightCheck: payload,
      isFetching: false
    }),

    [invoicesGeneratePreflightExport]: (_state, { payload }) => ({
      ..._state,
      preflightCheck: null,
      preflightExportData: payload,
      isFetching: false
    }),

    [generateInvoicesStatusExport]: (_state, { payload }) => ({
      ..._state,
      invoiceStatusExportData: payload,
      isFetching: false,
      error: null
    }),

    [invoicesGenerateInvoices]: (_state, { payload }, statusMessage) => ({
      ..._state,
      data: payload,
      isFetching: false,
      statusMessage
    }),

    [invoicesActivityEnd]: (_state, { payload }) => ({
      ..._state,
      data: payload,
      isFetching: false,
      isComplete: true,
      isDeleting: false,
      isInvoicingBusy: false
    }),

    [deleteInvoices]: (_state, { payload }, statusMessage) => ({
      ..._state,
      data: payload,
      isFetching: false,
      statusMessage
    }),

    [finalizeInvoices]: (_state, { payload }, statusMessage) => ({
      ..._state,
      data: payload,
      isFetching: false,
      statusMessage
    }),

    [getEstimate]: (_state, { payload }) => ({
      ..._state,
      remainingCustomersCount: payload.count,
      isFetching: false
    }),

    [createFromFilters]: (_state, { payload }) => ({
      ..._state,
      newBilling: payload,
      isFetching: false
    }),

    [createWithCustomers]: (_state, { payload }) => ({
      ..._state,
      newBilling: payload,
      isFetching: false
    }),

    [createRemaining]: (_state, { payload }) => ({
      ..._state,
      newBilling: payload,
      isFetching: false
    }),

    [getBilling]: (_state, { payload }) => ({
      ..._state,
      billing: payload,
      isFetching: false
    }),

    [clearPreflight]: (_state, { payload }) => ({
      ..._state,
      preflightCheck: null
    }),

    [deleteCustomerFromBilling]: (_state, { payload }) => ({
      ..._state,
      data: {
        ..._state.data,
        invoiceGenerationDetails: _state.data.invoiceGenerationDetails.filter(
          i => i.billingCustomerDetailId !== payload
        )
      }
    }),

    [deleteAllCustomersFromBilling]: (_state, { payload }) => ({
      ..._state,
      recentlyDeleted: [..._state.recentlyDeleted, payload],
      isFetching: false
    }),

    [deleteAllCustomersFromBillingPreflight]: (_state, { payload }) => ({
      ..._state,
      preflightCheck: payload,
      isFetching: false,
    })

  },

  defaultState
);
