import { handleActions } from 'redux-actions';
import { fetchAllSubscribersRequest, fetchAllSubscribersResponse } from 'shared/store/modules/subscribers/actions';

import { AsyncStatus } from 'shared/models/enums/AsyncStatus';

const defaultState = AsyncStatus.Idle;

export default handleActions(
  {
    [fetchAllSubscribersRequest.toString()]: {
      next: () => AsyncStatus.Pending
    },

    [fetchAllSubscribersResponse.toString()]: {
      next: () => AsyncStatus.Fulfilled,
      throw: () => AsyncStatus.Rejected
    }
  },

  defaultState
);