import { handleActions } from 'redux-actions';

import { fetchUserResponse, updateUserProfileResponse } from 'shared/store/modules/user/actions';

const defaultState = {
  firstName: '',
  lastName: ''
};

export const reducer = handleActions(
  {
    [fetchUserResponse]: {
      next: (_state, { payload }) => payload
    },

    [updateUserProfileResponse]: {
      next: (_state, { payload }) => payload
    }
  },

  defaultState
);
