export class UtilityService {
  http;

  constructor(http) {
    this.http = http;
  }

  // GET /utilities
  async fetchAll() {
    return this.http.get('/utilities');
  }

  // GET /utilities/{utilityId}
  async fetchOne(id) {
    return this.http.get(`/utilities/${id}`);
  }

  async fetchDetails(id) {
    return this.http.get(`/utilities/${id}/details`);
  }

  // POST /utilities
  async create(utility) {
    return this.http.post('/utilities', utility);
  }

  // PUT /utilities
  async update(id, utility) {
    return this.http.put('/utilities', { id, ...utility });
  }

  // DELETE /utilities
  async delete(id) {
    return this.http.delete(`/utilities/${id}`);
  }

  async fetchRateSchedule(id) {
    return this.http.get(`/utilities/rate-schedules/${id}/`);
  }

  async createRateSchedule(utilityRateTypeId, schedule) {
    return this.http.post('/utilities/rate-schedules', {
      utilityRateTypeId,
      ...schedule
    });
  }

  async updateRateSchedule(id, rateTypeId, schedule) {
    return this.http.put('/utilities/rate-schedules', {
      id,
      utilityRateTypeId: rateTypeId,
      ...schedule
    });
  }

  async deleteRateSchedule(rateTypeId) {
    return this.http.delete(`/utilities/rate-schedules/${rateTypeId}`);
  }

  async fetchOffers(utilityId) {
    return this.http.get(`/utilities/${utilityId}/offers`);
  }

  async fetchOffer(offerId) {
    return this.http.get(`/utilities/offers/${offerId}`);
  }

  async createOffer(utilityId, offer) {
    return this.http.post('/utilities/offers', {
      utilityId,
      ...offer
    });
  }

  async updateOfferCounties(offerId, countyIds) {
    return this.http.put('/utilities/offers/counties', {
      offerId,
      countyIds
    });
  }

  async updateOfferProjects(offerId, projectIds) {
    return this.http.put('/utilities/offers/projects', {
      offerId,
      projectIds
    });
  }

  async updateOfferReferralCodes(offerId, referralCodeIds) {
    return this.http.put('/utilities/offers/referralcodes', {
      offerId,
      referralCodeIds
    });
  }

  async updateOffer(id, utilityId, offer) {
    return this.http.put('/utilities/offers', {
      id,
      utilityId: utilityId,
      ...offer
    });
  }

  async setDefaultOffer(id, isDefault) {
    return this.http.patch('/utilities/offers/default', {
      id,
      isDefault
    });
  }

  async createCreditBand(offerId, creditBand) {
    return this.http.post('/utilities/offers/credit-bands', {
      subscriptionTemplateId: offerId,
      ...creditBand
    });
  }

  async updateCreditBand(id, offerId, creditBand) {
    return this.http.put('/utilities/offers/credit-bands', {
      id,
      subscriptionTemplateId: offerId,
      ...creditBand
    });
  }

  async deleteCreditBand(creditBandId) {
    return this.http.delete(`/utilities/offers/credit-bands/${creditBandId}`);
  }

  async fetchOfferDisclosures(offerId) {
    return this.http.get(`/utilities/offers/${offerId}/disclosures`);
  }

  async fetchDisclosure(offerId, disclosureType) {
    return this.http.get(`/utilities/offers/disclosures/${offerId}/${disclosureType}`);
  }

  async fetchDisclosures() {
    return this.http.get('/utilities/offers/disclosures');
  }

  async updateDisclosure(offerId, disclosureType, keyValues) {
    return this.http.put('/utilities/offers/disclosures', {
      offerId,
      disclosureType,
      keyValues
    });
  }

  async fetchDisclosureSchema(disclosureType) {
    return this.http.get(`/utilities/offers/disclosures/${disclosureType}`);
  }

  async deleteOfferDisclosure(offerId, disclosureType) {
    return this.http.delete(`/utilities/offers/disclosures/${offerId}/${disclosureType}`);
  }

  async fetchContracts(utilityId) {
    return this.http.get(`/utilities/${utilityId}/contracts`);
  }

  async fetchContract(contractId) {
    return this.http.get(`/utilities/contracts/${contractId}`);
  }

  async createContract(utilityId, contract) {
    return this.http.post('/utilities/contracts', {
      utilityId,
      ...contract
    });
  }

  async updateContract(utilityId, id, contract) {
    return this.http.put('/utilities/contracts', { utilityId, id, ...contract });
  }

  async createHeader(contractId, type) {
    return this.http.post('/utilities/contracts/headers', {
      contractCompositionId: contractId,
      type
    });
  }

  async sortHeaders(ids) {
    return this.http.put('/utilities/contracts/headers/sort', [...ids]);
  }

  async deleteHeader(id) {
    return this.http.delete(`/utilities/contracts/headers/${id}`);
  }

  async updateHeader(header) {
    return this.http.put('/utilities/contracts/headers', header);
  }

  async createBlock(contractId) {
    return this.http.post('/utilities/contracts/legal-blocks', {
      contractCompositionId: contractId
    });
  }

  async sortBlocks(ids) {
    return this.http.put('/utilities/contracts/legal-blocks/sort', [...ids]);
  }

  async deleteBlock(id) {
    return this.http.delete(`/utilities/contracts/legal-blocks/${id}`);
  }

  async updateBlock(block) {
    return this.http.put('/utilities/contracts/legal-blocks', block);
  }

  async fetchSections(contractId) {
    return this.http.get(`/utilities/contracts/${contractId}/custom-sections`);
  }

  async createSection(contractId) {
    return this.http.post('/utilities/contracts/custom-sections', {
      contractCompositionId: contractId
    });
  }

  async sortSections(ids) {
    return this.http.put('/utilities/contracts/custom-sections/sort', [...ids]);
  }

  async deleteSection(id) {
    return this.http.delete(`/utilities/contracts/custom-sections/${id}`);
  }

  async updateSection(contractId, section) {
    return this.http.put('/utilities/contracts/custom-sections', {
      contractCompositionId: contractId,
      ...section
    });
  }

  async generateContractPreview(offerId, projectId) {
    return this.http.post(`/utilities/contracts/${offerId}/${projectId}/preview`);
  }

  async checkForContractPreview(ticket) {
    return this.http.head(`/utilities/contracts/preview/${ticket}`);
  }

  async fetchContractPreview(ticket) {
    return this.http.get(`/utilities/contracts/preview/${ticket}`, {
      responseType: 'blob'
    });
  }

  async deleteContract(id) {
    return this.http.delete(`/utilities/contracts/${id}`);
  }

  async fetchAssociatedUtilities(utilityId) {
    return this.http.get(`/utilities/associated-utility/${utilityId}`);
  }

  async updateAssociatedUtilities(utilityId, associatedUtilityIds) {
    return this.http.put('/utilities/associated-utility/map', {
      utilityId,
      associatedUtilityIds
    });
  }
}
