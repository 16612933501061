import { createAction } from 'redux-actions';

const MODULE = 'app/admin/subscribers';

const CREATE_SUBSCRIBER_USER_REQUEST = `${MODULE}/CREATE_SUBSCRIBER_USER_REQUEST`;
const CREATE_SUBSCRIBER_USER_RESPONSE = `${MODULE}/CREATE_SUBSCRIBER_USER_RESPONSE`;
const DELETE_SUBSCRIBER_REQUEST = `${MODULE}/DELETE_SUBSCRIBER_REQUEST`;
const DELETE_SUBSCRIBER_RESPONSE = `${MODULE}/DELETE_SUBSCRIBER_RESPONSE`;
const EXPORT_SUBSCRIBER_ACTIVITIES_REQUEST = `${MODULE}/EXPORT_SUBSCRIBER_ACTIVITIES_REQUEST`;
const EXPORT_SUBSCRIBER_ACTIVITIES_RESPONSE = `${MODULE}/EXPORT_SUBSCRIBER_ACTIVITIES_RESPONSE`;
const FETCH_ALL_SUBSCRIBERS_REQUEST = `${MODULE}/FETCH_ALL_SUBSCRIBERS_REQUEST`;
const FETCH_ALL_SUBSCRIBERS_RESPONSE = `${MODULE}/FETCH_ALL_SUBSCRIBERS_RESPONSE`;
const FETCH_SUBSCRIBERS_BY_UTILITY_REQUEST = `${MODULE}/FETCH_SUBSCRIBERS_BY_UTILITY_REQUEST`;
const FETCH_SUBSCRIBERS_BY_UTILITY_RESPONSE = `${MODULE}/FETCH_SUBSCRIBERS_BY_UTILITY_RESPONSE`;
const FETCH_AUTO_ALLOCATABLE_CUSTOMERS_REQUEST = `${MODULE}/FETCH_AUTO_ALLOCATABLE_CUSTOMERS_REQUEST`;
const FETCH_AUTO_ALLOCATABLE_CUSTOMERS_RESPONSE = `${MODULE}/FETCH_AUTO_ALLOCATABLE_CUSTOMERS_RESPONSE`;
const FETCH_SUBSCRIBER_DETAILS_REQUEST = `${MODULE}/FETCH_SUBSCRIBER_DETAILS_REQUEST`;
const FETCH_SUBSCRIBER_DETAILS_RESPONSE = `${MODULE}/FETCH_SUBSCRIBER_DETAILS_RESPONSE`;
const FETCH_SUBSCRIBER_INVOICE_REQUEST = `${MODULE}/FETCH_SUBSCRIBER_INVOICE_REQUEST`;
const FETCH_SUBSCRIBER_INVOICE_RESPONSE = `${MODULE}/FETCH_SUBSCRIBER_INVOICE_RESPONSE`;
const FETCH_SUBSCRIBER_INVOICE__BYDOCID_REQUEST = `${MODULE}/FETCH_SUBSCRIBER_INVOICE__BYDOCID_REQUEST`;
const FETCH_SUBSCRIBER_INVOICE__BYDOCID_RESPONSE = `${MODULE}/FETCH_SUBSCRIBER_INVOICE__BYDOCID_RESPONSE`;
const FETCH_SUBSCRIBER_INVOICES_REQUEST = `${MODULE}/FETCH_SUBSCRIBER_INVOICES_REQUEST`;
const FETCH_SUBSCRIBER_INVOICES_RESPONSE = `${MODULE}/FETCH_SUBSCRIBER_INVOICES_RESPONSE`;
const FETCH_SUBSCRIBER_REQUEST = `${MODULE}/FETCH_SUBSCRIBER_REQUEST`;
const FETCH_SUBSCRIBER_RESPONSE = `${MODULE}/FETCH_SUBSCRIBER_RESPONSE`;
const UPDATE_SUBSCRIBER_REQUEST = `${MODULE}/UPDATE_SUBSCRIBER_REQUEST`;
const UPDATE_SUBSCRIBER_RESPONSE = `${MODULE}/UPDATE_SUBSCRIBER_RESPONSE`;

export const createSubscriberUserRequest = createAction(CREATE_SUBSCRIBER_USER_REQUEST);
export const createSubscriberUserResponse = createAction(CREATE_SUBSCRIBER_USER_RESPONSE);
export const deleteSubscriberRequest = createAction(DELETE_SUBSCRIBER_REQUEST);
export const deleteSubscriberResponse = createAction(DELETE_SUBSCRIBER_RESPONSE);
export const exportSubscriberActivitiesRequest = createAction(EXPORT_SUBSCRIBER_ACTIVITIES_REQUEST);
export const exportSubscriberActivitiesResponse = createAction(EXPORT_SUBSCRIBER_ACTIVITIES_RESPONSE);
export const fetchAllSubscribersRequest = createAction(FETCH_ALL_SUBSCRIBERS_REQUEST);
export const fetchAllSubscribersResponse = createAction(FETCH_ALL_SUBSCRIBERS_RESPONSE);
export const fetchSubscribersByUtilityRequest = createAction(FETCH_SUBSCRIBERS_BY_UTILITY_REQUEST);
export const fetchSubscribersByUtilityResponse = createAction(FETCH_SUBSCRIBERS_BY_UTILITY_RESPONSE);
export const fetchAutoAllocatableCustomersRequest = createAction(FETCH_AUTO_ALLOCATABLE_CUSTOMERS_REQUEST);
export const fetchAutoAllocatableCustomersResponse = createAction(FETCH_AUTO_ALLOCATABLE_CUSTOMERS_RESPONSE);
export const fetchSubscriberDetailsRequest = createAction(FETCH_SUBSCRIBER_DETAILS_REQUEST);
export const fetchSubscriberDetailsResponse = createAction(FETCH_SUBSCRIBER_DETAILS_RESPONSE);
export const fetchSubscriberInvoiceRequest = createAction(FETCH_SUBSCRIBER_INVOICE_REQUEST);
export const fetchSubscriberInvoiceResponse = createAction(FETCH_SUBSCRIBER_INVOICE_RESPONSE);
export const fetchSubscriberInvoiceByDocIdRequest = createAction(FETCH_SUBSCRIBER_INVOICE__BYDOCID_REQUEST);
export const fetchSubscriberInvoiceByDocIdResponse = createAction(FETCH_SUBSCRIBER_INVOICE__BYDOCID_RESPONSE);
export const fetchSubscriberInvoicesRequest = createAction(FETCH_SUBSCRIBER_INVOICES_REQUEST);
export const fetchSubscriberInvoicesResponse = createAction(FETCH_SUBSCRIBER_INVOICES_RESPONSE);
export const fetchSubscriberRequest = createAction(FETCH_SUBSCRIBER_REQUEST);
export const fetchSubscriberResponse = createAction(FETCH_SUBSCRIBER_RESPONSE);
export const updateSubscriberRequest = createAction(UPDATE_SUBSCRIBER_REQUEST);
export const updateSubscriberResponse = createAction(UPDATE_SUBSCRIBER_RESPONSE);
