export class ImportService {
  http;

  constructor(http) {
    this.http = http;
  }

  async fetchInstructions() {
    return this.http.get('/import/instructions', {
      responseType: 'blob'
    });
  }

  async fetchTemplate() {
    return this.http.get('/import/template', {
      responseType: 'blob'
    });
  }

  async fetchIdReference() {
    return this.http.get('/import/id-reference', {
      responseType: 'blob'
    });
  }

  async fetchExisting() {
    return this.http.get('/import/bulk-edit', {
      responseType: 'blob'
    });
  }

  async fetchReport(claimTicket) {
    return this.http.get(`/import/${claimTicket}/import`, {
      responseType: 'blob'
    });
  }

  async fetchBulkEditReport(claimTicket) {
    return this.http.get(`/import/bulk-edit/${claimTicket}/import`, {
      responseType: 'blob'
    });
  }

  async fetchErrorReport(claimTicket) {
    return this.http.get(`/import/${claimTicket}/validation`, {
      responseType: 'blob'
    });
  }

  async fetchBulkEditErrorReport(claimTicket) {
    return this.http.get(`/import/bulk-edit/${claimTicket}/validation`, {
      responseType: 'blob'
    });
  }

  async validate(formData) {
    return this.http.post('/import/validate', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  async validateBulkEdit(formData) {
    return this.http.post('/import/bulk-edit/validate', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  async import(claimTicket) {
    return this.http.post('/import', null, {
      params: {
        claimTicket
      }
    });
  }

  async importBulkEdit(claimTicket) {
    return this.http.post('/import/bulk-edit', null, {
      params: {
        claimTicket
      }
    });
  }
}
