import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useLogout } from 'shared/store/modules/auth/hooks';

export function Logout() {
  const logout = useLogout();

  useEffect(() => {
    logout();
  }, [logout]);

  return <Redirect to="/login" />;
}
