import { handleActions } from 'redux-actions';

import { fetchUserRolesRequest, fetchUserRolesResponse } from 'shared/store/modules/users/actions';

const defaultState = [];

export default handleActions(
  {
    [fetchUserRolesRequest]: () => defaultState,
    [fetchUserRolesResponse]: (state, action) => {
      return action.payload;
    }
  },

  defaultState
);
