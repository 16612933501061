import React, { Component } from 'react';

export default function asyncComponent(getComponent) {
  class AsyncComponent extends Component {
    static Component = null;

    state = {
      // eslint-disable-next-line no-use-before-define
      Component: AsyncComponent.Component
    };

    UNSAFE_componentWillMount() {
      if (!this.state.Component) {
        getComponent().then(Component => {
          AsyncComponent.Component = Component;

          this.setState({
            Component
          });
        });
      }
    }

    render() {
      const { Component } = this.state;

      if (Component) {
        return <Component {...this.props} />;
      }

      return null;
    }
  }

  return AsyncComponent;
}
