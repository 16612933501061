import { handleActions } from 'redux-actions';
import { getAllocationStatusesResponse } from 'shared/store/modules/allocations/actions';

const defaultState = [];

export default handleActions(
  {
    [getAllocationStatusesResponse]: {
      next: (state, { payload }) => ({
        ...state,
        data: payload
      })
    }
  },

  defaultState
);
