import { createAction } from 'redux-actions';

const MODULE = 'app/auth';

const LOGIN_REQUEST = `${MODULE}/LOGIN_REQUEST`;
const AFTER_LOGIN_RESPONSE = `${MODULE}/AFTER_LOGIN_RESPONSE`;
const AUTH0_RESPONSE = `${MODULE}/AUTH0_RESPONSE`;
const LOGOUT = `${MODULE}/LOGOUT`;
const RENEW_SESSION_REQUEST = `${MODULE}/RENEW_SESSION_REQUEST`;
const RENEW_SESSION_RESPONSE = `${MODULE}/RENEW_SESSION_RESPONSE`;

export const loginRequest = createAction(LOGIN_REQUEST);
export const auth0Response = createAction(AUTH0_RESPONSE);
export const afterLoginResponse = createAction(AFTER_LOGIN_RESPONSE);
export const logout = createAction(LOGOUT);
export const renewSessionRequest = createAction(RENEW_SESSION_REQUEST);
export const renewSessionResponse = createAction(RENEW_SESSION_RESPONSE);
