import { generatePath as path } from 'react-router-dom';

function missingParamHandler(param) {
  throw new Error(`Missing parameter: ${param}`);
}

export function dashboardPath() {
  return path('/admin');
}

export function portfoliosPath() {
  return path('/admin/portfolios');
}

export function portfolioPath(portfolioId = missingParamHandler('portfolioId')) {
  return path('/admin/portfolios/:portfolioId', { portfolioId });
}

export function projectsPath() {
  return path('/admin/projects');
}

export function projectPath(projectId = missingParamHandler('projectId')) {
  return path('/admin/projects/:projectId', { projectId });
}

export function projectAllocationsPath(projectId = missingParamHandler('projectId')) {
  return path('/admin/projects/:projectId/allocations', { projectId });
}

export function projectAllocationPath(
  projectId = missingParamHandler('projectId'),
  allocationId = missingParamHandler('allocationId')
) {
  return path('/admin/projects/:projectId/allocations/:allocationId', {
    projectId,
    allocationId
  });
}

export function subscribersPath() {
  return path('/admin/subscribers');
}

export function subscriberPath(subscriberId = missingParamHandler('subscriberId')) {
  return path('/admin/subscribers/:subscriberId', { subscriberId });
}

export function subscriberSubscriptionsPath(subscriberId = missingParamHandler('subscriberId')) {
  return path('/admin/subscribers/:subscriberId/subscriptions', {
    subscriberId
  });
}

export function subscriberSubscriptionPath(
  subscriberId = missingParamHandler('subscriberId'),
  subscriptionId = missingParamHandler('subscriptionId')
) {
  return path('/admin/subscribers/:subscriberId/subscriptions/:subscriptionId/edit', { subscriberId, subscriptionId });
}
export function subscriberPremisesPath(subscriberId = missingParamHandler('subscriberId')) {
  return path('/admin/subscribers/:subscriberId/premises', {
    subscriberId
  });
}

export function subscriberPremisePath(
  subscriberId = missingParamHandler('subscriberId'),
  premiseId = missingParamHandler('premiseId')
) {
  return path('/admin/subscribers/:subscriberId/premises/:premiseId', {
    subscriberId,
    premiseId
  });
}

export function subscriberPremiseAllocationsPath(
  subscriberId = missingParamHandler('subscriberId'),
  premiseId = missingParamHandler('premiseId')
) {
  return path('/admin/subscribers/:subscriberId/premises/:premiseId/allocations', {
    subscriberId,
    premiseId
  });
}

export function subscriberPremiseAllocationPath(
  subscriberId = missingParamHandler('subscriberId'),
  premiseId = missingParamHandler('premiseId'),
  allocationId = missingParamHandler('allocationId')
) {
  return path('/admin/subscribers/:subscriberId/premises/:premiseId/allocations/:allocationId', {
    subscriberId,
    premiseId,
    allocationId
  });
}

export function utilitiesPath() {
  return path('/admin/utilities');
}

export function utilityPath(utilityId = missingParamHandler('utilityId')) {
  return path('/admin/utilities/:utilityId', { utilityId });
}

export function utilityRatesPath(utilityId = missingParamHandler('utilityId')) {
  return path('/admin/utilities/:utilityId/rates', { utilityId });
}

export function utilityRatePath(utilityId = missingParamHandler('utilityId'), rateId = missingParamHandler('rateId')) {
  return path('/admin/utilities/:utilityId/rates/:rateId', {
    utilityId,
    rateId
  });
}

export function utilityRateSchedulesPath(
  utilityId = missingParamHandler('utilityId'),
  rateId = missingParamHandler('rateId')
) {
  return path('/admin/utilities/:utilityId/rates/:rateId/schedules', {
    utilityId,
    rateId
  });
}

export function utilityRateSchedulePath(
  utilityId = missingParamHandler('utilityId'),
  rateId = missingParamHandler('rateId'),
  scheduleId = missingParamHandler('scheduleId')
) {
  return path('/admin/utilities/:utilityId/rates/:rateId/schedules/:scheduleId/edit', {
    utilityId,
    rateId,
    scheduleId
  });
}
