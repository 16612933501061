import { handleActions } from 'redux-actions';

type ThemeState = {
  logo?: string;
};

const defaultState: ThemeState = {};

export const reducer = handleActions(
  {},

  defaultState
);
