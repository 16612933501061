export default Object.freeze({
  '0': 'Invited',
  '1': 'Active',
  '2': 'Disabled',
  '3': 'Locked',
  Invited: 0,
  Active: 1,
  Disabled: 2,
  Locked: 3
});
