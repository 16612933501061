import { css } from '@emotion/react';
import sctheme from 'app/styles/sctheme';

const styles = sctheme => css`
  .banner {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    height: 40px;
    flex-shrink: 0;
    color: ${sctheme.grays.$900};
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;

    svg {
      margin-right: 0.5rem;
    }
  }
  .sandbox {
    border-bottom: 2px solid ${sctheme.system.success};
    background: ${sctheme.system.successLight};
    svg {
      color: ${sctheme.system.success};
      width: 16px;
    }
  }

  .demo {
    border-bottom: 2px solid ${sctheme.blues.secondary};
    background: ${sctheme.system.infoLight};
    svg {
      color: ${sctheme.blues.secondary};
      width: 16px;
    }
  }

  .seed {
    border-bottom: 2px solid ${sctheme.system.warning};
    background: ${sctheme.system.warningLight};
    svg {
      color: ${sctheme.system.warning};
      width: 16px;
    }
  }
`;
export default styles(sctheme);
