import { handleActions } from 'redux-actions';

import { setActiveAccountId } from '../actions';

const initialState = null;

export const activeId = handleActions(
  {
    [setActiveAccountId]: {
      next: (_state, { payload }) => payload
    }
  },

  initialState
);
