import { detect } from 'detect-browser';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Transition } from 'react-transition-group';
import { UncontrolledAlert, Row, Col, Container } from 'reactstrap';

import Loading from 'shared/components/Loading';
import 'react-toastify/dist/ReactToastify.css';

import styles from './Content.styles';
import sctheme from 'app/styles/sctheme';

const duration = 150;

const defaultStyles = {
  backgroundColor: sctheme.white,
  opacity: 0,
  visiblity: 'hidden'
};

const transitionStyles = {
  entering: {
    opacity: 0.75,
    visibility: 'visible'
  },
  entered: {
    opacity: 0.75,
    visibility: 'visible'
  },
  exiting: {
    opacity: 0.75,
    visibility: 'visible'
  },
  exited: {
    opacity: 0,
    visibility: 'hidden',
    transition: `all ${duration}ms linear`
  }
};

class Content extends Component {
  constructor(props) {
    super(props);

    this.content = React.createRef();
    this.contentContainer = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.content.current && this.content.current.scrollTo) {
        this.content.current.scrollTo(0, 0);
      }
      if (this.contentContainer.current && this.contentContainer.current.scrollTo) {
        this.contentContainer.current.scrollTo(0, 0);
      }
    }
  }

  render() {
    const browser = detect();

    return (
      <>
        <main ref={this.content} css={styles}>
          <div className="content">
            <div className="content-container" ref={this.contentContainer}>
              <ToastContainer />

              {browser.name === 'ie' && (
                <UncontrolledAlert className="mb-0" color="warning">
                  Your browser isn't fully supported at this time. Please consider using{' '}
                  <a href="https://www.google.com/chrome/">Chrome</a>,{' '}
                  <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>, or{' '}
                  <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Edge</a>.
                </UncontrolledAlert>
              )}

              {this.props.children}

              <div className="copyright mt-auto">
                <Container fluid>
                  <div className="copyright-inner text-muted">
                    <Row>
                      <Col>
                        <div className="d-flex">
                          <div className="p-3">
                            {!this.props.hidePoweredBySunCentral && (
                              <a href="https://www.suncentral.net" rel="noopener noreferrer" target="_blank">
                                Powered by SunCentral
                              </a>
                            )}
                          </div>

                          <div className="p-3 ml-auto">
                            <Link target="_blank" to="/terms">
                              Terms of Use
                            </Link>
                          </div>
                          <div className="p-3">
                            <Link target="_blank" to="/privacy">
                              Privacy Policy
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </div>
            </div>

            <Transition in={this.props.loading} timeout={duration}>
              {state => (
                <div
                  className="loading-overlay"
                  style={{
                    ...defaultStyles,
                    ...transitionStyles[state]
                  }}
                >
                  <Loading />
                </div>
              )}
            </Transition>
          </div>
        </main>
      </>
    );
  }
}

const mapStateToProps = state => ({
  hidePoweredBySunCentral: state.features.hidePoweredBySunCentral,
  loading: state.admin.loading,
  provider: state.provider
});

export default withRouter(connect(mapStateToProps, null)(Content));
