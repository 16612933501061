import { createAction } from 'redux-actions';

const MODULE = 'app/admin/subscriptions';

const ADD_CUSTOMER_DOCUMENT = `${MODULE}/ADD_CUSTOMER_DOCUMENT`;
const CREATE_SUBSCRIPTION_REQUEST = `${MODULE}/CREATE_SUBSCRIPTION_REQUEST`;
const CREATE_SUBSCRIPTION_RESPONSE = `${MODULE}/CREATE_SUBSCRIPTION_RESPONSE`;
const FETCH_ALL_SUBSCRIPTIONS_REQUEST = `${MODULE}/FETCH_ALL_SUBSCRIPTIONS_REQUEST`;
const FETCH_ALL_SUBSCRIPTIONS_RESPONSE = `${MODULE}/FETCH_ALL_SUBSCRIPTIONS_RESPONSE`;
const FETCH_CUSTOMER_SUBSCRIPTION_REQUEST = `${MODULE}/FETCH_CUSTOMER_SUBSCRIPTION_REQUEST`;
const FETCH_CUSTOMER_SUBSCRIPTION_RESPONSE = `${MODULE}/FETCH_CUSTOMER_SUBSCRIPTION_RESPONSE`;
const FETCH_SUBSCRIPTION_DETAILS_REQUEST = `${MODULE}/FETCH_SUBSCRIPTION_DETAILS_REQUEST`;
const FETCH_SUBSCRIPTION_DETAILS_RESPONSE = `${MODULE}/FETCH_SUBSCRIPTION_DETAILS_RESPONSE`;
const FETCH_SUBSCRIPTION_REQUEST = `${MODULE}/FETCH_SUBSCRIPTION_REQUEST`;
const FETCH_SUBSCRIPTION_RESPONSE = `${MODULE}/FETCH_SUBSCRIPTION_RESPONSE`;
const UPDATE_SUBSCRIPTION_REQUEST = `${MODULE}/UPDATE_SUBSCRIPTION_REQUEST`;
const UPDATE_SUBSCRIPTION_RESPONSE = `${MODULE}/UPDATE_SUBSCRIPTION_RESPONSE`;

export const addCustomerDocument = createAction(ADD_CUSTOMER_DOCUMENT);
export const createSubscriptionRequest = createAction(CREATE_SUBSCRIPTION_REQUEST);
export const createSubscriptionResponse = createAction(CREATE_SUBSCRIPTION_RESPONSE);
export const fetchAllSubscriptionsRequest = createAction(FETCH_ALL_SUBSCRIPTIONS_REQUEST);
export const fetchAllSubscriptionsResponse = createAction(FETCH_ALL_SUBSCRIPTIONS_RESPONSE);
export const fetchCustomerSubscriptionRequest = createAction(FETCH_CUSTOMER_SUBSCRIPTION_REQUEST);
export const fetchCustomerSubscriptionResponse = createAction(FETCH_CUSTOMER_SUBSCRIPTION_RESPONSE);
export const fetchSubscriptionDetailsRequest = createAction(FETCH_SUBSCRIPTION_DETAILS_REQUEST);
export const fetchSubscriptionDetailsResponse = createAction(FETCH_SUBSCRIPTION_DETAILS_RESPONSE);
export const fetchSubscriptionRequest = createAction(FETCH_SUBSCRIPTION_REQUEST);
export const fetchSubscriptionResponse = createAction(FETCH_SUBSCRIPTION_RESPONSE);
export const updateSubscriptionRequest = createAction(UPDATE_SUBSCRIPTION_REQUEST);
export const updateSubscriptionResponse = createAction(UPDATE_SUBSCRIPTION_RESPONSE);
