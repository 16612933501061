import React, { PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { getProviderName } from 'shared/store/modules/provider/selectors';
import { selectThemeImage, selectThemeLogo } from 'shared/store/modules/theme/selectors';

import { Layout, AuthContainer, BannerContainer } from './AuthLayout.styles';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import AuthLayoutError from './AuthLayoutError';

type AuthLayoutProps = PropsWithChildren<{
  title: string;
  showLoginMesage?: boolean;
}>;

export function AuthLayout(props: AuthLayoutProps) {
  const { children, title, showLoginMesage } = props;

  const image = useSelector(state => selectThemeImage(state));
  const logo = useSelector(state => selectThemeLogo(state));
  const name = useSelector(state => getProviderName(state));

  return (
    <Layout image={image}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <ToastContainer />

      {showLoginMesage && (
        <BannerContainer>
          <div><FontAwesomeIcon icon={faInfoCircle} className="mr-2 icon" /></div>
          <p>We've released a new login experience to make using our portal more secure and user-friendly. When logging in the first time, you will need to select <b>Reset password</b> on the next screen. Afterwards, use your new password to log in.</p>
        </BannerContainer>
      )}

      <AuthLayoutError />

      <AuthContainer>
        <Link className="d-block" to="/">
          <img alt={`${name} Logo`} className="d-block ml-auto mr-auto mb-5" src={logo} title={name} width="50%" />
        </Link>

        {children}
      </AuthContainer>
    </Layout>
  );
}
