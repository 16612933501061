import axios from 'axios';
import qs from 'qs';

export class StripeService {
  http;

  constructor(key) {
    this.http = axios.create({
      baseURL: 'https://api.stripe.com/v1',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    this.http.interceptors.request.use(config => {
      config.data = config.data + `&${qs.stringify({ key })}`;

      return config;
    });
  }

  async fetchToken(payload) {
    return this.http.post('/tokens', qs.stringify(payload));
  }
}
