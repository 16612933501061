import { LOCATION_CHANGE } from 'connected-react-router';
import { handleActions } from 'redux-actions';

import {
  customerAddPaymentMethod,
  customerAddPaymentMethodError,
  customerAddPaymentMethodResponse,
  customerAutoPaymentsStatus,
  customerCancelAutoPay,
  customerEnrollInAutoPay,
  customerFetchError,
  customerFetchPaymentsRequest,
  customerFetchPaymentsResponse,
  customerFetchResponse,
  customerGetBalance,
  customerGetBilling,
  customerSetPrimaryPaymentResponse,
  customerVerifyBankAccount,
  deletePaymentSuccess
} from 'shared/store/modules/customers/actions';

const defaultState = {
  displayName: '',
  isFetching: false,
  isBankAccountVerified: false,
  isOptedInToAutoPayments: null,
  error: null,
  nextPaymentId: null,
  paymentSources: [],
  dashboard: {
    accountActivity: []
  },
  balance: {},
  enablePlaid: false
};

const paymentSourcesDelete = (sources, payload) => sources.filter(source => payload.id !== source.id);

export const reducer = handleActions(
  {
    [LOCATION_CHANGE]: state => ({
      ...state,
      isFetching: false
    }),

    [customerFetchResponse]: (state, { payload }) => ({
      ...state,
      ...payload
    }),

    [customerFetchPaymentsRequest]: state => ({
      ...state,
      error: null,
      isBankAccountVerified: false,
      isFetching: true
    }),

    [customerVerifyBankAccount]: (state, { payload }) => ({
      ...state,
      isBankAccountVerified: payload,
      isFetching: false
    }),

    [customerFetchPaymentsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        error: null,
        isFetching: false,
        paymentSources: payload.sources,
        enablePlaid: payload.enablePlaid
      }),

      throw: (state, { payload }) => ({
        ...state,
        paymentSources: [],
        error: {
          message: payload.message
        },
        isFetching: false,
        enablePlaid: payload.enablePlaid
      })
    },

    [customerSetPrimaryPaymentResponse]: (state, { payload }) => ({
      ...state,
      paymentSources: payload.sources,
      isFetching: false
    }),

    [customerAddPaymentMethod]: state => ({
      ...state,
      error: defaultState.error,
      isFetching: true
    }),

    [customerAddPaymentMethodResponse]: (state, { payload }) => ({
      ...state,
      nextPaymentId: payload.data.paymentSourceId,
      isFetching: false
    }),

    [customerAutoPaymentsStatus]: (state, { payload }) => ({
      ...state,
      isOptedInToAutoPayments: payload,
      isFetching: false
    }),

    [customerEnrollInAutoPay]: state => ({
      ...state,
      isOptedInToAutoPayments: true
    }),

    [deletePaymentSuccess]: (state, { payload }) => ({
      ...state,
      paymentSources: paymentSourcesDelete(state.paymentSources, payload),
      nextPaymentId: null
    }),

    [customerCancelAutoPay]: state => ({
      ...state,
      isOptedInToAutoPayments: false
    }),

    [customerAddPaymentMethodError]: (state, { payload }) => ({
      ...state,
      error: payload.response,
      isFetching: false
    }),

    [customerFetchError]: (state, { payload }) => ({
      ...state,
      error: payload.response,
      isFetching: false
    }),

    [customerGetBilling]: (state, { payload }) => ({
      ...state,
      dashboard: payload,
      isFetching: false
    }),

    [customerGetBalance]: (state, { payload }) => ({
      ...state,
      balance: payload,
      isFetching: false
    }),
  },

  defaultState
);
