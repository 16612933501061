import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import styled from 'styled-components';
import sctheme from 'app/styles/sctheme';

import { getProviderName } from 'shared/store/modules/provider/selectors';
import { selectThemeLogo } from 'shared/store/modules/theme/selectors';

const Layout = styled.div`
  position: relative;
  background-color: ${sctheme.white};
`;

const DocumentLayout = ({ children, title }) => (
  <Layout>
    <Helmet>
      <title>{title}</title>
    </Helmet>

    {children}
  </Layout>
);

DocumentLayout.propTypes = {
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  logo: selectThemeLogo(state),
  name: getProviderName(state)
});

export default connect(mapStateToProps)(DocumentLayout);
