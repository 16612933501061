import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';

import { reducer as account } from 'shared/store/modules/account';
import { reducer as accounts } from 'shared/store/modules/accounts';
import { SET_ACTIVE_ACCOUNT_ID } from 'shared/store/modules/accounts/actions';
import { reducer as auth } from 'shared/store/modules/auth';
import { logout } from 'shared/store/modules/auth/actions';
import { reducer as content } from 'shared/store/modules/content';
import { reducer as customer } from 'shared/store/modules/customers';
import { reducer as features } from 'shared/store/modules/features';
import { reducer as initialDashboard } from 'shared/store/modules/initialDashboard';
import { reducer as invoices } from 'shared/store/modules/invoices';
import { reducer as nav } from 'shared/store/modules/nav/reducers';
import { reducer as navigation } from 'shared/store/modules/navigation';
import { reducer as prospects } from 'shared/store/modules/prospects';
import { reducer as provider } from 'shared/store/modules/provider';
import { reducer as redirects } from 'shared/store/modules/redirects';
import { reducer as theme } from 'shared/store/modules/theme';
import { reducer as user } from 'shared/store/modules/user';

import admin from './admin';
import autoallocations from './autoallocations';
import project from './project';
import subscription from './subscription';
import users from './users';

export default function createRootReducer(history) {
  const appReducer = combineReducers({
    account,
    accounts,
    admin,
    auth,
    autoallocations,
    content,
    customer,
    features,
    initialDashboard,
    invoices,
    nav,
    navigation,
    project,
    prospects,
    provider,
    redirects,
    router: connectRouter(history),
    subscription,
    theme,
    user,
    users
  });

  const rootReducer = (state, action) => {
    if (action.type === logout.toString()) {
      const { content, features, initialDashboard, navigation, provider, redirects, router, theme } = state;

      state = {
        content,
        features,
        initialDashboard,
        navigation,
        provider,
        redirects,
        router,
        theme
      };
    }

    if (action.type === SET_ACTIVE_ACCOUNT_ID) {
      const {
        accounts,
        auth,
        content,
        features,
        initialDashboard,
        navigation,
        provider,
        redirects,
        router,
        theme
      } = state;

      state = {
        accounts,
        auth,
        content,
        features,
        initialDashboard,
        navigation,
        provider,
        redirects,
        router,
        theme
      };
    }

    return appReducer(state, action);
  };

  return rootReducer;
}

export const history = createBrowserHistory();
export const rootReducer = createRootReducer(history);
