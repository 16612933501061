import invert from 'lodash/invert';

const PaymentSourceStatus = {
  Cancelled: 'cancelled',
  Chargeable: 'chargeable',
  Consumed: 'consumed',
  Failed: 'failed',
  Pending: 'pending',
  Verified: 'verified',
  VerificationFailed: 'verification_failed',
  PendingAutomatedMicrodeposits: 'pending_automated_microdeposits'
};

export default Object.freeze({
  ...PaymentSourceStatus,
  ...invert(PaymentSourceStatus)
});
