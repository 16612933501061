import { combineReducers } from 'redux';

import allIds from './allIds';
import byId from './byId';
import roles from './roles';

export const reducer = combineReducers({
  allIds,
  byId,
  roles
});
