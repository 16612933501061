export class ReadingService {
  http;

  constructor(http) {
    this.http = http;
  }

  async fetchOne(id) {
    return this.http.get(`/projects/readings/${id}`);
  }

  async create(gardenId, reading) {
    return this.http.post('/projects/readings', { gardenId, ...reading });
  }

  async update(id, gardenId, reading) {
    return this.http.put('/projects/readings', {
      id,
      gardenId,
      ...reading
    });
  }

  async delete(id) {
    return this.http.delete(`/projects/readings/${id}`);
  }

  async fetchMeterData(projectId, month, year) {
    return this.http.get(`/projects/${projectId}/readings/${month}/${year}`);
  }
}
