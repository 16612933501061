import merge from 'lodash/merge';
import { handleActions } from 'redux-actions';

import { addEntities } from 'shared/store/modules/entities';

const initialState = {};

export const byId = handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => merge({}, state, payload.entities.settlementAccounts)
    }
  },

  initialState
);
