import Model from './Model';

class RateType extends Model {
  name = '';
  description = '';
  schedules = [];

  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(RateType.fromJSON);
    }

    const rateType = new RateType();

    return rateType.mapValues(rateType, json);
  }
}

export default RateType;
