import React from 'react';
import { Container } from 'reactstrap';

import DocumentLayout from 'shared/components/DocumentLayout';
import { useContent } from 'shared/store/modules/content/hooks';
import { createMarkup } from 'shared/utilities/createMarkup';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const { data, error, isLoading } = useContent('privacy-policy');

  return (
    <DocumentLayout title="Privacy Policy">
      <Container id="privacy-policy">
        {isLoading ? (
          'Loading...'
        ) : error ? (
          'There was an error loading the content'
        ) : (
          <div dangerouslySetInnerHTML={createMarkup(data.content)} />
        )}
      </Container>
    </DocumentLayout>
  );
};

export default PrivacyPolicy;
