import merge from 'lodash/merge';
import { handleActions } from 'redux-actions';

import { createAllocationResponse, deleteAllocationResponse } from 'shared/store/modules/allocations/actions';
import { addEntities, deleteEntities } from 'shared/store/modules/entities';
import { createPremiseScheduleResponse } from 'shared/store/modules/premises/actions';

const defaultState = {};

export default handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => {
        return merge({}, state, payload.entities.premises);
      }
    },

    [deleteEntities]: {
      next: (state, { payload }) => {
        const newState = Object.assign({}, state);
        delete newState[payload.id];
        return newState;
      }
    },

    [createAllocationResponse]: {
      next: (state, { payload }) => {
        const { premise } = payload.entities.allocations[payload.result];

        return {
          ...state,
          [premise]: {
            ...state[premise],
            allocations: [...state[premise].allocations, payload.result]
          }
        };
      }
    },

    [deleteAllocationResponse]: {
      next: (state, { payload }) => {
        const newState = { ...state };

        let allocationList = newState[payload.premise].allocations;

        if (allocationList) {
          let index = allocationList.indexOf(payload.allocationId);

          allocationList.splice(index, 1);

          return newState;
        }

        return state;
      }
    },

    [createPremiseScheduleResponse]: {
      next: (state, { payload: { entities, result } }) => {
        const { premiseId } = entities.schedules[result];

        return {
          ...state,
          [premiseId]: {
            ...state[premiseId],
            schedules: [...state[premiseId].schedules, result]
          }
        };
      }
    }
  },

  defaultState
);
