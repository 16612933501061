import { createAction } from 'redux-actions';

const MODULE = 'app/admin/creditBands';

const CREATE_CREDIT_BAND_REQUEST = `${MODULE}/CREATE_CREDIT_BAND_REQUEST`;
const CREATE_CREDIT_BAND_RESPONSE = `${MODULE}/CREATE_CREDIT_BAND_RESPONSE`;
const DELETE_CREDIT_BAND_REQUEST = `${MODULE}/DELETE_CREDIT_BAND_REQUEST`;
const DELETE_CREDIT_BAND_RESPONSE = `${MODULE}/DELETE_CREDIT_BAND_RESPONSE`;
const UPDATE_CREDIT_BAND_REQUEST = `${MODULE}/UPDATE_CREDIT_BAND_REQUEST`;
const UPDATE_CREDIT_BAND_RESPONSE = `${MODULE}/UPDATE_CREDIT_BAND_RESPONSE`;

export const createCreditBandRequest = createAction(CREATE_CREDIT_BAND_REQUEST);
export const createCreditBandResponse = createAction(CREATE_CREDIT_BAND_RESPONSE);
export const deleteCreditBandRequest = createAction(DELETE_CREDIT_BAND_REQUEST);
export const deleteCreditBandResponse = createAction(DELETE_CREDIT_BAND_RESPONSE);
export const updateCreditBandRequest = createAction(UPDATE_CREDIT_BAND_REQUEST);
export const updateCreditBandResponse = createAction(UPDATE_CREDIT_BAND_RESPONSE);
