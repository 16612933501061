import { createAction } from 'redux-actions';

const MODULE = 'app/account';

const CHANGE_PASSWORD_REQUEST = `${MODULE}/CHANGE_PASSWORD_REQUEST`;
const CHANGE_PASSWORD_RESPONSE = `${MODULE}/CHANGE_PASSWORD_RESPONSE`;
const FORGOT_PASSWORD_REQUEST = `${MODULE}/FORGOT_PASSWORD_REQUEST`;
const FORGOT_PASSWORD_RESPONSE = `${MODULE}/FORGOT_PASSWORD_RESPONSE`;
const GET_ACCOUNT_REQUEST = `${MODULE}/GET_ACCOUNT_REQUEST`;
const GET_ACCOUNT_RESPONSE = `${MODULE}/GET_ACCOUNT_RESPONSE`;
const RESEND_INVITE_REQUEST = `${MODULE}/RESEND_INVITE_REQUEST`;
const RESEND_INVITE_RESPONSE = `${MODULE}/RESEND_INVITE_RESPONSE`;
const RESET_PASSWORD_REQUEST = `${MODULE}/RESET_PASSWORD_REQUEST`;
const RESET_PASSWORD_RESPONSE = `${MODULE}/RESET_PASSWORD_RESPONSE`;
const UPDATE_ACCOUNT_REQUEST = `${MODULE}/UPDATE_ACCOUNT_REQUEST`;
const UPDATE_ACCOUNT_RESPONSE = `${MODULE}/UPDATE_ACCOUNT_RESPONSE`;

export const changePasswordRequest = createAction(CHANGE_PASSWORD_REQUEST);
export const changePasswordResponse = createAction(CHANGE_PASSWORD_RESPONSE);
export const forgotPasswordRequest = createAction(FORGOT_PASSWORD_REQUEST);
export const forgotPasswordResponse = createAction(FORGOT_PASSWORD_RESPONSE);
export const getAccountRequest = createAction(GET_ACCOUNT_REQUEST);
export const getAccountResponse = createAction(GET_ACCOUNT_RESPONSE);
export const resendInviteRequest = createAction(RESEND_INVITE_REQUEST);
export const resendInviteResponse = createAction(RESEND_INVITE_RESPONSE);
export const resetPasswordRequest = createAction(RESET_PASSWORD_REQUEST);
export const resetPasswordResponse = createAction(RESET_PASSWORD_RESPONSE);
export const updateAccountRequest = createAction(UPDATE_ACCOUNT_REQUEST);
export const updateAccountResponse = createAction(UPDATE_ACCOUNT_RESPONSE);
