import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import styled from 'styled-components';

import Icon from 'shared/components/Icon';
import { CustomerType, UserRole } from 'shared/models';
import CustomerAllocationStatus from 'shared/models/enums/CustomerAllocationStatus';

const NavIcon = styled(Icon)`
  margin-right: 10px;
`;

type CustomerNavigationProps = {
  account: any;
  isUtilityBilled?: boolean;
  navigation: { url: string; icon: string; title: string }[];
};

export const CustomerNavigation = (props: CustomerNavigationProps) => {
  const { account, isUtilityBilled, navigation } = props;

  return (
    <>
      <Nav className="navigation-primary" vertical>
        <NavItem>
          <NavLink exact tag={Link} to="/">
            <NavIcon icon="dashboard" /> Dashboard
          </NavLink>
        </NavItem>

        {account.role === UserRole['Account Manager'] && (
          <NavItem>
            <NavLink tag={Link} to="/account-activity">
              <NavIcon icon="attach_money" /> {isUtilityBilled ? 'Account Activity' : 'Payments'}
            </NavLink>
          </NavItem>
        )}

        {(account.customerAllocationStatus === CustomerAllocationStatus.Allocated ||
          account.customerType === CustomerType.Provisional) && (
          <NavItem>
            <NavLink tag={Link} to="/subscription">
              <NavIcon icon="sunny" /> Subscription
            </NavLink>
          </NavItem>
        )}

        {account.customerAllocationStatus === CustomerAllocationStatus.Allocated && (
          <NavItem>
            <NavLink tag={Link} to="/projects">
              <NavIcon icon="layers" /> Solar Projects
            </NavLink>
          </NavItem>
        )}

        {account.role === UserRole['Account Manager'] && (
          <NavItem>
            <NavLink tag={Link} to="/settings">
              <NavIcon icon="settings" /> Account Settings
            </NavLink>
          </NavItem>
        )}

        {account.customerAllocationStatus !== CustomerAllocationStatus.Allocated && (
          <NavItem>
            <NavLink tag={Link} to="/documents">
              <NavIcon icon="description" /> Documents
            </NavLink>
          </NavItem>
        )}
      </Nav>

      {!!navigation.length && (
        <Nav className="navigation-secondary" vertical>
          {navigation.map((nav, index) => (
            <NavItem key={`key-${nav.url}`}>
              <NavLink href={nav.url} target="_blank">
                <NavIcon icon={nav.icon} /> {nav.title}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      )}
    </>
  );
};
