import { handleActions } from 'redux-actions';

import { validateRequest, validateResponse, importResponse } from 'shared/store/modules/imports/actions';

const defaultState = {};

export default handleActions(
  {
    [validateRequest]: () => defaultState,

    [validateResponse]: {
      next: (_state, { payload }) => {
        if (payload.claimTicket) {
          return {
            [payload.claimTicket]: {
              ...payload.validation
            }
          };
        }
      }
    },

    [importResponse]: {
      next: () => defaultState
    }
  },

  defaultState
);
