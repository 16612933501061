import ReactGA from 'react-ga4';

let options = {};
let hasInit = false;
let currentPage = '';
let state = null;
let shardTracker = null;
let globalTracker = null;
let tracker = [];
let allowGADebugMode = false;
let allowGADevTracking = false;

const isDevEnv = process.env.NODE_ENV.toLowerCase() === 'development' ? true : false;

const initGA = () => {
  let trackerData = [];

  // GLOBAL tracker setup
  if (state.provider.analyticsKeys && state.provider.analyticsKeys.global) {
    globalTracker = 'globalTracker';
    tracker.push(globalTracker);
    trackerData.push({
      trackingId: state.provider.analyticsKeys.global,
      gaOptions: { name: globalTracker, siteSpeedSampleRate: 75 }
    });
  }

  // SHARD tracker setup
  if (
    state.provider.analyticsKeys &&
    state.provider.analyticsKeys.shard &&
    state.provider.analyticsKeys.shard !== state.provider.analyticsKeys.global
  ) {
    shardTracker = 'shardTracker';
    tracker.push(shardTracker);
    trackerData.push({
      trackingId: state.provider.analyticsKeys.shard,
      gaOptions: { name: shardTracker, siteSpeedSampleRate: 75 }
    });
  }

  // do not track on dev its not set
  if (isDevEnv && !allowGADevTracking) {
    return;
  }

  ReactGA.initialize(trackerData, { debug: allowGADebugMode, alwaysSendToDefaultTracker: false });

  hasInit = true;
};

const trackPage = page => {
  // do not track local dev environments unless bit is set
  if (isDevEnv && !allowGADevTracking) {
    return;
  }

  // init the GA to track into the right places
  if (!hasInit && state.provider) {
    initGA();
  }

  // if a user exists let's track everything to that user
  if (state.auth.userId) {
    options = { ...options, userId: state.auth.userId };
  }

  ReactGA.set({ page, ...options }, tracker);
};

export const googleAnalytics = store => next => action => {
  // track URL changes as page views
  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;
    if (currentPage !== nextPage) {
      currentPage = nextPage;
      state = store.getState();
      trackPage(nextPage);
    }
  }
  return next(action);
};
