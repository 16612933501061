import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { UserRole } from 'shared/models';
import { getIsNavOpen } from 'shared/store/modules/nav/selectors';
import { SubscriptionPaymentType } from 'shared/models/SubscriptionPaymentType';

import { AdminNavigation, CustomerNavigation, Logo, PMNavigation } from './components';

import styles from './Navigation.styles';
import { useSelector } from 'react-redux';
import { selectActiveAccount } from 'shared/store/modules/accounts/selectors';
import { selectRole } from 'shared/store/modules/auth/selectors';

export const Navigation = () => {
  const account = useSelector(selectActiveAccount);
  const paymentType = useSelector(state => state?.admin?.dashboard?.data?.subscription?.paymentType);
  const isOpen = useSelector(getIsNavOpen);
  const navigation = useSelector(state => state.navigation);
  const provider = useSelector(state => state.provider);
  const role = useSelector(selectRole);
  const theme = useSelector(state => state.theme);

  const [isUtilityBilled, setIsUtiltyBilled] = useState(false);

  const cls = classNames({
    navigation: true,
    'is-open': isOpen
  });

  useEffect(() => {
    if (paymentType && paymentType === SubscriptionPaymentType.UtilityBilled) {
      setIsUtiltyBilled(true);
    }
  }, [paymentType]);

  // To-Do: isUtilityBilled in <CustomerNavigation /> is being defaulted to false. Add logic for determining this.

  const renderNavigation = () => {
    switch (role) {
      case UserRole.Admin:
        return <AdminNavigation />;
      case UserRole['Portfolio Manager']:
        return <PMNavigation />;
      default:
        return <CustomerNavigation account={account} isUtilityBilled={isUtilityBilled} navigation={navigation} />;
    }
  };

  return (
    <div css={styles}>
      <div className={cls}>
        <Logo name={`${provider.name}`} url={`${theme.logo}`} />
        {renderNavigation()}
      </div>
    </div>
  );
};
