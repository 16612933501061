export class ContentService {
  http;

  constructor(http) {
    this.http = http;
  }

  async fetchContent(slug) {
    return this.http.get(`/content/${slug}`);
  }
}
