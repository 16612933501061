import { schema } from 'normalizr';

export const premise = new schema.Entity('premises');
export const premises = [premise];

premise.define({
  allocations: require('shared/store/modules/allocations/schemas').allocations,
  customer: require('shared/store/modules/subscribers/schemas').subscriber,
  projects: require('shared/store/modules/projects/schemas').projects,
  rateType: require('shared/store/modules/rateTypes/schemas').rateType,
  schedules: require('shared/store/modules/schedules/schemas').schedules,
  subscription: require('shared/store/modules/subscriptions/schemas').subscription,
  utility: require('shared/store/modules/utilities/schemas').utility
});
