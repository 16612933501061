import Model from './Model';

class Premise extends Model {
  address = '';
  address2 = '';
  annualUsage = '';
  city = '';
  countId = '';
  countyId = '';
  creditRateType = '';
  isDisabled = false;
  latitude = '';
  longitude = '';
  number = '';
  production = [];
  projects = [];
  rateClassName = '';
  stateId = '';
  useUtilityRateCeilingOverride = false;
  utilityAccountNumber = '';
  utilityCreditRate = '';
  utilityId = '';
  utilityName = '';
  utilityRateCeilingOverrideAmount = '';
  utilityRateTypeId = '';
  zipCode = '';

  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(Premise.fromJSON);
    }

    const premise = new Premise();

    return premise.mapValues(premise, json);
  }
}

export default Premise;
