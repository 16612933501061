import { handleActions } from 'redux-actions';

import { addEntities } from 'shared/store/modules/entities';

// Documents
// -----------------------------------------------------------------------------

// Default State
const defaultState = {};

// Reducer
export default handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => ({
        ...state,
        ...payload.entities.documents
      })
    }
  },

  defaultState
);
