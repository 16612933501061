import { handleActions } from 'redux-actions';

import {
  loginRequest,
  auth0Response,
  afterLoginResponse,
  renewSessionRequest,
  renewSessionResponse
} from 'shared/store/modules/auth/actions';

type AuthState = {
  access_token?: string;
  id_token?: string;
  expires?: string;
  expires_in?: number;
  hostname?: string;
  isRefreshing?: boolean;
  issued?: string;
  message?: string | null;
  nextStepToComplete?: string;
  prospectId?: string;
  refresh_token?: string;
  refresh_token_expires?: string;
  refresh_token_expires_in?: number;
  refresh_token_issued?: string;
  role?: string;
  scope?: string;
  type?: string;
  userId?: string;
  userName?: string;
  isStreamlinedProspect?: boolean;
};

const defaultState: AuthState = {};

export const reducer = handleActions(
  {
    [loginRequest.toString()]: {
      next: state => {
        return {
          ...state,
          message: null
        };
      }
    },

    [auth0Response.toString()]: {
      next: (state, { payload }) => {
        const {
          access_token,
          id_token,
          expires_in,
          expires,
          userId,
          scope,
          role,
          hostname
        } = payload;

        return {
          ...state,
          access_token,
          id_token,
          expires_in,
          expires,
          userId,
          scope,
          role,
          hostname,
        }
      }
    },

    [afterLoginResponse.toString()]: {
      next: (state, { payload }) => {
        const {
          nextStepToComplete,
          prospectId,
          role,
          userName,
          isStreamlinedProspect
        } = payload;

        return {
          ...state,
          nextStepToComplete,
          prospectId,
          role,
          userName,
          isStreamlinedProspect
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message
      })
    },

    [renewSessionRequest.toString()]: {
      next: state => ({
        ...state,
        isRefreshing: true
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false
      })
    },

    [renewSessionResponse.toString()]: {
      next: (_state, { payload }) => ({
        ...payload,
        isRefreshing: false
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false
      })
    }
  },

  defaultState
);
