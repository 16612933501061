import Model from './Model';

class Project extends Model {
  address = '';
  address2 = '';
  capacity = '';
  city = '';
  commercialStartDate = '';
  countyId = '';
  estimatedYield = '';
  externalId = '';
  id = '';
  imageUrl = '';
  isDisabled = false;
  latitude = '';
  longitude = '';
  meterId = '';
  meterProvider = '';
  name = '';
  operationStatus = '';
  portfolioId = '';
  stateId = '';
  utilityId = '';
  zipCode = '';

  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(Project.fromJSON);
    }

    const project = new Project();

    return project.mapValues(project, json);
  }
}

export default Project;
