import { schema } from 'normalizr';

export const utility = new schema.Entity('utilities');
export const utilities = [utility];

utility.define({
  rateType: require('shared/store/modules/rateTypes/schemas').rateType,
  rateTypes: require('shared/store/modules/rateTypes/schemas').rateTypes,
  offers: require('shared/store/modules/offers/schemas').offers
});
