export class ConfigService {
  http;

  constructor(http) {
    this.http = http;
  }

  async fetch() {
    return this.http.get('/config');
  }
}
