import { createAction } from 'redux-actions';

const MODULE = 'app/admin/activities';

const CREATE_ACTIVITY_REQUEST = `${MODULE}/CREATE_ACTIVITY_REQUEST`;
const CREATE_ACTIVITY_RESPONSE = `${MODULE}/CREATE_ACTIVITY_RESPONSE`;
const CREATE_PORTFOLIO_ACTIVITY_REQUEST = `${MODULE}/CREATE_PORTFOLIO_ACTIVITY_REQUEST`;
const CREATE_PORTFOLIO_ACTIVITY_RESPONSE = `${MODULE}/CREATE_PORTFOLIO_ACTIVITY_RESPONSE`;

export const createActivityRequest = createAction(CREATE_ACTIVITY_REQUEST);
export const createActivityResponse = createAction(CREATE_ACTIVITY_RESPONSE);
export const createPortfolioActivityRequest = createAction(CREATE_PORTFOLIO_ACTIVITY_REQUEST);
export const createPortfolioActivityResponse = createAction(CREATE_PORTFOLIO_ACTIVITY_RESPONSE);
