import { createAction } from 'redux-actions';

const MODULE = 'app/admin/portfolios';

const CREATE_PORTFOLIO_REQUEST = `${MODULE}/CREATE_PORTFOLIO_REQUEST`;
const CREATE_PORTFOLIO_RESPONSE = `${MODULE}/CREATE_PORTFOLIO_RESPONSE`;
const EXPORT_PORTFOLIO_ACTIVITIES_REQUEST = `${MODULE}/EXPORT_PORTFOLIO_ACTIVITIES_REQUEST`;
const EXPORT_PORTFOLIO_ACTIVITIES_RESPONSE = `${MODULE}/EXPORT_PORTFOLIO_ACTIVITIES_RESPONSE`;
const EXPORT_PORTFOLIO_PRODUCTION_REPORT_REQUEST = `${MODULE}/EXPORT_PORTFOLIO_PRODUCTION_REPORT_REQUEST`;
const EXPORT_PORTFOLIO_PRODUCTION_REPORT_RESPONSE = `${MODULE}/EXPORT_PORTFOLIO_PRODUCTION_REPORT_RESPONSE`;
const FETCH_ALL_PORTFOLIOS_REQUEST = `${MODULE}/FETCH_ALL_PORTFOLIOS_REQUEST`;
const FETCH_ALL_PORTFOLIOS_RESPONSE = `${MODULE}/FETCH_ALL_PORTFOLIOS_RESPONSE`;
const FETCH_PORTFOLIO_DETAILS_REQUEST = `${MODULE}/FETCH_PORTFOLIO_DETAILS_REQUEST`;
const FETCH_PORTFOLIO_DETAILS_RESPONSE = `${MODULE}/FETCH_PORTFOLIO_DETAILS_RESPONSE`;
const FETCH_PORTFOLIO_REQUEST = `${MODULE}/FETCH_PORTFOLIO_REQUEST`;
const FETCH_PORTFOLIO_RESPONSE = `${MODULE}/FETCH_PORTFOLIO_RESPONSE`;
const FETCH_PORTFOLIO_SETTLEMENT_DETAILS_REQUEST = `${MODULE}/FETCH_PORTFOLIO_SETTLEMENT_DETAILS_REQUEST`;
const FETCH_PORTFOLIO_SETTLEMENT_DETAILS_RESPONSE = `${MODULE}/FETCH_PORTFOLIO_SETTLEMENT_DETAILS_RESPONSE`;
const SETTLE_PORTFOLIO_BALANCE_REQUEST = `${MODULE}/SETTLE_PORTFOLIO_BALANCE_REQUEST`;
const SETTLE_PORTFOLIO_BALANCE_RESPONSE = `${MODULE}/SETTLE_PORTFOLIO_BALANCE_RESPONSE`;
const UPDATE_PORTFOLIO_REQUEST = `${MODULE}/UPDATE_PORTFOLIO_REQUEST`;
const UPDATE_PORTFOLIO_RESPONSE = `${MODULE}/UPDATE_PORTFOLIO_RESPONSE`;

export const createPortfolioRequest = createAction(CREATE_PORTFOLIO_REQUEST);
export const createPortfolioResponse = createAction(CREATE_PORTFOLIO_RESPONSE);
export const exportPortfolioActivitiesRequest = createAction(EXPORT_PORTFOLIO_ACTIVITIES_REQUEST);
export const exportPortfolioActivitiesResponse = createAction(EXPORT_PORTFOLIO_ACTIVITIES_RESPONSE);
export const exportPortfolioProductionReportRequest = createAction(EXPORT_PORTFOLIO_PRODUCTION_REPORT_REQUEST);
export const exportPortfolioProductionReportResponse = createAction(EXPORT_PORTFOLIO_PRODUCTION_REPORT_RESPONSE);
export const fetchAllPortfoliosRequest = createAction(FETCH_ALL_PORTFOLIOS_REQUEST);
export const fetchAllPortfoliosResponse = createAction(FETCH_ALL_PORTFOLIOS_RESPONSE);
export const fetchPortfolioDetailsRequest = createAction(FETCH_PORTFOLIO_DETAILS_REQUEST);
export const fetchPortfolioDetailsResponse = createAction(FETCH_PORTFOLIO_DETAILS_RESPONSE);
export const fetchPortfolioRequest = createAction(FETCH_PORTFOLIO_REQUEST);
export const fetchPortfolioResponse = createAction(FETCH_PORTFOLIO_RESPONSE);
export const fetchPortfolioSettlementDetailsRequest = createAction(FETCH_PORTFOLIO_SETTLEMENT_DETAILS_REQUEST);
export const fetchPortfolioSettlementDetailsResponse = createAction(FETCH_PORTFOLIO_SETTLEMENT_DETAILS_RESPONSE);
export const settlePortfolioBalanceRequest = createAction(SETTLE_PORTFOLIO_BALANCE_REQUEST);
export const settlePortfolioBalanceResponse = createAction(SETTLE_PORTFOLIO_BALANCE_RESPONSE);
export const updatePortfolioRequest = createAction(UPDATE_PORTFOLIO_REQUEST);
export const updatePortfolioResponse = createAction(UPDATE_PORTFOLIO_RESPONSE);
