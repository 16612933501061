import merge from 'lodash/merge';
import { handleActions } from 'redux-actions';

import {
  deleteDeferredChargeResponse,
  fetchDeferredChargesResponse
} from 'shared/store/modules/deferredCharges/actions';
import { addEntities } from 'shared/store/modules/entities';

const defaultState = {};

export default handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => {
        return merge({}, state, payload.entities.deferredCharges);
      }
    },

    [fetchDeferredChargesResponse]: {
      next: (_state, { payload }) => {
        return payload.entities.deferredCharges;
      }
    },

    [deleteDeferredChargeResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          [payload]: {
            ...state[payload],
            deleted: true
          }
        };
      }
    }
  },

  defaultState
);
