import { css } from '@emotion/react';
import sctheme from 'app/styles/sctheme';

const styles = () => css`
  @media (max-width: 720px) {
    position: fixed;
    width: 100%;
    z-index: 1000;
  }

  .header {
    display: flex;
    position: relative;
    background-color: ${sctheme.grays.$900};
    color: ${sctheme.white};
  }

  .header .account-holder {
    flex-grow: 1;
    padding: 19px;
  }

  .header .account-holder .breadcrumb {
    padding: 0;
    background-color: transparent;
    margin: 0;
  }

  .header .account-holder .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    color: #8ab7ed;
  }

  .header .account-holder .breadcrumb .breadcrumb-item.active {
    color: ${sctheme.white};
  }

  .header .nav-utility {
    display: flex;
    width: 100%;
  }

  .header .nav-utility .account-name {
    padding-right: 10px;
    font-weight: 600;
  }

  .header .nav-utility .subscriber-name {
    color: ${sctheme.grays.$300};
  }

  .header .nav-utility .account-toggle {
    display: block;
    cursor: pointer;
    padding: 19px;
    color: ${sctheme.white};
    text-decoration: none;
  }

  .header .nav-utility .account-toggle:hover {
    text-decoration: none;
  }

  .nav-toggler {
    transition: transform 0.35s ease-in-out;
  }

  .dropdown-menu {
    margin-top: 0;
    padding: 0;
    border: 1px solid white;
    border-radius: 0 0 4px 4px;
    background-clip: none;
  }

  .ddm-account {
    right: 2px;
    left: auto;
  }

  .dropdown-item {
    padding: 0.5rem 1rem;
    color: ${sctheme.black};

    &:hover {
      background-color: ${sctheme.grays.$100};
    }

    &:active {
      color: ${sctheme.black};
    }
  }

  @media (min-width: 1200px) {
    .nav-toggler {
      display: none !important;
    }
  }

  .nav-toggler.active {
    transform: translateX(250px);
    background-color: ${sctheme.grays.$800};
    z-index: 1001;
  }
`;

export default styles;
