import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectAccountId, selectAllAccounts } from './selectors';
import { setActiveAccountId } from 'shared/store/modules/accounts/actions';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

export function useAccounts() {
  const activeAccountId = useSelector(selectAccountId);
  const accounts = useSelector(selectAllAccounts);
  const dispatch = useAppDispatch();

  return {
    accounts,
    activeAccountId,
    setActiveAccountId: useCallback((accountId: string) => dispatch(setActiveAccountId(accountId)), [dispatch])
  };
}
