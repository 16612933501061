import merge from 'lodash/merge';
import { combineActions, handleActions } from 'redux-actions';

import { createCreditBandResponse } from 'shared/store/modules/creditBands/actions';
import { addEntities, deleteEntities } from 'shared/store/modules/entities';
import {
  fetchOfferResponse,
  updateOfferCountiesResponse,
  updateOfferProjectsResponse,
  updateOfferReferralCodesResponse
} from 'shared/store/modules/offers/actions';

const defaultState = {};

export default handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => merge({}, state, payload.entities.offers)
    },

    [deleteEntities]: {
      next: (state, { payload }) => {
        const newState = Object.assign({}, state);
        delete newState[payload.id];
        return newState;
      }
    },

    [createCreditBandResponse]: {
      next: (state, { payload }) => {
        const { offerId } = payload.entities.creditBands[payload.result];

        return {
          ...state,
          [offerId]: {
            ...state[offerId],
            creditBands: [...state[offerId].creditBands, payload.result]
          }
        };
      }
    },

    [combineActions(updateOfferCountiesResponse, updateOfferProjectsResponse, updateOfferReferralCodesResponse)]: {
      next: (state, { payload }) => {
        return {
          ...state,
          [payload.id]: payload
        };
      }
    },

    [fetchOfferResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          [payload.result]: payload.entities.offers[payload.result]
        };
      }
    }
  },

  defaultState
);
