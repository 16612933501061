import { createAction } from 'redux-actions';

const MODULE = 'app/admin/imports';

const FETCH_ERROR_REPORT_REQUEST = `${MODULE}/FETCH_ERROR_REPORT_REQUEST`;
const FETCH_ERROR_REPORT_RESPONSE = `${MODULE}/FETCH_ERROR_REPORT_RESPONSE`;
const FETCH_INSTRUCTIONS_REQUEST = `${MODULE}/FETCH_INSTRUCTIONS_REQUEST`;
const FETCH_INSTRUCTIONS_RESPONSE = `${MODULE}/FETCH_INSTRUCTIONS_RESPONSE`;
const FETCH_REPORT_REQUEST = `${MODULE}/FETCH_REPORT_REQUEST`;
const FETCH_REPORT_RESPONSE = `${MODULE}/FETCH_REPORT_RESPONSE`;
const FETCH_TEMPLATE_REQUEST = `${MODULE}/FETCH_TEMPLATE_REQUEST`;
const FETCH_TEMPLATE_RESPONSE = `${MODULE}/FETCH_TEMPLATE_RESPONSE`;
const FETCH_ID_REFERENCE_REQUEST = `${MODULE}/FETCH_ID_REFERENCE_REQUEST`;
const FETCH_ID_REFERENCE_RESPONSE = `${MODULE}/FETCH_ID_REFERENCE_RESPONSE`;
const IMPORT_REQUEST = `${MODULE}/IMPORT_REQUEST`;
const IMPORT_RESPONSE = `${MODULE}/IMPORT_RESPONSE`;
const VALIDATE_REQUEST = `${MODULE}/VALIDATE_REQUEST`;
const VALIDATE_RESPONSE = `${MODULE}/VALIDATE_RESPONSE`;

export const fetchErrorReportRequest = createAction(FETCH_ERROR_REPORT_REQUEST);
export const fetchErrorReportResponse = createAction(FETCH_ERROR_REPORT_RESPONSE);
export const fetchInstructionsRequest = createAction(FETCH_INSTRUCTIONS_REQUEST);
export const fetchInstructionsResponse = createAction(FETCH_INSTRUCTIONS_RESPONSE);
export const fetchReportRequest = createAction(FETCH_REPORT_REQUEST);
export const fetchReportResponse = createAction(FETCH_REPORT_RESPONSE);
export const fetchTemplateRequest = createAction(FETCH_TEMPLATE_REQUEST);
export const fetchTemplateResponse = createAction(FETCH_TEMPLATE_RESPONSE);
export const fetchIdReferenceRequest = createAction(FETCH_ID_REFERENCE_REQUEST);
export const fetchIdReferenceResponse = createAction(FETCH_ID_REFERENCE_RESPONSE);
export const importRequest = createAction(IMPORT_REQUEST);
export const importResponse = createAction(IMPORT_RESPONSE);
export const validateRequest = createAction(VALIDATE_REQUEST);
export const validateResponse = createAction(VALIDATE_RESPONSE);
