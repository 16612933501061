class Document {
  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(Document.fromJSON);
    }

    const document = new Document();

    return {
      ...json,
      ...document
    };
  }
}

export default Document;
