import merge from 'lodash/merge';
import { handleActions } from 'redux-actions';

import { addEntities, deleteEntities } from 'shared/store/modules/entities';

const defaultState = {};

export default handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => {
        return merge({}, state, payload.entities.referralCodes);
      }
    },

    [deleteEntities]: {
      next: (state, { payload }) => {
        return {
          ...state,
          [payload.id]: {
            ...state[payload.id],
            deleted: true
          }
        };
      }
    }
  },

  defaultState
);
