import { handleActions } from 'redux-actions';
import { fetchAllProjectsRequest, fetchAllProjectsResponse } from 'shared/store/modules/projects/actions';
import { AsyncStatus } from 'shared/models/enums/AsyncStatus';

const defaultState = AsyncStatus.Idle;

export default handleActions(
  {
    [fetchAllProjectsRequest.toString()]: {
      next: () => AsyncStatus.Pending
    },

    [fetchAllProjectsResponse.toString()]: {
      next: () => AsyncStatus.Fulfilled,
      throw: () => AsyncStatus.Rejected
    }
  },

  defaultState
);