import { createAction } from 'redux-actions';

const MODULE = 'app/projects';

const CREATE_PROJECT_REQUEST = `${MODULE}/CREATE_PROJECT_REQUEST`;
const CREATE_PROJECT_RESPONSE = `${MODULE}/CREATE_PROJECT_RESPONSE`;
const EXPORT_CUSTOMER_PROJECT_REQUEST = `${MODULE}/EXPORT_CUSTOMER_PROJECT_REQUEST`;
const EXPORT_CUSTOMER_PROJECT_RESPONSE = `${MODULE}/EXPORT_CUSTOMER_PROJECT_RESPONSE`;
const EXPORT_PROJECT_PAYMENTS_REQUEST = `${MODULE}/EXPORT_PROJECT_PAYMENTS_REQUEST`;
const EXPORT_PROJECT_PAYMENTS_RESPONSE = `${MODULE}/EXPORT_PROJECT_PAYMENTS_RESPONSE`;
const EXPORT_PROJECT_PRODUCTION_REQUEST = `${MODULE}/EXPORT_PROJECT_PRODUCTION_REQUEST`;
const EXPORT_PROJECT_PRODUCTION_RESPONSE = `${MODULE}/EXPORT_PROJECT_PRODUCTION_RESPONSE`;
const FETCH_ALL_PROJECTS_REQUEST = `${MODULE}/FETCH_ALL_PROJECTS_REQUEST`;
const FETCH_ALL_PROJECTS_RESPONSE = `${MODULE}/FETCH_ALL_PROJECTS_RESPONSE`;
const FETCH_CUSTOMER_PROJECTS_REQUEST = `${MODULE}/FETCH_CUSTOMER_PROJECTS_REQUEST`;
const FETCH_CUSTOMER_PROJECTS_RESPONSE = `${MODULE}/FETCH_CUSTOMER_PROJECTS_RESPONSE`;
const FETCH_CUSTOMER_PROJECT_REQUEST = `${MODULE}/FETCH_CUSTOMER_PROJECT_REQUEST`;
const FETCH_CUSTOMER_PROJECT_RESPONSE = `${MODULE}/FETCH_CUSTOMER_PROJECT_RESPONSE`;
const FETCH_PROJECT_ALLOCATIONS_REQUEST = `${MODULE}/FETCH_PROJECT_ALLOCATIONS_REQUEST`;
const FETCH_PROJECT_ALLOCATIONS_RESPONSE = `${MODULE}/FETCH_PROJECT_ALLOCATIONS_RESPONSE`;
const FETCH_PROJECT_DETAILS_REQUEST = `${MODULE}/FETCH_PROJECT_DETAILS_REQUEST`;
const FETCH_PROJECT_DETAILS_RESPONSE = `${MODULE}/FETCH_PROJECT_DETAILS_RESPONSE`;
const FETCH_PROJECT_REQUEST = `${MODULE}/FETCH_PROJECT_REQUEST`;
const FETCH_PROJECT_RESPONSE = `${MODULE}/FETCH_PROJECT_RESPONSE`;
const UPDATE_PROJECT_REQUEST = `${MODULE}/UPDATE_PROJECT_REQUEST`;
const UPDATE_PROJECT_RESPONSE = `${MODULE}/UPDATE_PROJECT_RESPONSE`;
const UPLOAD_PROJECT_IMAGE_REQUEST = `${MODULE}/UPLOAD_PROJECT_IMAGE_REQUEST`;
const UPLOAD_PROJECT_IMAGE_RESPONSE = `${MODULE}/UPLOAD_PROJECT_IMAGE_RESPONSE`;
const FETCH_AUTO_ALLOCATABLE_PROJECTS_REQUEST = `${MODULE}/FETCH_AUTO_ALLOCATABLE_PROJECTS_REQUEST`;
const FETCH_AUTO_ALLOCATABLE_PROJECTS_RESPONSE = `${MODULE}/FETCH_AUTO_ALLOCATABLE_PROJECTS_RESPONSE`;

export const createProjectRequest = createAction(CREATE_PROJECT_REQUEST);
export const createProjectResponse = createAction(CREATE_PROJECT_RESPONSE);
export const customerProjectExportRequest = createAction(EXPORT_CUSTOMER_PROJECT_REQUEST);
export const customerProjectExportResponse = createAction(EXPORT_CUSTOMER_PROJECT_RESPONSE);
export const customerProjectFetchRequest = createAction(FETCH_CUSTOMER_PROJECT_REQUEST);
export const customerProjectFetchResponse = createAction(FETCH_CUSTOMER_PROJECT_RESPONSE);
export const customerProjectsFetchRequest = createAction(FETCH_CUSTOMER_PROJECTS_REQUEST);
export const customerProjectsFetchResponse = createAction(FETCH_CUSTOMER_PROJECTS_RESPONSE);
export const exportProjectPaymentsRequest = createAction(EXPORT_PROJECT_PAYMENTS_REQUEST);
export const exportProjectPaymentsResponse = createAction(EXPORT_PROJECT_PAYMENTS_RESPONSE);
export const exportProjectProductionRequest = createAction(EXPORT_PROJECT_PRODUCTION_REQUEST);
export const exportProjectProductionResponse = createAction(EXPORT_PROJECT_PRODUCTION_RESPONSE);
export const fetchAllProjectsRequest = createAction(FETCH_ALL_PROJECTS_REQUEST);
export const fetchAllProjectsResponse = createAction(FETCH_ALL_PROJECTS_RESPONSE);
export const fetchProjectAllocationsRequest = createAction(FETCH_PROJECT_ALLOCATIONS_REQUEST);
export const fetchProjectAllocationsResponse = createAction(FETCH_PROJECT_ALLOCATIONS_RESPONSE);
export const fetchProjectDetailsRequest = createAction(FETCH_PROJECT_DETAILS_REQUEST);
export const fetchProjectDetailsResponse = createAction(FETCH_PROJECT_DETAILS_RESPONSE);
export const fetchProjectRequest = createAction(FETCH_PROJECT_REQUEST);
export const fetchProjectResponse = createAction(FETCH_PROJECT_RESPONSE);
export const updateProjectRequest = createAction(UPDATE_PROJECT_REQUEST);
export const updateProjectResponse = createAction(UPDATE_PROJECT_RESPONSE);
export const uploadProjectImageRequest = createAction(UPLOAD_PROJECT_IMAGE_REQUEST);
export const uploadProjectImageResponse = createAction(UPLOAD_PROJECT_IMAGE_RESPONSE);
export const fetchAutoAllocatableProjectsRequest = createAction(FETCH_AUTO_ALLOCATABLE_PROJECTS_REQUEST);
export const fetchAutoAllocatableProjectsResponse = createAction(FETCH_AUTO_ALLOCATABLE_PROJECTS_RESPONSE);
