export class ProspectService {
  http;

  constructor(http) {
    this.http = http;
  }

  async fetchProspect(prospectId) {
    return this.http.get(`/prospects/${prospectId}`);
  }

  // GET /prospects/savings
  async fetchSavings(zipCode, powerCost, isAnnual = false) {
    return this.http.get('/prospects/savings', {
      params: {
        zipCode: zipCode,
        powerCost: powerCost,
        isAnnual: isAnnual
      }
    });
  }

  // GET /prospects/inquiry
  async fetchInquiryInfo(zipCode) {
    return this.http.get('/prospects/inquiry', {
      params: {
        zipCode: zipCode
      }
    });
  }

  // GET /prospects/utility-meters
  async fetchUtilityMeters(prospectId) {
    return this.http.get(`/prospects/${prospectId}/utility-meters`);
  }

  // GET /prospects/utility-bills
  async fetchUtilityBills(prospectId) {
    return this.http.get(`/prospects/${prospectId}/utility-bills`);
  }

  // GET /prospects/rate-types
  async fetchRateTypes(prospectId) {
    return this.http.get(`/prospects/${prospectId}/rate-types`);
  }

  // POST /prospects/inquiry
  async createProspect(zipCode, associatedUtilityId) {
    return this.http.post('/prospects/inquiry', null, {
      params: { zipCode: zipCode, associatedUtilityId: associatedUtilityId }
    });
  }

  // POST /prospects
  async updateProspect(payload) {
    return this.http.post('/prospects', payload);
  }

  // POST /prospects/premise
  async createProspectPremise(payload) {
    return this.http.post('/prospects/premise', payload);
  }

  // GET /prospects/utility-authorization
  async getAuthorizationUrl(prospectId, referral) {
    return this.http.get(`/prospects/${prospectId}/utility-authorization`);
  }

  // PATCH /prospects/utility-authorization-complete
  async setUtilAuthComplete(prospectId, referral) {
    return this.http.patch(`/prospects/utility-authorization-complete`, null, {
      params: {
        referral,
        prospectId
      }
    });
  }

  // PATCH /prospects/
  async patchProspect(data) {
    return this.http.patch(`/prospects/`, data);
  }

  // POST /prospects/resend-invite
  async resendInvite(prospectId) {
    return this.http.post('/prospects/resend-invite', null, {
      params: { prospectId }
    });
  }

  // POST /prospects/validate
  async validateToken(token, email) {
    return this.http.post('/prospects/validate', {
      validationToken: token,
      email: email
    });
  }

  // POST /prospects/google-sign-up
  async signup(code) {
    return this.http.post('/prospects/google-sign-up', {
      code: code
    });
  }

  // POST /prospects/password
  async setPassword(token, email, password) {
    return this.http.post('/prospects/password', {
      validationToken: token,
      email: email,
      newPassword: password
    });
  }

  // GET /prospects/offers/{utilityId}
  async getOffers(prospectId, referralCode) {
    return this.http.get(`/prospects/offers/${prospectId}`, {
      params: {
        referralCode
      }
    });
  }

  // GET /prospects/legal/{subscriptionTemplateId}
  async getLegal(subscriptionTemplateId) {
    return this.http.get(`/prospects/legal/${subscriptionTemplateId}`);
  }

  // POST /prospects/sign/{subscriptionTemplateId}
  async signOffer(claimTicket) {
    return this.http.post('/prospects/sign', null, {
      params: { claimTicket }
    });
  }

  // POST /prospects/credit-check/
  async creditCheck(values) {
    return this.http.post('/prospects/check-credit', values);
  }

  // POST /prospects/subscription/generate
  async fetchClaimTicket(prospectId, subscriptionTemplateId) {
    return this.http.post('/prospects/subscription/generate', null, {
      params: {
        prospectId: prospectId,
        subscriptionTemplateId: subscriptionTemplateId
      }
    });
  }

  // HEAD /prospects/subscription/{claimTicket}
  async validateContract(claimTicket) {
    return this.http.head(`/prospects/subscription/${claimTicket}`, {
      responseType: 'blob'
    });
  }

  // GET /prospects/subscription/review/{claimTicket}
  async fetchContractDetails(claimTicket) {
    return this.http.get(`/prospects/subscription/review/${claimTicket}`);
  }

  // GET /prospects/subscription/review/{prospectId}
  async fetchContractDetailsWithProspectId(prospectId) {
    return this.http.get(`prospects/subscription/review-no-claimticket/${prospectId}`);
  }

  // GET /prospects/subscription/{claimTicket}
  async fetchContract(claimTicket) {
    return this.http.get(`/prospects/subscription/${claimTicket}`, {
      responseType: 'blob'
    });
  }

  async export(args = {}) {
    args.renderMasthead = args.renderMasthead || false;
    return this.http.get('/prospects/export', {
      params: {
        renderMasthead: args.renderMasthead,
        onlyIneligible: args.onlyIneligible,
        beginDate: args.beginDate,
        endDate: args.endDate,
        nextStep: args.nextStepValue
      }
    });
  }

  async exportCompleted(args) {
    return this.http.get('/prospects/completed/export', {
      params: {
        ...args
      }
    });
  }

  async creditMixExport(args) {
    return this.http.get('/prospects/credit-mix/export', {
      params: {
        ...args
      }
    });
  }

  async fetchCreditCheckAction(subscriptionTemplateId) {
    return this.http.get(`/prospects/should-check-credit?subscriptionTemplateId=${subscriptionTemplateId}`);
  }

  async recordOfferSelection(offerId) {
    return this.http.post('/prospects/choose-offer', {
      subscriptionTemplateId: offerId
    });
  }

  async fetchProspectDocuments() {
    return this.http.get('/prospects/documents');
  }

  async uploadProspectDocument(formData) {
    return this.http.post('/prospects/documents/upload', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  async checkCapacity(stateName, id, stateId) {
    return this.http.post('prospects/sign-up-inquiry', null, {
      params: {
        stateName,
        associatedUtilityId: id,
        stateId
      }
    });
  }

  async checkReferralCode(id) {
    return this.http.get(`/referralcodes/validate?referralCode=${id}`);
  }

  async getCodeDetails(id) {
    return this.http.get(`referralCodes/promos/${id}`);
  }
}
