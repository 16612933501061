import merge from 'lodash/merge';
import { handleActions } from 'redux-actions';

import { addEntities, deleteEntities } from 'shared/store/modules/entities';
import { addContractsToUtility } from 'shared/store/modules/utilities/actions';

const defaultState = {};

export default handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => {
        return merge({}, state, payload.entities.utilities);
      }
    },

    [deleteEntities]: {
      next: (state, { payload }) => {
        const newState = Object.assign({}, state);
        delete newState[payload.id];
        return newState;
      }
    },

    [addContractsToUtility]: {
      next: (state, { payload }) => ({
        ...state,
        [payload.utilityId]: {
          ...state[payload.utilityId],
          contracts: payload.contracts
        }
      })
    }
  },

  defaultState
);
