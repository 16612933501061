import { createAction } from 'redux-actions';

const MODULE = 'app/admin/schedules';

const CREATE_SCHEDULE_REQUEST = `${MODULE}/CREATE_SCHEDULE_REQUEST`;
const CREATE_SCHEDULE_RESPONSE = `${MODULE}/CREATE_SCHEDULE_RESPONSE`;
const FETCH_SCHEDULE_REQUEST = `${MODULE}/FETCH_SCHEDULE_REQUEST`;
const FETCH_SCHEDULE_RESPONSE = `${MODULE}/FETCH_SCHEDULE_RESPONSE`;
const UPDATE_SCHEDULE_REQUEST = `${MODULE}/UPDATE_SCHEDULE_REQUEST`;
const UPDATE_SCHEDULE_RESPONSE = `${MODULE}/UPDATE_SCHEDULE_RESPONSE`;

export const createScheduleRequest = createAction(CREATE_SCHEDULE_REQUEST);
export const createScheduleResponse = createAction(CREATE_SCHEDULE_RESPONSE);
export const fetchScheduleRequest = createAction(FETCH_SCHEDULE_REQUEST);
export const fetchScheduleResponse = createAction(FETCH_SCHEDULE_RESPONSE);
export const updateScheduleRequest = createAction(UPDATE_SCHEDULE_REQUEST);
export const updateScheduleResponse = createAction(UPDATE_SCHEDULE_RESPONSE);
