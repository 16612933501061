import { handleActions } from 'redux-actions';

import { deleteEntities } from 'shared/store/modules/entities';
import { fetchAllUtilitiesResponse, fetchUtilityResponse } from 'shared/store/modules/utilities/actions';

const defaultState = [];

export default handleActions(
  {
    [fetchAllUtilitiesResponse]: {
      next: (_state, { payload }) => [...payload.result]
    },

    [fetchUtilityResponse]: {
      next: (state, { payload }) => {
        if (!state.find(id => id === payload.result)) {
          return [...state, payload.result];
        }

        return state;
      }
    },

    [deleteEntities]: {
      next: (state, { payload }) => {
        if (Array.isArray(state)) {
          return state.filter(id => id !== payload.id);
        }

        return state;
      }
    }
  },

  defaultState
);
