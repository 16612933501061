import { combineReducers } from 'redux';

import allIds from './allIds';
import byId from './byId';
import status from './status';

export const reducer = combineReducers({
  allIds,
  byId,
  status
});
