import merge from 'lodash/merge';
import { handleActions } from 'redux-actions';

import { UserStatus } from 'shared/models';
import { addEntities } from 'shared/store/modules/entities';
import {
  assignRoleResponse,
  updateEmailResponse,
  updateResponse,
  disableResponse,
  unlockUserResponse
} from 'shared/store/modules/users/actions';

const defaultState = {};

export default handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => {
        return merge({}, state, payload.entities.users);
      }
    },

    [assignRoleResponse]: {
      next: (state, { payload }) => ({
        ...state,
        [payload.id]: {
          ...state[payload.id],
          role: payload.role
        }
      })
    },

    [updateEmailResponse]: {
      next: (state, { payload }) => ({
        ...state,
        [payload.id]: {
          ...state[payload.id],
          email: payload.email,
          status: payload.status
        }
      })
    },

    [updateResponse]: {
      next: (state, { payload }) => ({
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload
        }
      })
    },

    [unlockUserResponse]: {
      next: (state, { payload }) => ({
        ...state,
        [payload.id]: {
          ...state[payload.id],
          status: UserStatus.Active
        }
      })
    },

    [disableResponse]: {
      next: (state, { payload }) => {
        const newState = { ...state };

        delete newState[payload.id];

        return newState;
      }
    }
  },

  defaultState
);
