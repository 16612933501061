import { createAction } from 'redux-actions';

const MODULE = 'app/admin/readings';

const CREATE_READING_REQUEST = `${MODULE}/CREATE_READING_REQUEST`;
const CREATE_READING_RESPONSE = `${MODULE}/CREATE_READING_RESPONSE`;
const DELETE_READING_REQUEST = `${MODULE}/DELETE_READING_REQUEST`;
const DELETE_READING_RESPONSE = `${MODULE}/DELETE_READING_RESPONSE`;
const FETCH_READING_REQUEST = `${MODULE}/FETCH_READING_REQUEST`;
const FETCH_READING_RESPONSE = `${MODULE}/FETCH_READING_RESPONSE`;
const UPDATE_READING_REQUEST = `${MODULE}/UPDATE_READING_REQUEST`;
const UPDATE_READING_RESPONSE = `${MODULE}/UPDATE_READING_RESPONSE`;

export const createReadingRequest = createAction(CREATE_READING_REQUEST);
export const createReadingResponse = createAction(CREATE_READING_RESPONSE);
export const deleteReadingRequest = createAction(DELETE_READING_REQUEST);
export const deleteReadingResponse = createAction(DELETE_READING_RESPONSE);
export const fetchReadingRequest = createAction(FETCH_READING_REQUEST);
export const fetchReadingResponse = createAction(FETCH_READING_RESPONSE);
export const updateReadingRequest = createAction(UPDATE_READING_REQUEST);
export const updateReadingResponse = createAction(UPDATE_READING_RESPONSE);
