import { handleActions } from 'redux-actions';

import { fetchAllDisclosuresRequest, fetchAllDisclosuresResponse } from './actions';

const defaultState = [];

export const reducer = handleActions(
  {
    [fetchAllDisclosuresRequest]: () => defaultState,
    [fetchAllDisclosuresResponse]: (_, { payload }) => payload
  },

  defaultState
);
