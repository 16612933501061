export class SubscriptionService {
  http;

  constructor(http) {
    this.http = http;
  }

  async fetchOne(subscriptionId) {
    return this.http.get(`/customers/subscriptions/${subscriptionId}`);
  }

  async create(customerId, subscription) {
    return this.http.post('/customers/subscriptions', {
      customerId,
      ...subscription
    });
  }

  async update(id, subscription) {
    return this.http.put('/customers/subscriptions', {
      id,
      ...subscription
    });
  }

  async delete(id) {
    return this.http.delete(`/customers/subscriptions/${id}`);
  }
}
