import { handleActions } from 'redux-actions';

type ProviderState = {
  name?: string;
};

const defaultState: ProviderState = {};

export const reducer = handleActions(
  {},

  defaultState
);
