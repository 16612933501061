import { handleActions } from 'redux-actions';

import { importRequest, importResponse } from 'shared/store/modules/imports/actions';

const defaultState = false;

export default handleActions(
  {
    [importRequest]: {
      next: () => true,
      throw: () => false
    },

    [importResponse]: () => false
  },

  defaultState
);
