import { css } from '@emotion/react';
import sctheme from 'app/styles/sctheme';

const styles = sctheme => css`
  @media (min-width: 721px) {
    height: 100vh;
  }

  .navigation {
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    width: 250px;
    background-color: ${sctheme.white};
    overflow-y: auto;
    z-index: 1002;
    position: fixed;
    top: 0;
    transform: translateX(-250px);
    transition: transform 0.35s ease-in-out, box-shadow 0.35s ease-in-out;

    &.is-open {
      transform: translateX(0);
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2),
        0 18px 46px 6px rgba(16, 22, 26, 0.2);
    }

    @media (min-width: 1200px) {
      flex-basis: 250px;
      position: relative;
      transform: translateX(0);
      transition: none;
      border-top-right-radius: 5px;
      margin-top: -31px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

      &.is-open {
        transform: none;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .navigation-primary {
    padding-bottom: 20px;
  }

  .navigation-secondary {
    padding-top: 20px;
    border-top: 1px solid ${sctheme.grays.$200};
  }

  .navigation .nav-link {
    color: ${sctheme.grays.$800};
    border-left: 3px solid transparent;
    padding: 15px;
  }

  .navigation .nav-link:hover {
    border-left-color: ${sctheme.blues.secondary};
    color: ${sctheme.grays.$800};
  }

  .navigation .nav-link.active {
    background-color: ${sctheme.grays.$100};
    border-left-color: ${sctheme.blues.secondary};
    color: ${sctheme.blues.secondary};
  }
`;

export default styles(sctheme);
