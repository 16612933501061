import { handleActions } from 'redux-actions';

import { getAccountResponse } from 'shared/store/modules/account/actions';

const initialState = {};

export const reducer = handleActions(
  {
    [getAccountResponse]: {
      next: (state, action) => {
        return {
          ...state,
          customerId: action.payload
        };
      },

      throw: (state, action) => {
        return {
          ...state,
          message: action.payload.message
        };
      }
    }
  },

  initialState
);
