export enum SubscriptionPaymentType {
  SubscriberBilled,
  UtilityBilled
}

export const SubscriptionPaymentTypeName = new Map<number, string>([
  [SubscriptionPaymentType.SubscriberBilled, 'Subscriber Billed'],
  [SubscriptionPaymentType.UtilityBilled, 'Utility Billed']
]);

export const SubscriptionPaymentTypeSimple = [
  {
    id: SubscriptionPaymentType.SubscriberBilled,
    name: 'Subscriber Billed'
  },
  {
    id: SubscriptionPaymentType.UtilityBilled,
    name: 'Utility Billed'
  }
];
