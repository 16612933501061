import Model from './Model';

class Portfolio extends Model {
  contactFullName = '';
  contactPhone = '';
  externalId = '';
  id = '';
  isDisabled = false;
  managementType = '';
  name = '';
  projects = [];
  transferDestinationId = '';

  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(Portfolio.fromJSON);
    }

    const portfolio = new Portfolio();

    return portfolio.mapValues(portfolio, json);
  }
}

export default Portfolio;
