import { createAction } from 'redux-actions';

const MODULE = 'app/admin/documents';

const DELETE_DOCUMENT_REQUEST = `${MODULE}/DELETE_DOCUMENT_REQUEST`;
const DELETE_DOCUMENT_RESPONSE = `${MODULE}/DELETE_DOCUMENT_RESPONSE`;
const FETCH_DOCUMENTS_REQUEST = `${MODULE}/FETCH_DOCUMENTS_REQUEST`;
const FETCH_DOCUMENTS_RESPONSE = `${MODULE}/FETCH_DOCUMENTS_RESPONSE`;
const FETCH_DOCUMENT_REQUEST = `${MODULE}/FETCH_DOCUMENT_REQUEST`;
const FETCH_DOCUMENT_RESPONSE = `${MODULE}/FETCH_DOCUMENT_RESPONSE`;
const UPLOAD_DOCUMENT_REQUEST = `${MODULE}/UPLOAD_DOCUMENT_REQUEST`;
const UPLOAD_DOCUMENT_RESPONSE = `${MODULE}/UPLOAD_DOCUMENT_RESPONSE`;

export const deleteDocumentRequest = createAction(DELETE_DOCUMENT_REQUEST);
export const deleteDocumentResponse = createAction(DELETE_DOCUMENT_RESPONSE);
export const fetchDocumentRequest = createAction(FETCH_DOCUMENT_REQUEST);
export const fetchDocumentResponse = createAction(FETCH_DOCUMENT_RESPONSE);
export const fetchDocumentsRequest = createAction(FETCH_DOCUMENTS_REQUEST);
export const fetchDocumentsResponse = createAction(FETCH_DOCUMENTS_RESPONSE);
export const uploadDocumentRequest = createAction(UPLOAD_DOCUMENT_REQUEST);
export const uploadDocumentResponse = createAction(UPLOAD_DOCUMENT_RESPONSE);
