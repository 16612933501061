import axios from 'axios';

import { AllocationService } from './services/AllocationService';
import { AutoAllocationService } from './services/AutoAllocationService';
import { BillingService } from './services/BillingService';
import { ConfigService } from './services/ConfigService';
import { ContentService } from './services/ContentService';
import { CustomerService } from './services/CustomerService';
import { DashboardService } from './services/DashboardService';
import { ImportService } from './services/ImportService';
import { LedgerService } from './services/LedgerService';
import { PaymentService } from './services/PaymentService';
import { PlaidService } from './services/PlaidService';
import { PortfolioService } from './services/PortfolioService';
import { PremiseService } from './services/PremiseService';
import { ProjectService } from './services/ProjectService';
import { ProspectService } from './services/ProspectService';
import { RateTypeService } from './services/RateTypeService';
import { ReadingService } from './services/ReadingService';
import { ReferralCodeService } from './services/ReferralCodeService';
import { StripeService } from './services/StripeService';
import { SubscriptionService } from './services/SubscriptionService';
import { UserService } from './services/UserService';
import { UtilityService } from './services/UtilityService';

class Api {
  hostname;
  accessToken;
  refreshToken;

  allocation;
  auth;
  autoallocation;
  billing;
  config;
  content;
  dashboard;
  import;
  ledger;
  payment;
  plaid;
  portfolio;
  premise;
  project;
  prospect;
  rateType;
  reading;
  referralCode;
  stripe;
  subscriber;
  subscription;
  user;
  utility;

  constructor(hostname, stripePublicKey, accessToken, refreshToken) {
    this.hostname = hostname;
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;

    let scheme = process.env.REACT_APP_API_SCHEME || 'https';

    this.http = axios.create({
      baseURL: `${scheme}://${hostname}`
    });

    this.authHttp = axios.create({
      baseURL: `${scheme}://${hostname}`
    });

    this.allocation = new AllocationService(this);
    this.autoallocation = new AutoAllocationService(this);
    this.billing = new BillingService(this);
    this.config = new ConfigService(this);
    this.content = new ContentService(this);
    this.dashboard = new DashboardService(this);
    this.import = new ImportService(this);
    this.ledger = new LedgerService(this);
    this.payment = new PaymentService(this);
    this.plaid = new PlaidService(this);
    this.portfolio = new PortfolioService(this);
    this.premise = new PremiseService(this);
    this.project = new ProjectService(this);
    this.prospect = new ProspectService(this);
    this.rateType = new RateTypeService(this);
    this.reading = new ReadingService(this);
    this.referralCode = new ReferralCodeService(this);
    this.stripe = new StripeService(stripePublicKey);
    this.subscriber = new CustomerService(this);
    this.subscription = new SubscriptionService(this);
    this.user = new UserService(this);
    this.utility = new UtilityService(this);
  }

  get(url = '', config = {}) {
    return this.http.get(url, config);
  }

  post(url = '', data = {}, config = {}) {
    return this.http.post(url, data, config);
  }

  patch(url = '', data = {}, config = {}) {
    return this.http.patch(url, data, config);
  }

  put(url = '', data = {}, config = {}) {
    return this.http.put(url, data, config);
  }

  delete(url = '', data = {}, config = {}) {
    return this.http.delete(url, data, config);
  }

  head(url = '', data = {}, config = {}) {
    return this.http.head(url, data, config);
  }
}

export default Api;
