import { css } from '@emotion/react';
import sctheme from 'app/styles/sctheme';

const styles = sctheme => css`
  width: 100%;
  @media (max-width: 720px) {
    position: relative;
    bottom: 0;
  }
  @media (min-width: 721px) {
    overflow: auto;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: ${sctheme.grays.$100};
    height: 100%;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    padding: 0 0 15px;
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .copyright {
    width: 100%;
    padding: 0 15px;
  }

  .copyright-inner {
    border-top: 2px solid ${sctheme.grays.$300};
    font-size: 0.875rem;
  }

  .badge {
    &.bg-success {
      background-color: ${sctheme.system.success} !important;
    }
    &.bg-warning {
      background-color: ${sctheme.system.warning} !important;
    }
    &.bg-info {
      background-color: ${sctheme.system.info} !important;
    }
    &.bg-danger {
      background-color: ${sctheme.system.error} !important;
    }
  }

  .text-info {
    color: ${sctheme.system.info} !important;
  }

  .text-warning {
    color: ${sctheme.system.warning} !important;
  }

  .text-success {
    color: ${sctheme.system.success} !important;
  }

  .text-danger {
    color: ${sctheme.system.error} !important;
  }
`;

export default styles(sctheme);
