import { schema } from 'normalizr';

export const subscriber = new schema.Entity('subscribers');
export const subscribers = [subscriber];

subscriber.define({
  activities: require('shared/store/modules/activities/schemas').activities,
  deferredCharges: require('shared/store/modules/deferredCharges/schemas').deferredCharges,
  documents: require('shared/store/modules/documents/schemas').documents,
  premises: require('shared/store/modules/premises/schemas').premises,
  projects: require('shared/store/modules/projects/schemas').projects,
  subscriptions: require('shared/store/modules/subscriptions/schemas').subscriptions,
  users: require('shared/store/modules/user/schemas').users,
  utility: require('shared/store/modules/utilities/schemas').utility
});
