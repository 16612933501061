import merge from 'lodash/merge';
import { handleActions } from 'redux-actions';

import { deleteAllocationResponse } from 'shared/store/modules/allocations/actions';
import { addEntities } from 'shared/store/modules/entities';

const defaultState = {};

export default handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => {
        //trim out any leading zeroes
        if (payload.entities.allocations) {
          // eslint-disable-next-line
          Object.keys(payload.entities.allocations).map(a => {
            if (payload.entities.allocations[a]) {
              if (typeof payload.entities.allocations[a].allocation === 'string') {
                payload.entities.allocations[a].allocation = parseFloat(payload.entities.allocations[a].allocation);
              }
            }
          });
        }

        return merge({}, state, payload.entities.allocations);
      }
    },

    [deleteAllocationResponse]: {
      next: (state, { payload }) => {
        const newState = { ...state };

        delete newState[payload.allocationId];

        return newState;
      }
    }
  },

  defaultState
);
