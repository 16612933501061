import React from 'react';

import { useActiveUser } from 'shared/store/modules/user/hooks';
import { useActiveCustomer } from 'shared/store/modules/customers/hooks';

import { Content } from './Content';
import { Header } from './Header';
import { Navigation } from './Navigation';
import { Router } from './Router';
import { SessionTimer } from './SessionTimer';
import { Theme } from './Theme';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import styles from './ApplicationLayout.styles';

export function ApplicationLayout() {
  useActiveUser();
  useActiveCustomer();

  return (
    <Theme>
      <div css={styles}>
        <div className="wrapper">
          <Header />

          <div className="layout">
            <Navigation />

            <Content>
              <Router />
            </Content>
          </div>

          <SessionTimer />
        </div>
      </div>
    </Theme>
  );
}
