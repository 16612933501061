import logo from './logo.png';

export function ShardError() {
  return (
    <div className="d-flex flex-column h-100 w-100 justify-content-center align-items-center">
      <a href="https://www.suncentral.net/">
        <img alt="SunCentral Logo" className="mb-4" src={logo} title="SunCentral" width="230" />
      </a>

      <p className="mx-3 text-center text-white">No application configured at this hostname.</p>
    </div>
  );
}
