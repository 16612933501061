import { createAction } from 'redux-actions';

const MODULE = 'app/admin/premises';

const CREATE_PREMISE_REQUEST = `${MODULE}/CREATE_PREMISE_REQUEST`;
const CREATE_PREMISE_RESPONSE = `${MODULE}/CREATE_PREMISE_RESPONSE`;
const CREATE_PREMISE_SCHEDULE_REQUEST = `${MODULE}/CREATE_PREMISE_SCHEDULE_REQUEST`;
const CREATE_PREMISE_SCHEDULE_RESPONSE = `${MODULE}/CREATE_PREMISE_SCHEDULE_RESPONSE`;
const CUSTOMER_PREMISE_EXPORT_REQUEST = `${MODULE}/CUSTOMER_PREMISE_EXPORT_REQUEST`;
const CUSTOMER_PREMISE_EXPORT_RESPONSE = `${MODULE}/CUSTOMER_PREMISE_EXPORT_RESPONSE`;
const CUSTOMER_PREMISE_FETCH_REQUEST = `${MODULE}/CUSTOMER_PREMISE_FETCH_REQUEST`;
const CUSTOMER_PREMISE_FETCH_RESPONSE = `${MODULE}/CUSTOMER_PREMISE_FETCH_RESPONSE`;
const DELETE_PREMISE_REQUEST = `${MODULE}/DELETE_PREMISE_REQUEST`;
const DELETE_PREMISE_RESPONSE = `${MODULE}/DELETE_PREMISE_RESPONSE`;
const DELETE_PREMISE_SCHEDULE_REQUEST = `${MODULE}/DELETE_PREMISE_SCHEDULE_REQUEST`;
const DELETE_PREMISE_SCHEDULE_RESPONSE = `${MODULE}/DELETE_PREMISE_SCHEDULE_RESPONSE`;
const FETCH_PREMISE_DETAILS_REQUEST = `${MODULE}/FETCH_PREMISE_DETAILS_REQUEST`;
const FETCH_PREMISE_DETAILS_RESPONSE = `${MODULE}/FETCH_PREMISE_DETAILS_RESPONSE`;
const FETCH_PREMISE_REQUEST = `${MODULE}/FETCH_PREMISE_REQUEST`;
const FETCH_PREMISE_RESPONSE = `${MODULE}/FETCH_PREMISE_RESPONSE`;
const FETCH_PREMISE_SCHEDULES_REQUEST = `${MODULE}/FETCH_PREMISE_SCHEDULES_REQUEST`;
const FETCH_PREMISE_SCHEDULES_RESPONSE = `${MODULE}/FETCH_PREMISE_SCHEDULES_RESPONSE`;
const FETCH_PREMISE_SCHEDULE_REQUEST = `${MODULE}/FETCH_PREMISE_SCHEDULE_REQUEST`;
const FETCH_PREMISE_SCHEDULE_RESPONSE = `${MODULE}/FETCH_PREMISE_SCHEDULE_RESPONSE`;
const UPDATE_PREMISE_REQUEST = `${MODULE}/UPDATE_PREMISE_REQUEST`;
const UPDATE_PREMISE_RESPONSE = `${MODULE}/UPDATE_PREMISE_RESPONSE`;
const UPDATE_PREMISE_SCHEDULE_REQUEST = `${MODULE}/UPDATE_PREMISE_SCHEDULE_REQUEST`;
const UPDATE_PREMISE_SCHEDULE_RESPONSE = `${MODULE}/UPDATE_PREMISE_SCHEDULE_RESPONSE`;

export const createPremiseRequest = createAction(CREATE_PREMISE_REQUEST);
export const createPremiseResponse = createAction(CREATE_PREMISE_RESPONSE);
export const createPremiseScheduleRequest = createAction(CREATE_PREMISE_SCHEDULE_REQUEST);
export const createPremiseScheduleResponse = createAction(CREATE_PREMISE_SCHEDULE_RESPONSE);
export const customerPremiseExportRequest = createAction(CUSTOMER_PREMISE_EXPORT_REQUEST);
export const customerPremiseExportResponse = createAction(CUSTOMER_PREMISE_EXPORT_RESPONSE);
export const customerPremiseFetchRequest = createAction(CUSTOMER_PREMISE_FETCH_REQUEST);
export const customerPremiseFetchResponse = createAction(CUSTOMER_PREMISE_FETCH_RESPONSE);
export const deletePremiseRequest = createAction(DELETE_PREMISE_REQUEST);
export const deletePremiseResponse = createAction(DELETE_PREMISE_RESPONSE);
export const deletePremiseScheduleRequest = createAction(DELETE_PREMISE_SCHEDULE_REQUEST);
export const deletePremiseScheduleResponse = createAction(DELETE_PREMISE_SCHEDULE_RESPONSE);
export const fetchPremiseDetailsRequest = createAction(FETCH_PREMISE_DETAILS_REQUEST);
export const fetchPremiseDetailsResponse = createAction(FETCH_PREMISE_DETAILS_RESPONSE);
export const fetchPremiseRequest = createAction(FETCH_PREMISE_REQUEST);
export const fetchPremiseResponse = createAction(FETCH_PREMISE_RESPONSE);
export const fetchPremiseScheduleRequest = createAction(FETCH_PREMISE_SCHEDULE_REQUEST);
export const fetchPremiseScheduleResponse = createAction(FETCH_PREMISE_SCHEDULE_RESPONSE);
export const fetchPremiseSchedulesRequest = createAction(FETCH_PREMISE_SCHEDULES_REQUEST);
export const fetchPremiseSchedulesResponse = createAction(FETCH_PREMISE_SCHEDULES_RESPONSE);
export const updatePremiseRequest = createAction(UPDATE_PREMISE_REQUEST);
export const updatePremiseResponse = createAction(UPDATE_PREMISE_RESPONSE);
export const updatePremiseScheduleRequest = createAction(UPDATE_PREMISE_SCHEDULE_REQUEST);
export const updatePremiseScheduleResponse = createAction(UPDATE_PREMISE_SCHEDULE_RESPONSE);
