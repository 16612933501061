export class PremiseService {
  http;

  constructor(http) {
    this.http = http;
  }

  async fetchDetails(id) {
    return this.http.get(`/premises/${id}/details`);
  }

  async fetchPremiseSchedules(premiseId) {
    return this.http.get(`/premises/${premiseId}/rate-schedules`);
  }

  async fetchPremiseSchedule(scheduleId) {
    return this.http.get(`/premises/rate-schedules/${scheduleId}`);
  }

  async updatePremiseSchedule(id, premiseId, schedule) {
    return this.http.put('/premises/rate-schedules', {
      id,
      premiseId,
      ...schedule
    });
  }

  async createPremiseSchedule(premiseId, schedule) {
    return this.http.post('/premises/rate-schedules', {
      premiseId,
      ...schedule
    });
  }

  async fetchOne(id) {
    return this.http.get(`/premises/${id}`);
  }

  async create(customerId, premise) {
    return this.http.post('/premises', { customerId, ...premise });
  }

  async delete(premiseId) {
    return this.http.delete(`/premises/${premiseId}`);
  }

  async update(id, premise) {
    return this.http.put('/premises', { id, ...premise });
  }

  async deletePremiseSchedule(Id) {
    return this.http.delete(`/premises/rate-schedules/${Id}`);
  }
}
