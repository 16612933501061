import { toast } from 'react-toastify';

const toaster = {
  success(msg) {
    return toast.success(msg || 'Success');
  },

  error(err, msg = 'An error has occurred') {
    if (err && err.response && err.response.data) {
      if (err.response.data.errorMessage) {
        msg = err.response.data.errorMessage;
      }
      if (err.response.data.message) {
        msg = err.response.data.message;
      }
    }

    return toast.error(msg);
  },

  warn(err, options) {
    return toast.warn(err, options);
  },

  dismiss(toastId) {
    toast.dismiss(toastId.current);
  }
};

export default toaster;
