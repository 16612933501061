import { LOCATION_CHANGE } from 'connected-react-router';
import { handleActions } from 'redux-actions';

import { validateRequest, validateResponse, importRequest, importResponse } from 'shared/store/modules/imports/actions';

const defaultState = null;

export default handleActions(
  {
    [LOCATION_CHANGE]: () => defaultState,

    [validateRequest]: () => defaultState,
    [validateResponse]: {
      next: () => defaultState,
      throw: (_state, { payload }) => payload
    },

    [importRequest]: () => defaultState,
    [importResponse]: {
      next: () => defaultState,
      throw: (_state, { payload }) => payload
    }
  },

  defaultState
);
