export class LedgerService {
  http;

  constructor(http) {
    this.http = http;
  }

  async create(customerId, ledger) {
    return this.http.post('/ledger', {
      customerId,
      ...ledger
    });
  }

  async createForPortfolio(portfolioId, ledger) {
    return this.http.post('/ledger/portfolio', {
      portfolioId,
      ...ledger
    });
  }
}
