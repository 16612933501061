import { handleActions } from 'redux-actions';

import { validateRequest, validateResponse } from 'shared/store/modules/imports/actions';

const defaultState = false;

export default handleActions(
  {
    [validateRequest]: {
      next: () => true,
      throw: () => false
    },

    [validateResponse]: () => false
  },

  defaultState
);
