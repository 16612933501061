const sctheme = {
  blues: {
    primary: '#013883',
    primaryLight: '#E2E8f0',
    secondary: '#0072b7',
    tertiary: '#27afcf'
  },
  system: {
    error: '#d0342c',
    errorLight: '#faebea',
    warning: '#ff7900',
    warningLight: '#fff2e5',
    success: '#42a262',
    successLight: '#ecf6ef',
    info: '#0072b7',
    infoLight: '#f5f9fc'
  },
  grays: {
    $100: '#f2f5f8',
    $200: '#e5eaee',
    $300: '#cad0d6',
    $400: '#adb3b9',
    $500: '#99a1a8',
    $600: '#6d7780',
    $700: '#576068',
    $800: '#40474E',
    $900: '#343A40'
  },
  map: {
    red: '#CF0020',
    orange: '#F4A431',
    mediumBlue: '#4A93E0',
    green: '#1DBD48',
    purple: '#BB2ADD',
    gray: '#9B9B9B',
    brightBlue: '#2FEBF0',
    yellow: '#F9E435',
    pink: '#Ef32DC'
  },
  red: '#cf0020',
  white: '#feffff',
  black: '#333333',
  lightGray: '#fafafa'
};

export default sctheme;
