import { createContext, PropsWithChildren } from 'react';
import Api from 'shared/api';

type ApiProviderProps = PropsWithChildren<{
  api: Api;
}>;

export const ApiContext = createContext<Api | undefined>(undefined);

export function ApiProvider(props: ApiProviderProps) {
  const { api, children } = props;

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}
