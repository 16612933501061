import Model from './Model';

class Activity extends Model {
  description = '';
  amount = '';
  effectiveDate = new Date();
  type = '';
  invoiceIds = [];
  manualChargeLedgerIds = [];

  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(Activity.fromJSON);
    }

    const activity = new Activity();

    return activity.mapValues(activity, json);
  }
}

export default Activity;
