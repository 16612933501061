import {
  changePasswordResponse,
  forgotPasswordRequest,
  forgotPasswordResponse,
  getAccountRequest,
  getAccountResponse,
  resendInviteRequest,
  resendInviteResponse,
  resetPasswordRequest,
  resetPasswordResponse
} from 'shared/store/modules/account/actions';
import toast from 'shared/utilities/toast';

export const getAccount = () => async (dispatch, _getState, { api }) => {
  dispatch(getAccountRequest());

  try {
    const customerId = localStorage.getItem('customerId');
    return dispatch(getAccountResponse(customerId));
  } catch (err) {
    return dispatch(getAccountResponse(err));
  }
};

export const forgotPassword = username => async (dispatch, _getState, { api }) => {
  dispatch(forgotPasswordRequest());

  try {
    await api.user.forgotPassword(username);
    toast.success('Check email for reset password link');
    return dispatch(forgotPasswordResponse());
  } catch (err) {
    return dispatch(forgotPasswordResponse(err));
  }
};

export const resetPassword = (username, newPassword, token) => async (dispatch, _getState, { api }) => {
  dispatch(resetPasswordRequest());

  try {
    await api.user.resetPassword({
      username,
      newPassword,
      token,
      shouldReset: null
    });

    toast.success('Password reset');
    return dispatch(resetPasswordResponse());
  } catch (err) {
    toast.error(err);
    return dispatch(resetPasswordResponse(err));
  }
};

export const changePasswordRequest = () => async (dispatch, _getState, { api }) => {
  try {
    await api.user.changePasswordRequest();
    toast.success('Reset password email sent');
    return dispatch(changePasswordResponse());
  } catch (err) {
    toast.error(err);
    return dispatch(changePasswordResponse(err));
  }
};

export const resendInvite = userId => async (dispatch, _getState, { api }) => {
  dispatch(resendInviteRequest());

  try {
    await api.user.resendInvite(userId);
    toast.success('Invitation sent');
    return dispatch(resendInviteResponse());
  } catch (err) {
    toast.error(err);
    return dispatch(resendInviteResponse(err));
  }
};
