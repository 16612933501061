import { createAction } from 'redux-actions';

const MODULE = 'app/prospects';

const CHECK_INQUIRY_CAPACITY_REQUEST = `${MODULE}/CHECK_INQUIRY_CAPACITY_REQUEST`;
const CHECK_INQUIRY_CAPACITY_RESPONSE = `${MODULE}/CHECK_INQUIRY_CAPACITY_RESPONSE`;
const CHECK_REFERRAL_CODE_REQUEST = `${MODULE}/CHECK_REFERRAL_CODE_REQUEST`;
const CHECK_REFERRAL_CODE_RESPONSE = `${MODULE}/CHECK_REFERRAL_CODE_RESPONSE`;
const CREATE_INQUIRY_REQUEST = `${MODULE}/CREATE_INQUIRY_REQUEST`;
const CREATE_INQUIRY_RESPONSE = `${MODULE}/CREATE_INQUIRY_RESPONSE`;
const CREATE_WITH_PREMISE = `${MODULE}/CREATE_WITH_PREMISE`;
const CREDIT_CHECK_REQUEST = `${MODULE}/CREDIT_CHECK_REQUEST`;
const CREDIT_CHECK_RESPONSE = `${MODULE}/CREDIT_CHECK_RESPONSE`;
const ERROR = `${MODULE}/ERROR`;
const FETCH_CODE_DETAILS_REQUEST = `${MODULE}/FETCH_CODE_DETAILS_REQUEST`;
const FETCH_CODE_DETAILS_RESPONSE = `${MODULE}/FETCH_CODE_DETAILS_RESPONSE`;
const FETCH_CLAIM_TICKET_REQUEST = `${MODULE}/FETCH_CLAIM_TICKET_REQUEST`;
const FETCH_CLAIM_TICKET_RESPONSE = `${MODULE}/FETCH_CLAIM_TICKET_RESPONSE`;
const FETCH_CONTRACT_DETAILS_REQUEST = `${MODULE}/FETCH_CONTRACT_DETAILS_REQUEST`;
const FETCH_CONTRACT_DETAILS_RESPONSE = `${MODULE}/FETCH_CONTRACT_DETAILS_RESPONSE`;
const FETCH_CONTRACT_DETAILS_WITH_PROSPECTID_REQUEST = `${MODULE}/FETCH_CONTRACT_DETAILS_WITH_PROSPECTID_REQUEST`;
const FETCH_CONTRACT_DETAILS_WITH_PROSPECTID_RESPONSE = `${MODULE}/FETCH_CONTRACT_DETAILS_WITH_PROSPECTID_RESPONSE`;
const FETCH_CONTRACT_REQUEST = `${MODULE}/FETCH_CONTRACT_REQUEST`;
const FETCH_CONTRACT_RESPONSE = `${MODULE}/FETCH_CONTRACT_RESPONSE`;
const FETCH_CREDIT_CHECK_ACTION_REQUEST = `${MODULE}/FETCH_CREDIT_CHECK_ACTION_REQUEST`;
const FETCH_CREDIT_CHECK_ACTION_RESPONSE = `${MODULE}/FETCH_CREDIT_CHECK_ACTION_RESPONSE`;
const FETCH_INQUIRY_INFO_REQUEST = `${MODULE}/FETCH_INQUIRY_INFO_REQUEST`;
const FETCH_INQUIRY_INFO_RESPONSE = `${MODULE}/FETCH_INQUIRY_INFO_RESPONSE`;
const FETCH_PROSPECT_DOCUMENTS_REQUEST = `${MODULE}/FETCH_PROSPECT_DOCUMENTS_REQUEST`;
const FETCH_PROSPECT_DOCUMENTS_RESPONSE = `${MODULE}/FETCH_PROSPECT_DOCUMENTS_RESPONSE`;
const FETCH_PROSPECT_REQUEST = `${MODULE}/FETCH_PROSPECT_REQUEST`;
const FETCH_PROSPECT_RESPONSE = `${MODULE}/FETCH_PROSPECT_RESPONSE`;
const FETCH_RATE_TYPES = `${MODULE}/FETCH_RATE_TYPES`;
const FETCH_REQUEST = `${MODULE}/FETCH_REQUEST`;
const FETCH_SAVINGS_REQUEST = `${MODULE}/FETCH_SAVINGS_REQUEST`;
const FETCH_SAVINGS_RESPONSE = `${MODULE}/FETCH_SAVINGS_RESPONSE`;
const FETCH_UTILITY_METERS = `${MODULE}/FETCH_UTILITY_METERS`;
const GET_LEGAL_TEMPLATE = `${MODULE}/GET_LEGAL_TEMPLATE`;
const GET_OFFERS = `${MODULE}/GET_OFFERS`;
const GET_UTIL_AUTH_URL_RESPONSE = `${MODULE}/GET_UTIL_AUTH_URL_RESPONSE`;
const INVALIDATE_CONTRACT_REQUEST = `${MODULE}/INVALIDATE_CONTRACT_REQUEST`;
const LOGIN_REQUEST = `${MODULE}/LOGIN_REQUEST`;
const LOGIN_RESPONSE = `${MODULE}/LOGIN_RESPONSE`;
const PATCH_INQUIRY_REQUEST = `${MODULE}/PATCH_INQUIRY_REQUEST`;
const PATCH_INQUIRY_RESPONSE = `${MODULE}/PATCH_INQUIRY_RESPONSE`;
const PATCH_PROSPECT_REQUEST = `${MODULE}/PATCH_PROSPECT_REQUEST`;
const PATCH_PROSPECT_RESPONSE = `${MODULE}/PATCH_PROSPECT_RESPONSE`;
const RESEND_INVITE_REQUEST = `${MODULE}/RESEND_INVITE_REQUEST`;
const RESEND_INVITE_RESPONSE = `${MODULE}/RESEND_INVITE_RESPONSE`;
const RESET_ZIP_CODE_REQUEST = `${MODULE}/RESET_ZIP_CODE_REQUEST`;
const SELECT_METERS_FOR_ACTIVATION = `${MODULE}/SELECT_METERS_FOR_ACTIVATION`;
const SELECT_OFFER = `${MODULE}/SELECT_OFFER`;
const SET_PASSWORD_REQUEST = `${MODULE}/SET_PASSWORD_REQUEST`;
const SET_PASSWORD_RESPONSE = `${MODULE}/SET_PASSWORD_RESPONSE`;
const SET_UTIL_AUTH_REQUEST = `${MODULE}/SET_UTIL_AUTH_REQUEST`;
const SET_UTIL_AUTH_RESPONSE = `${MODULE}/SET_UTIL_AUTH_RESPONSE`;
const SIGN = `${MODULE}/SIGN`;
const SKIP_UTILITY_LOOKUP = `${MODULE}/SKIP_UTILITY_LOOKUP`;
const UPLOAD_PROSPECT_DOCUMENT_REQUEST = `${MODULE}/UPLOAD_PROSPECT_DOCUMENT_REQUEST`;
const UPLOAD_PROSPECT_DOCUMENT_RESPONSE = `${MODULE}/UPLOAD_PROSPECT_DOCUMENT_RESPONSE`;
const USE_UTILITY_LOOKUP = `${MODULE}/USE_UTILITY_LOOKUP`;
const VALIDATE_CONTRACT_REQUEST = `${MODULE}/VALIDATE_CONTRACT_REQUEST`;
const VALIDATE_CONTRACT_RESPONSE = `${MODULE}/VALIDATE_CONTRACT_RESPONSE`;
const VALIDATE_TOKEN_REQUEST = `${MODULE}/VALIDATE_TOKEN_REQUEST`;
const VALIDATE_TOKEN_RESPONSE = `${MODULE}/VALIDATE_TOKEN_RESPONSE`;
const SIGNUP_REQUEST = `${MODULE}/SIGNUP_REQUEST`;
const SIGNUP_RESPONSE = `${MODULE}/SIGNUP_RESPONSE`;

export const checkInquiryCapacityRequest = createAction(CHECK_INQUIRY_CAPACITY_REQUEST);
export const checkInquiryCapacityResponse = createAction(CHECK_INQUIRY_CAPACITY_RESPONSE);
export const checkReferralCodeRequest = createAction(CHECK_REFERRAL_CODE_REQUEST);
export const checkReferralCodeResponse = createAction(CHECK_REFERRAL_CODE_RESPONSE);
export const createInquiryRequest = createAction(CREATE_INQUIRY_REQUEST);
export const createInquiryResponse = createAction(CREATE_INQUIRY_RESPONSE);
export const createProspectWithPremise = createAction(CREATE_WITH_PREMISE);
export const creditCheckRequest = createAction(CREDIT_CHECK_REQUEST);
export const creditCheckResponse = createAction(CREDIT_CHECK_RESPONSE);
export const error = createAction(ERROR);
export const fetchCodeDetailsRequest = createAction(FETCH_CODE_DETAILS_REQUEST);
export const fetchCodeDetailsResponse = createAction(FETCH_CODE_DETAILS_RESPONSE);
export const fetchClaimTicketRequest = createAction(FETCH_CLAIM_TICKET_REQUEST);
export const fetchClaimTicketResponse = createAction(FETCH_CLAIM_TICKET_RESPONSE);
export const fetchContractDetailsRequest = createAction(FETCH_CONTRACT_DETAILS_REQUEST);
export const fetchContractDetailsResponse = createAction(FETCH_CONTRACT_DETAILS_RESPONSE);
export const fetchContractDetailsWithProspectIdRequest = createAction(FETCH_CONTRACT_DETAILS_WITH_PROSPECTID_REQUEST);
export const fetchContractDetailsWithProspectIdResponse = createAction(FETCH_CONTRACT_DETAILS_WITH_PROSPECTID_RESPONSE);
export const fetchContractRequest = createAction(FETCH_CONTRACT_REQUEST);
export const fetchContractResponse = createAction(FETCH_CONTRACT_RESPONSE);
export const fetchCreditCheckActionRequest = createAction(FETCH_CREDIT_CHECK_ACTION_REQUEST);
export const fetchCreditCheckActionResponse = createAction(FETCH_CREDIT_CHECK_ACTION_RESPONSE);
export const fetchInquiryInfoRequest = createAction(FETCH_INQUIRY_INFO_REQUEST);
export const fetchInquiryInfoResponse = createAction(FETCH_INQUIRY_INFO_RESPONSE);
export const fetchProspectDocumentsRequest = createAction(FETCH_PROSPECT_DOCUMENTS_REQUEST);
export const fetchProspectDocumentsResponse = createAction(FETCH_PROSPECT_DOCUMENTS_RESPONSE);
export const fetchProspectRequest = createAction(FETCH_PROSPECT_REQUEST);
export const fetchProspectResponse = createAction(FETCH_PROSPECT_RESPONSE);
export const fetchRequest = createAction(FETCH_REQUEST);
export const fetchSavingsRequest = createAction(FETCH_SAVINGS_REQUEST);
export const fetchSavingsResponse = createAction(FETCH_SAVINGS_RESPONSE);
export const fetchUtilityMeters = createAction(FETCH_UTILITY_METERS);
export const getLegalTemplate = createAction(GET_LEGAL_TEMPLATE);
export const getOffers = createAction(GET_OFFERS);
export const getRateTypes = createAction(FETCH_RATE_TYPES);
export const getUtilAuthUrlResponse = createAction(GET_UTIL_AUTH_URL_RESPONSE);
export const invalidateContractRequest = createAction(INVALIDATE_CONTRACT_REQUEST);
export const loginRequest = createAction(LOGIN_REQUEST);
export const loginResponse = createAction(LOGIN_RESPONSE);
export const patchInquiryRequest = createAction(PATCH_INQUIRY_REQUEST);
export const patchInquiryResponse = createAction(PATCH_INQUIRY_RESPONSE);
export const patchProspectRequest = createAction(PATCH_PROSPECT_REQUEST);
export const patchProspectResponse = createAction(PATCH_PROSPECT_RESPONSE);
export const resendInviteRequest = createAction(RESEND_INVITE_REQUEST);
export const resendInviteResponse = createAction(RESEND_INVITE_RESPONSE);
export const resetZipCodeRequest = createAction(RESET_ZIP_CODE_REQUEST);
export const selectMetersForActivation = createAction(SELECT_METERS_FOR_ACTIVATION);
export const selectOffer = createAction(SELECT_OFFER);
export const setPasswordRequest = createAction(SET_PASSWORD_REQUEST);
export const setPasswordResponse = createAction(SET_PASSWORD_RESPONSE);
export const setUtilAuthRequest = createAction(SET_UTIL_AUTH_REQUEST);
export const setUtilAuthResponse = createAction(SET_UTIL_AUTH_RESPONSE);
export const sign = createAction(SIGN);
export const skipUtilityLookup = createAction(SKIP_UTILITY_LOOKUP);
export const uploadProspectDocumentRequest = createAction(UPLOAD_PROSPECT_DOCUMENT_REQUEST);
export const uploadProspectDocumentResponse = createAction(UPLOAD_PROSPECT_DOCUMENT_RESPONSE);
export const useUtilityLookup = createAction(USE_UTILITY_LOOKUP);
export const validateContractRequest = createAction(VALIDATE_CONTRACT_REQUEST);
export const validateContractResponse = createAction(VALIDATE_CONTRACT_RESPONSE);
export const validateTokenRequest = createAction(VALIDATE_TOKEN_REQUEST);
export const validateTokenResponse = createAction(VALIDATE_TOKEN_RESPONSE);
export const signupRequest = createAction(SIGNUP_REQUEST);
export const signupResponse = createAction(SIGNUP_RESPONSE);
