import { createAction } from 'redux-actions';

const MODULE = 'app/admin/bulkedit';

const FETCH_ERROR_REPORT_REQUEST = `${MODULE}/FETCH_ERROR_REPORT_REQUEST`;
const FETCH_ERROR_REPORT_RESPONSE = `${MODULE}/FETCH_ERROR_REPORT_RESPONSE`;
const FETCH_REPORT_REQUEST = `${MODULE}/FETCH_REPORT_REQUEST`;
const FETCH_REPORT_RESPONSE = `${MODULE}/FETCH_REPORT_RESPONSE`;
const FETCH_EXISTING_REQUEST = `${MODULE}/FETCH_EXISTING_REQUEST`;
const FETCH_EXISTINGE_RESPONSE = `${MODULE}/FETCH_EXISTING_RESPONSE`;
const IMPORT_REQUEST = `${MODULE}/IMPORT_REQUEST`;
const IMPORT_RESPONSE = `${MODULE}/IMPORT_RESPONSE`;
const VALIDATE_REQUEST = `${MODULE}/VALIDATE_REQUEST`;
const VALIDATE_RESPONSE = `${MODULE}/VALIDATE_RESPONSE`;

export const fetchErrorReportRequest = createAction(FETCH_ERROR_REPORT_REQUEST);
export const fetchErrorReportResponse = createAction(FETCH_ERROR_REPORT_RESPONSE);
export const fetchReportRequest = createAction(FETCH_REPORT_REQUEST);
export const fetchReportResponse = createAction(FETCH_REPORT_RESPONSE);
export const fetchExistingRequest = createAction(FETCH_EXISTING_REQUEST);
export const fetchExistingResponse = createAction(FETCH_EXISTINGE_RESPONSE);
export const importRequest = createAction(IMPORT_REQUEST);
export const importResponse = createAction(IMPORT_RESPONSE);
export const validateRequest = createAction(VALIDATE_REQUEST);
export const validateResponse = createAction(VALIDATE_RESPONSE);
