import { handleActions } from 'redux-actions';
import { fetchAllReferralCodesResponse } from '../actions';

const defaultState = null;

export default handleActions(
  {
    [fetchAllReferralCodesResponse]: {
      next: () => Date.now()
    }
  },

  defaultState
);
