import React from "react";
import { Alert } from "reactstrap";
import { ErrorContainer } from "./AuthLayout.styles";
import { parse } from 'query-string';
import { useLocation } from "react-router-dom";

const AuthLayoutError = () => {
  const { search } = useLocation();
  const { error, error_type } = parse(search, { parseBooleans: true });

  if (!error) return null;

  const messages = {
    invalid_request: "Your invitation appears to have expired, please reach out to customerservice@suncentral.net for help.",
    no_accounts: "You likely tried to enter an email and password when you previously signed in with Google, or vice versa. Please try a different method.",
    access_denied: "You likely tried to enter an email and password when you previously signed in with Google, or vice versa. Please try a different method.",
    [undefined]: "Please try a different method or reach out to customerservice@suncentral.net for help."
  };

  return (
    <>
      <ErrorContainer>
        <Alert style={{ width: '500px' }} color="danger">There was an issue with your login. If you were a subscriber before October 16th, we have updated our login experience. {messages[error_type]}</Alert>
      </ErrorContainer>
    </>
  )
}

export default AuthLayoutError;
