import { handleActions } from 'redux-actions';

import { validateRequest, validateResponse } from 'shared/store/modules/imports/actions';

const defaultState = null;

export default handleActions(
  {
    [validateRequest]: () => null,

    [validateResponse]: {
      next: (_state, { payload }) => {
        if (payload.claimTicket) {
          return payload.claimTicket;
        }
      },

      throw: () => null
    }
  },

  defaultState
);
