import { handleActions } from 'redux-actions';

import {
  autoAllocationExportRequest,
  autoAllocationExportResponse,
  waitlistRequest,
  waitlistResponse
} from 'shared/store/modules/autoAllocations/actions';

const defaultState = {};

export default handleActions(
  {
    [autoAllocationExportRequest]: () => defaultState,
    [autoAllocationExportResponse]: {
      next: (_state, { payload }) => payload
    },

    [waitlistRequest]: {
      next: state => {
        return {};
      }
    },

    [waitlistResponse]: {
      next: (state, { payload }) => {
        return payload;
      }
    }
  },

  defaultState
);
