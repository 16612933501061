import { createAction } from 'redux-actions';

const MODULE = 'app/admin/disclosures';

const FETCH_ALL_DISCLOSURES_REQUEST = `${MODULE}/FETCH_ALL_DISCLOSURES_REQUEST`;
const FETCH_ALL_DISCLOSURES_RESPONSE = `${MODULE}/FETCH_ALL_DISCLOSURES_RESPONSE`;
const FETCH_OFFER_DISCLOSURES_REQUEST = `${MODULE}/FETCH_OFFER_DISCLOSURES_REQUEST`;
const FETCH_OFFER_DISCLOSURES_RESPONSE = `${MODULE}/FETCH_OFFER_DISCLOSURES_RESPONSE`;

export const fetchAllDisclosuresRequest = createAction(FETCH_ALL_DISCLOSURES_REQUEST);
export const fetchAllDisclosuresResponse = createAction(FETCH_ALL_DISCLOSURES_RESPONSE);
export const fetchOfferDisclosuresRequest = createAction(FETCH_OFFER_DISCLOSURES_REQUEST);
export const fetchOfferDisclosuresResponse = createAction(FETCH_OFFER_DISCLOSURES_RESPONSE);
