import { useSelector } from 'react-redux';

import lazy from 'shared/utilities/asyncComponent';
import { UserRole } from 'shared/models';
import { selectRole } from 'shared/store/modules/auth/selectors';

const AdminRouter = lazy(() => import('app/Portal/Admin').then(module => module.default));
const PortfolioManagerRouter = lazy(() => import('app/Portal/PortfolioManager/Routes').then(module => module.default));
const SubscriberRouter = lazy(() => import('app/Portal').then(module => module.default));

export function Router() {
  const role = useSelector(selectRole);

  switch (role) {
    case UserRole.Admin:
      return <AdminRouter />;

    case UserRole['Portfolio Manager']:
      return <PortfolioManagerRouter />;

    default:
      return <SubscriberRouter />;
  }
}
