import { AccountPicker } from './AccountPicker';
import { ForgotPassword } from './ForgotPassword';
import { Login } from './Login';
import { Logout } from './Logout';

const AuthRoutes = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/logout',
    component: Logout
  },
  {
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    path: '/accounts',
    component: AccountPicker
  }
];

export default AuthRoutes;
