import thunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';

import * as schemas from 'shared/store/modules/schemas';
import Api from 'shared/api';
import { logout, renewSessionResponse } from 'shared/store/modules/auth/actions';
import { broadcastMiddleware, googleAnalytics } from './middleware';
import { history, rootReducer } from 'shared/store/reducers';
import { loadState, saveState } from './localStorage';

export function initializeStore(shard) {
  const {
    api,
    content,
    paymentMethods,
    payments,
    provider,
    theme,
    features,
    navigation,
    redirects,
    initialDashboard,
    ...rest
  } = shard;
  const { hostname, stripePublicKey } = shard.api;

  const persistedState = loadState();

  let accessToken;
  let refreshToken;

  if (persistedState && persistedState.auth) {
    const { auth } = persistedState;
    const { access_token, refresh_token } = auth;
    accessToken = access_token;
    refreshToken = refresh_token;
  }

  const client = new Api(hostname, stripePublicKey, accessToken, refreshToken);

  const middleware = [
    thunk.withExtraArgument({ api: client, schemas }),
    routerMiddleware(history),
    googleAnalytics,
    broadcastMiddleware([logout.toString(), renewSessionResponse.toString()])
  ];

  // TODO Move entirety of /entry response into a single store
  const preloadedState = {
    ...persistedState,
    provider: {
      ...provider,
      ...api,
      payments,
      paymentMethods,
      ...rest
    },
    content,
    theme,
    features,
    navigation,
    redirects,
    initialDashboard
  };

  const store = createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(...middleware)));

  client.http.interceptors.request.use(config => {
    const accessToken = store.getState().auth.access_token;

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  }, Promise.reject);

  client.authHttp.interceptors.response.use(null, async error => {
    if (error.config && error.response && error.response.status === 401) {
      return Promise.reject(error);
    }
  });

  // TODO - Should throttle this, but it breaks API auth
  store.subscribe(() => {
    saveState({
      accounts: store.getState().accounts,
      auth: store.getState().auth,
      admin: {
        imports: {
          activeTicket: store.getState().admin.imports.activeTicket,
          byId: store.getState().admin.imports.byId
        }
      }
    });
  });

  return {
    api: client,
    store
  };
}
