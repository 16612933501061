import { schema } from 'normalizr';

export const portfolio = new schema.Entity('portfolios');
export const portfolios = [portfolio];

portfolio.define({
  activities: require('shared/store/modules/activities/schemas').activities,
  projects: require('shared/store/modules/projects/schemas').projects,
  settlementAccount: require('shared/store/modules/settlementAccounts/schemas').settlementAccount,
  users: require('shared/store/modules/user/schemas').users
});
