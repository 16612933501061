import { createAction } from 'redux-actions';

const MODULE = 'app/admin/contracts';

const CREATE_CONTRACT_REQUEST = `${MODULE}/CREATE_CONTRACT_REQUEST`;
const CREATE_CONTRACT_RESPONSE = `${MODULE}/CREATE_CONTRACT_RESPONSE`;
const FETCH_CONTRACTS_REQUEST = `${MODULE}/FETCH_CONTRACTS_REQUEST`;
const FETCH_CONTRACTS_RESPONSE = `${MODULE}/FETCH_CONTRACTS_RESPONSE`;
const FETCH_CONTRACT_REQUEST = `${MODULE}/FETCH_CONTRACT_REQUEST`;
const FETCH_CONTRACT_RESPONSE = `${MODULE}/FETCH_CONTRACT_RESPONSE`;
const UPDATE_CONTRACT_REQUEST = `${MODULE}/UPDATE_CONTRACT_REQUEST`;
const UPDATE_CONTRACT_RESPONSE = `${MODULE}/UPDATE_CONTRACT_RESPONSE`;

export const createContractRequest = createAction(CREATE_CONTRACT_REQUEST);
export const createContractResponse = createAction(CREATE_CONTRACT_RESPONSE);
export const fetchContractRequest = createAction(FETCH_CONTRACT_REQUEST);
export const fetchContractResponse = createAction(FETCH_CONTRACT_RESPONSE);
export const fetchContractsRequest = createAction(FETCH_CONTRACTS_REQUEST);
export const fetchContractsResponse = createAction(FETCH_CONTRACTS_RESPONSE);
export const updateContractRequest = createAction(UPDATE_CONTRACT_REQUEST);
export const updateContractResponse = createAction(UPDATE_CONTRACT_RESPONSE);
