import Model from './Model';

class CreditBand extends Model {
  amount = '';
  fico8ScoreFrom = '';
  fico8ScoreTo = '';

  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(CreditBand.fromJSON);
    }

    const creditBand = new CreditBand();

    return creditBand.mapValues(creditBand, json);
  }
}

export default CreditBand;
