import { combineActions, handleActions } from 'redux-actions';

import { fetchDashboardRequest, fetchDashboardResponse } from 'shared/store/modules/dashboard/actions';
import { fetchDocumentRequest, fetchDocumentResponse } from 'shared/store/modules/documents/actions';
import {

  fetchPortfolioDetailsRequest,
  fetchPortfolioRequest,
  fetchPortfolioDetailsResponse,
  fetchPortfolioResponse
} from 'shared/store/modules/portfolios/actions';
import {
  fetchPremiseDetailsRequest,
  fetchPremiseDetailsResponse,
  fetchPremiseRequest,
  fetchPremiseResponse
} from 'shared/store/modules/premises/actions';
import {
  fetchProjectDetailsRequest,
  fetchProjectDetailsResponse,
  fetchProjectRequest,
  fetchProjectResponse
} from 'shared/store/modules/projects/actions';
import { fetchRateTypeResponse, fetchRateTypeRequest } from 'shared/store/modules/rateTypes/actions';
import { fetchReadingResponse, fetchReadingRequest } from 'shared/store/modules/readings/actions';
import { fetchScheduleResponse, fetchScheduleRequest } from 'shared/store/modules/schedules/actions';
import {
  fetchSubscriberDetailsRequest,
  fetchSubscriberDetailsResponse,
} from 'shared/store/modules/subscribers/actions';
import {
  fetchAllSubscriptionsRequest,
  fetchAllSubscriptionsResponse,
  fetchSubscriptionRequest,
  fetchSubscriptionResponse
} from 'shared/store/modules/subscriptions/actions';
import {
  fetchAdminsRequest,
  fetchAdminsResponse,
} from 'shared/store/modules/users/actions';
import {
  fetchAllUtilitiesRequest,
  fetchAllUtilitiesResponse,
  fetchUtilityDetailsRequest,
  fetchUtilityDetailsResponse,
  fetchUtilityRequest,
  fetchUtilityResponse
} from 'shared/store/modules/utilities/actions';

const defaultState = false;

export const reducer = handleActions(
  {
    [combineActions(
      fetchDashboardRequest,
      fetchDocumentRequest,
      fetchPortfolioDetailsRequest,
      fetchPortfolioRequest,
      fetchPremiseDetailsRequest,
      fetchPremiseRequest,
      fetchProjectDetailsRequest,
      fetchProjectRequest,
      fetchRateTypeRequest,
      fetchReadingRequest,
      fetchScheduleRequest,
      fetchSubscriberDetailsRequest,
      fetchAllSubscriptionsRequest,
      fetchSubscriptionRequest,
      fetchAdminsRequest,
      fetchAllUtilitiesRequest,
      fetchUtilityDetailsRequest,
      fetchUtilityRequest
    )]: () => true,

    [combineActions(
      fetchDashboardResponse,
      fetchDocumentResponse,
      fetchPortfolioDetailsResponse,
      fetchPortfolioResponse,
      fetchPremiseDetailsResponse,
      fetchPremiseResponse,
      fetchProjectDetailsResponse,
      fetchProjectResponse,
      fetchRateTypeResponse,
      fetchReadingResponse,
      fetchScheduleResponse,
      fetchSubscriberDetailsResponse,
      fetchAllSubscriptionsResponse,
      fetchSubscriptionResponse,
      fetchAdminsResponse,
      fetchAllUtilitiesResponse,
      fetchUtilityDetailsResponse,
      fetchUtilityResponse
    )]: () => false
  },

  defaultState
);
