import { createAction } from 'redux-actions';

const MODULE = 'app/admin/rateTypes';

const CREATE_RATE_TYPE_REQUEST = `${MODULE}/CREATE_RATE_TYPE_REQUEST`;
const CREATE_RATE_TYPE_RESPONSE = `${MODULE}/CREATE_RATE_TYPE_RESPONSE`;
const FETCH_RATE_TYPE_REQUEST = `${MODULE}/FETCH_RATE_TYPE_REQUEST`;
const FETCH_RATE_TYPE_RESPONSE = `${MODULE}/FETCH_RATE_TYPE_RESPONSE`;
const UPDATE_RATE_TYPE_REQUEST = `${MODULE}/UPDATE_RATE_TYPE_REQUEST`;
const UPDATE_RATE_TYPE_RESPONSE = `${MODULE}/UPDATE_RATE_TYPE_RESPONSE`;

export const createRateTypeRequest = createAction(CREATE_RATE_TYPE_REQUEST);
export const createRateTypeResponse = createAction(CREATE_RATE_TYPE_RESPONSE);
export const fetchRateTypeRequest = createAction(FETCH_RATE_TYPE_REQUEST);
export const fetchRateTypeResponse = createAction(FETCH_RATE_TYPE_RESPONSE);
export const updateRateTypeRequest = createAction(UPDATE_RATE_TYPE_REQUEST);
export const updateRateTypeResponse = createAction(UPDATE_RATE_TYPE_RESPONSE);
