import invert from 'lodash/invert';

const CustomerAllocationStatus = {
  Unallocated: 0,
  Allocated: 1,
  PreAllocated: 2,
  Expired: 3
};

export default Object.freeze({
  ...CustomerAllocationStatus,
  ...invert(CustomerAllocationStatus)
});
