import React from 'react';
import { Container } from 'reactstrap';

import DocumentLayout from 'shared/components/DocumentLayout';
import { useContent } from 'shared/store/modules/content/hooks';
import { createMarkup } from 'shared/utilities/createMarkup';
import './Terms.css';

const Terms = () => {
  const { data, error, isLoading } = useContent('tos');

  return (
    <DocumentLayout title="Terms of Use">
      <Container id="terms">
        {isLoading ? (
          'Loading...'
        ) : error ? (
          'There was an error loading the content'
        ) : (
          <div dangerouslySetInnerHTML={createMarkup(data.content)} />
        )}
      </Container>
    </DocumentLayout>
  );
};

export default Terms;
