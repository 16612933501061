import { createAction } from 'redux-actions';

const MODULE = 'app/customers';

const CALCULATE_CARD_FEE_REQUEST = `${MODULE}/CALCULATE_CARD_FEE_REQUEST`;
const CALCULATE_CARD_FEE_RESPONSE = `${MODULE}/CALCULATE_CARD_FEE_RESPONSE`;
const CUSTOMER_ADD_PAYMENT_METHOD = `${MODULE}/CUSTOMER_ADD_PAYMENT_METHOD`;
const CUSTOMER_ADD_PAYMENT_METHOD_ERROR = `${MODULE}/CUSTOMER_ADD_PAYMENT_METHOD_ERROR`;
const CUSTOMER_ADD_PAYMENT_METHOD_RESPONSE = `${MODULE}/CUSTOMER_ADD_PAYMENT_METHOD_RESPONSE`;
const CUSTOMER_AUTO_PAYMENTS_STATUS = `${MODULE}/CUSTOMER_AUTO_PAYMENTS_STATUS`;
const CUSTOMER_CANCEL_AUTO_PAY = `${MODULE}/CUSTOMER_CANCEL_AUTO_PAY`;
const CUSTOMER_ENROLL_IN_AUTO_PAY = `${MODULE}/CUSTOMER_ENROLL_IN_AUTO_PAY`;
const CUSTOMER_FETCH_ERROR = `${MODULE}/CUSTOMER_FETCH_ERROR`;
const CUSTOMER_FETCH_PAYMENTS_REQUEST = `${MODULE}/CUSTOMER_FETCH_PAYMENTS_REQUEST`;
const CUSTOMER_FETCH_PAYMENTS_RESPONSE = `${MODULE}/CUSTOMER_FETCH_PAYMENTS_RESPONSE`;
const CUSTOMER_FETCH_REQUEST = `${MODULE}/CUSTOMER_FETCH_REQUEST`;
const CUSTOMER_FETCH_RESPONSE = `${MODULE}/CUSTOMER_FETCH_RESPONSE`;
const CUSTOMER_GET_BALANCE = `${MODULE}/CUSTOMER_GET_BALANCE`;
const CUSTOMER_GET_BILLING = `${MODULE}/CUSTOMER_GET_BILLING`;
const CUSTOMER_GET_PROVISIONAL_SUBSCRIPTION_REQUEST = `${MODULE}/CUSTOMER_GET_PROVISIONAL_SUBSCRIPTION_REQUEST`;
const CUSTOMER_GET_PROVISIONAL_SUBSCRIPTION_RESPONSE = `${MODULE}/CUSTOMER_GET_PROVISIONAL_SUBSCRIPTION_RESPONSE`;
const CUSTOMER_SET_PRIMARY_PAYMENT_REQUEST = `${MODULE}/CUSTOMER_SET_PRIMARY_PAYMENT_REQUEST`;
const CUSTOMER_SET_PRIMARY_PAYMENT_RESPONSE = `${MODULE}/CUSTOMER_SET_PRIMARY_PAYMENT_RESPONSE`;
const CUSTOMER_UPDATE_REQUEST = `${MODULE}/CUSTOMER_UPDATE_REQUEST`;
const CUSTOMER_UPDATE_RESPONSE = `${MODULE}/CUSTOMER_UPDATE_RESPONSE`;
const CUSTOMER_VERIFY_BANK_ACCOUNT = `${MODULE}/CUSTOMER_VERIFY_BANK_ACCOUNT`;
const DELETE_PAYMENT_ERROR = `${MODULE}/DELETE_PAYMENT_ERROR`;
const DELETE_PAYMENT_SUCCESS = `${MODULE}/DELETE_PAYMENT_SUCCESS`;
const FETCH_FEE_SCHEDULE_REQUEST = `${MODULE}/FETCH_FEE_SCHEDULE_REQUEST`;
const FETCH_FEE_SCHEDULE_RESPONSE = `${MODULE}/FETCH_FEE_SCHEDULE_RESPONSE`;
const FETCH_CUSTOMER_ESTIMATED_SAVINGS_REQUEST = `${MODULE}/FETCH_CUSTOMER_ESTIMATED_SAVINGS_REQUEST`;
const FETCH_CUSTOMER_ESTIMATED_SAVINGS_RESPONSE = `${MODULE}/FETCH_CUSTOMER_ESTIMATED_SAVINGS_RESPONSE`;
const CUSTOMER_UPDATE_CREDIT_CARD_REQUEST = `${MODULE}/CUSTOMER_UPDATE_CREDIT_CARD_REQUEST`;
const CUSTOMER_UPDATE_CREDIT_CARD_RESPONSE = `${MODULE}/CUSTOMER_UPDATE_CREDIT_CARD_RESPONSE`;

export const calculateCardFeeRequest = createAction(CALCULATE_CARD_FEE_REQUEST);
export const calculateCardFeeResponse = createAction(CALCULATE_CARD_FEE_RESPONSE);
export const customerAddPaymentMethod = createAction(CUSTOMER_ADD_PAYMENT_METHOD);
export const customerAddPaymentMethodError = createAction(CUSTOMER_ADD_PAYMENT_METHOD_ERROR);
export const customerAddPaymentMethodResponse = createAction(CUSTOMER_ADD_PAYMENT_METHOD_RESPONSE);
export const customerUpdateCreditCardRequest = createAction(CUSTOMER_UPDATE_CREDIT_CARD_REQUEST);
export const customerUpdateCreditCardResponse = createAction(CUSTOMER_UPDATE_CREDIT_CARD_RESPONSE);
export const customerAutoPaymentsStatus = createAction(CUSTOMER_AUTO_PAYMENTS_STATUS);
export const customerCancelAutoPay = createAction(CUSTOMER_CANCEL_AUTO_PAY);
export const customerEnrollInAutoPay = createAction(CUSTOMER_ENROLL_IN_AUTO_PAY);
export const customerFetchError = createAction(CUSTOMER_FETCH_ERROR);
export const customerFetchPaymentsRequest = createAction(CUSTOMER_FETCH_PAYMENTS_REQUEST);
export const customerFetchPaymentsResponse = createAction(CUSTOMER_FETCH_PAYMENTS_RESPONSE);
export const customerFetchRequest = createAction(CUSTOMER_FETCH_REQUEST);
export const customerFetchResponse = createAction(CUSTOMER_FETCH_RESPONSE);
export const customerGetBalance = createAction(CUSTOMER_GET_BALANCE);
export const customerGetBilling = createAction(CUSTOMER_GET_BILLING);
export const customerGetProvisionalSubscriptionRequest = createAction(CUSTOMER_GET_PROVISIONAL_SUBSCRIPTION_REQUEST);
export const customerGetProvisionalSubscriptionResponse = createAction(CUSTOMER_GET_PROVISIONAL_SUBSCRIPTION_RESPONSE);
export const customerSetPrimaryPaymentRequest = createAction(CUSTOMER_SET_PRIMARY_PAYMENT_REQUEST);
export const customerSetPrimaryPaymentResponse = createAction(CUSTOMER_SET_PRIMARY_PAYMENT_RESPONSE);
export const customerUpdateRequest = createAction(CUSTOMER_UPDATE_REQUEST);
export const customerUpdateResponse = createAction(CUSTOMER_UPDATE_RESPONSE);
export const customerVerifyBankAccount = createAction(CUSTOMER_VERIFY_BANK_ACCOUNT);
export const deletePaymentError = createAction(DELETE_PAYMENT_ERROR);
export const deletePaymentSuccess = createAction(DELETE_PAYMENT_SUCCESS);
export const fetchFeeScheduleRequest = createAction(FETCH_FEE_SCHEDULE_REQUEST);
export const fetchFeeScheduleResponse = createAction(FETCH_FEE_SCHEDULE_RESPONSE);
export const fetchCustomerEstimatedSavingsRequest = createAction(FETCH_CUSTOMER_ESTIMATED_SAVINGS_REQUEST);
export const fetchCustomerEstimatedSavingsResponse = createAction(FETCH_CUSTOMER_ESTIMATED_SAVINGS_RESPONSE);
