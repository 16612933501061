export class BillingService {
  http;

  constructor(http) {
    this.http = http;
  }

  // Project Detail Page
  // GET /billing/customer-billing-detail-for-project/{projectId}/lifetime/export
  // GET /billing/customer-billing-detail-for-project/{projectId}/{year}/{month}/export
  async exportCustomerPaymentsForProject(projectId) {
    return this.http.get(`/billing/customer-billing-detail-for-project/${projectId}/lifetime/export`);
  }
  async exportCustomerPaymentsForProjectByMonth(projectId, year, month) {
    return this.http.get(`/billing/customer-billing-detail-for-project/${projectId}/${year}/${month}/export`);
  }

  // Portfolio Detail Page
  // GET /billing/project-billing-detail-for-portfolio/{portfolioId}/{year}/{month}/export <== by project
  // GET /billing/project-billing-detail-for-portfolio/{portfolioId}/lifetime/export       <== by project
  // GET /billing/customer-billing-detail/{year}/{month}/export?filterByPortfolioId=id_here <== by subscriber, need to add optional filterByPortfolioId
  // GET /billing/customer-billing-detail/lifetime/export?filterByPortfolioId=id_here <== by subscriber, need to add optional filterByPortfolioId
  async exportProjectPaymentsForPortfolio(portfolioId) {
    return this.http.get(`/billing/project-billing-detail-for-portfolio/${portfolioId}/lifetime/export`);
  }
  async exportProjectPaymentsForPortfolioByMonth(portfolioId, year, month) {
    return this.http.get(`/billing/project-billing-detail-for-portfolio/${portfolioId}/${year}/${month}/export`);
  }
  async exportCustomerPaymentsForPortfolio(portfolioId) {
    return this.http.get(`/billing/customer-billing-detail/lifetime/export`, {
      params: {
        filterByPortfolioId: portfolioId
      }
    });
  }
  async exportCustomerPaymentsForPortfolioByMonth(portfolioId, year, month) {
    return this.http.get(`/billing/customer-billing-detail/${year}/${month}/export`, {
      params: {
        filterByPortfolioId: portfolioId
      }
    });
  }

  // POST /billing
  async createBilling(model) {
    return this.http.post('/billing', model);
  }

  // POST /billing/preflight
  async billingPreflight(model) {
    return this.http.post('/billing/preflight', model);
  }

  // GET /billing/preflight/{billingId}
  async checkPreflight(billingId) {
    return this.http.get(`/billing/preflight/${billingId}`);
  }

  // GET /billing/preflight/{billingId}/export
  async getPreflightExport(billingId) {
    return this.http.get(`/billing/preflight/${billingId}/export`);
  }

  // POST /billing/generate-invoices
  async generateInvoices(billingId) {
    return this.http.post('/billing/generate-invoices', null, {
      params: { billingId }
    });
  }

  // GET /billing/invoice-generation-status/{billingId}
  async invoiceGenerationStatus(billingId) {
    return this.http.get(`/billing/invoice-generation-status/${billingId}`);
  }

  // GET /billing/invoice-generation-status/{billingId}/export
  async invoiceGenerateStatusExport(billingId) {
    return this.http.get(`/billing/invoice-generation-status/${billingId}/export`);
  }

  // POST /billing/abort
  async abortBilling(billingId) {
    return this.http.post('/billing/abort', null, { params: { billingId } });
  }

  // POST /billing/create-remaining-customers
  async createRemainingCustomers({ forMonth, forYear }) {
    return this.http.post('/billing/create-remaining-customers', {
      forMonth,
      forYear
    });
  }

  // POST /billing/create-with-customers
  async createWithCustomers({ forMonth, forYear, customerIds, name }) {
    return this.http.post('/billing/create-with-customers', {
      forMonth,
      forYear,
      name,
      customerIds
    });
  }

  // GET /billing/create-remaining-customers-estimate/{month}/{year}
  async createRemainingCustomersEstimate({ month, year }) {
    return this.http.get(`/billing/create-remaining-customers-estimate/${month}/${year}`);
  }

  // GET /billing/{year}/{month}
  async getBilling({ month, year }) {
    return this.http.get(`/billing/${year}/${month}`);
  }

  async createFromFilters(payload) {
    return this.http.post('/billing/create-from-filters', payload);
  }

  // POST /billing/finalize
  async finalizeBilling(billingId) {
    return this.http.post('/billing/finalize', null, { params: { billingId } });
  }

  async exportProjectPayments(portfolioId) {
    if (portfolioId) {
      return this.http.get(`/billing/project-billing-detail/${portfolioId}/lifetime/export`);
    }

    return this.http.get(`/billing/project-billing-detail/lifetime/export`);
  }

  async exportCustomerBalances(args = {}) {
    args.renderMasthead = args.renderMasthead || false;

    return this.http.get('/customers/balance-report/export', {
      params: {
        renderMasthead: args.renderMasthead
      }
    });
  }

  async exportProjectPaymentsByMonth(portfolioId, year, month) {
    if (portfolioId) {
      return this.http.get(`/billing/project-billing-detail/${year}/${month}/${portfolioId}/export`);
    }

    return this.http.get(`/billing/project-billing-detail/${year}/${month}/export`);
  }

  async exportSubscriberPayments(portfolioId) {
    return this.http.get('/billing/customer-billing-detail/lifetime/export', null, {
      params: {
        filterByPortfolioId: portfolioId
      }
    });
  }

  async exportSubscriberPaymentsByMonth(portfolioId, year, month) {
    return this.http.get(`/billing/customer-billing-detail/${year}/${month}/export`, null, {
      params: {
        filterByPortfolioId: portfolioId
      }
    });
  }

  async getBillingDates() {
    return this.http.get('/billing/billing-dates');
  }

  // DELETE /billing/customer
  async deleteCustomerFromBilling(customerBillingDetailId) {
    return this.http.delete('/billing/customer', {
      params: {
        customerBillingDetailId
      }
    });
  }

  // DELETE /billing/allCustomers
  async deleteAllCustomersFromBilling(billingId) {
    return this.http.delete('/billing/allCustomers', {
      params: {
        billingId
      }
    });
  }

  async deleteAllCustomersFromBillingPreflight(billingId) {
    return this.http.get('/billing/PreflightDeleteBatch', {
      params: {
        billingId
      }
    });
  }

  async exportCustomerAging(args = {}) {
    args.renderMasthead = args.renderMasthead || false;

    return this.http.get('/billing/customer-aging/export', {
      params: {
        asOfDate: args.asOfDate,
        gardenId: args.projectId,
        portfolioId: args.portfolioId,
        renderMasthead: args.renderMasthead
      }
    });
  }

  async exportCustomerAgingByProject(args = {}) {
    args.renderMasthead = args.renderMasthead || false;

    return this.http.get('/billing/customer-aging-by-project/export', {
      params: {
        asOfDate: args.asOfDate,
        gardenId: args.projectId,
        portfolioId: args.portfolioId,
        renderMasthead: args.renderMasthead
      }
    });
  }

  async exportProjectAging(args = {}) {
    args.renderMasthead = args.renderMasthead || false;

    return this.http.get('/billing/project-aging/export', {
      params: {
        asOfDate: args.asOfDate,
        portfolioId: args.portfolioId,
        renderMasthead: args.renderMasthead
      }
    });
  }

  async exportExpectedProjectRevenue(args = {}) {
    args.renderMasthead = args.renderMasthead || false;

    return this.http.get('/billing/expected-project-revenue/export', {
      params: {
        asOfDate: args.asOfDate,
        portfolioId: args.portfolioId,
        renderMasthead: args.renderMasthead
      }
    });
  }

  async exportExpectedSubscriberRevenue(args = {}) {
    args.renderMasthead = args.renderMasthead || false;

    return this.http.get('/billing/expected-subscriber-revenue/export', {
      params: {
        asOfDate: args.asOfDate,
        portfolioId: args.portfolioId,
        renderMasthead: args.renderMasthead
      }
    });
  }

  async exportInvoiceDetail(args = {}) {
    args.renderMasthead = args.renderMasthead || false;

    return this.http.get('/billing/invoice-detail/export', {
      params: {
        beginDate: args.beginDate,
        endDate: args.endDate,
        portfolioId: args.portfolioId,
        projectId: args.projectId,
        renderMasthead: args.renderMasthead,
        status: args.invoiceStatusId
      }
    });
  }

  async exportInvoicePaymentStatus(args = {}) {
    args.renderMasthead = args.renderMasthead || false;
    return this.http.get('/billing/invoice-payment/export', {
      params: {
        beginDate: args.beginDate,
        endDate: args.endDate,
        renderMasthead: args.renderMasthead
      }
    });
  }

  async exportChargePaymentStatus(args, renderMasthead = false) {
    return this.http.get('/billing/charge-payment/export', {
      params: {
        ...args,
        renderMasthead
      }
    });
  }

  async appliedPaymentsExport(args = {}) {
    args.renderMasthead = args.renderMasthead || false;

    return this.http.get('/billing/applied-payment/export', {
      params: {
        beginDate: args.beginDate,
        endDate: args.endDate,
        renderMasthead: args.renderMasthead,
        projectId: args.projectId,
        portfolioId: args.portfolioId
      }
    });
  }

  async appliedPaymentsByProjectExport(args = {}) {
    args.renderMasthead = args.renderMasthead || false;

    return this.http.get('/billing/applied-payment-project/export', {
      params: {
        beginDate: args.beginDate,
        endDate: args.endDate,
        renderMasthead: args.renderMasthead,
        portfolioId: args.portfolioId,
        projectId: args.projectId
      }
    });
  }
}
