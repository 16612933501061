import merge from 'lodash/merge';
import { handleActions } from 'redux-actions';

import { deleteCreditBandResponse } from 'shared/store/modules/creditBands/actions';
import { addEntities, deleteEntities } from 'shared/store/modules/entities';

const defaultState = {};

export default handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => merge({}, state, payload.entities.creditBands)
    },

    [deleteEntities]: {
      next: (state, { payload }) => {
        const newState = Object.assign({}, state);
        delete newState[payload.id];
        return newState;
      }
    },

    [deleteCreditBandResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          [payload]: {
            ...state[payload],
            deleted: true
          }
        };
      }
    }
  },

  defaultState
);
