import {
  portfolioPath,
  projectPath,
  subscriberPath,
  subscriberPremisePath,
  subscriberSubscriptionPath,
  utilityPath,
  utilityRatePath,
  utilityRateSchedulePath
} from 'shared/utilities/AdminPathHelper';

export default Object.freeze({
  Customer: {
    name: 'Subscriber',
    icon: 'people',
    path: subscriberPath
  },

  Document: {
    name: 'Document',
    icon: 'content_copy',
    path: () => ''
  },

  Garden: {
    name: 'Project',
    icon: 'layers',
    path: projectPath
  },

  Portfolio: {
    name: 'Portfolio',
    icon: 'folder',
    path: portfolioPath
  },

  Premise: {
    name: 'Premise',
    icon: 'place',
    path: subscriberPremisePath
  },

  Subscription: {
    name: 'Subscription',
    icon: 'loop',
    path: subscriberSubscriptionPath
  },

  User: {
    name: 'User',
    icon: 'contacts',
    path: () => ''
  },

  Utility: {
    name: 'Utility',
    icon: 'location_city',
    path: utilityPath
  },

  UtilityRateSchedule: {
    name: 'Schedule',
    icon: 'calendar_today',
    path: utilityRateSchedulePath
  },

  UtilityRateType: {
    name: 'Rate Class',
    icon: 'list',
    path: utilityRatePath
  }
});
