import { schema } from 'normalizr';

export const project = new schema.Entity('projects');
export const projects = [project];

project.define({
  allocations: require('shared/store/modules/allocations/schemas').allocations,
  monthlyReadings: require('shared/store/modules/readings/schemas').readings,
  portfolio: require('shared/store/modules/portfolios/schemas').portfolio,
  premises: require('shared/store/modules/premises/schemas').premises,
  utility: require('shared/store/modules/utilities/schemas').utility
});
