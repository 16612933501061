class Billing {
  accountActivity = [];
  billing = {
    datasets: []
  };

  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(Billing.fromJSON);
    }

    const billing = new Billing();

    billing.accountActivity = json.accountActivity || billing.accountActivity;
    billing.billing = json.billing || billing.billing;

    return {
      ...json,
      ...billing
    };
  }
}

export default Billing;
