import { css } from '@emotion/react';

const styles = () => css`
  .form-auth .form-control {
    position: relative;
    height: auto;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
  }

  .form-auth .was-validated .form-control:invalid,
  .form-auth .form-control.is-invalid {
    background-image: none !important;
  }

  .form-auth .form-control:focus {
    z-index: 2;
  }

  .form-login input[type='email'] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .form-login input[type='password'] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .password-requirements {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 3;
    cursor: pointer;
    padding: 6px;
  }

  .system-message {
    background-color: rgba(1, 72, 130, 0.15);
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-bottom: 1rem;

    p {
      margin: 0;
    }
  }
`;

export default styles;
