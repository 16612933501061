import { createAction } from 'redux-actions';

const MODULE = 'app/admin/referralCodes';

const CREATE_REFERRAL_CODE_REQUEST = `${MODULE}/CREATE_REFERRAL_CODE_REQUEST`;
const CREATE_REFERRAL_CODE_RESPONSE = `${MODULE}/CREATE_REFERRAL_CODE_RESPONSE`;
const DELETE_REFERRAL_CODE_REQUEST = `${MODULE}/DELETE_REFERRAL_CODE_REQUEST`;
const DELETE_REFERRAL_CODE_RESPONSE = `${MODULE}/DELETE_REFERRAL_CODE_RESPONSE`;
const FETCH_ALL_REFERRAL_CODES_REQUEST = `${MODULE}/FETCH_ALL_REFERRAL_CODES_REQUEST`;
const FETCH_ALL_REFERRAL_CODES_RESPONSE = `${MODULE}/FETCH_ALL_REFERRAL_CODES_RESPONSE`;
const FETCH_REFERRAL_CODE_REQUEST = `${MODULE}/FETCH_REFERRAL_CODE_REQUEST`;
const FETCH_REFERRAL_CODE_RESPONSE = `${MODULE}/FETCH_REFERRAL_CODE_RESPONSE`;
const UPDATE_REFERRAL_CODE_REQUEST = `${MODULE}/UPDATE_REFERRAL_CODE_REQUEST`;
const UPDATE_REFERRAL_CODE_RESPONSE = `${MODULE}/UPDATE_REFERRAL_CODE_RESPONSE`;

export const createReferralCodeRequest = createAction(CREATE_REFERRAL_CODE_REQUEST);
export const createReferralCodeResponse = createAction(CREATE_REFERRAL_CODE_RESPONSE);
export const deleteReferralCodeRequest = createAction(DELETE_REFERRAL_CODE_REQUEST);
export const deleteReferralCodeResponse = createAction(DELETE_REFERRAL_CODE_RESPONSE);
export const fetchAllReferralCodesRequest = createAction(FETCH_ALL_REFERRAL_CODES_REQUEST);
export const fetchAllReferralCodesResponse = createAction(FETCH_ALL_REFERRAL_CODES_RESPONSE);
export const fetchReferralCodeRequest = createAction(FETCH_REFERRAL_CODE_REQUEST);
export const fetchReferralCodeResponse = createAction(FETCH_REFERRAL_CODE_RESPONSE);
export const updateReferralCodeRequest = createAction(UPDATE_REFERRAL_CODE_REQUEST);
export const updateReferralCodeResponse = createAction(UPDATE_REFERRAL_CODE_RESPONSE);
