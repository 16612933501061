import merge from 'lodash/merge';
import { combineActions, handleActions } from 'redux-actions';

import { addEntities } from 'shared/store/modules/entities';
import { createProspectWithPremise, setUtilAuthResponse, sign } from 'shared/store/modules/prospects/actions';

const initialState = {};

export const byId = handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => {
        if (payload.entities.accounts) {
          return merge({}, payload.entities.accounts);
        }

        return state;
      }
    },

    [combineActions(setUtilAuthResponse, createProspectWithPremise, sign)]: {
      next: (state, { payload: { nextStepToComplete, prospectId } }) => {
        return { ...state, [prospectId]: { ...state[prospectId], nextStepToComplete: nextStepToComplete } };
      }
    }
  },

  initialState
);
