import { handleActions, combineActions } from 'redux-actions';

import { deleteEntities } from 'shared/store/modules/entities';
import { fetchAllProjectsResponse, customerProjectsFetchResponse } from 'shared/store/modules/projects/actions';

const defaultState = [];

export default handleActions(
  {
    [combineActions(fetchAllProjectsResponse, customerProjectsFetchResponse)]: {
      next: (_, { payload }) => [...payload.result]
    },

    [deleteEntities]: {
      next: (state, { payload }) => {
        if (Array.isArray(state)) {
          return state.filter(id => id !== payload.id);
        }

        return state;
      }
    }
  },

  defaultState
);
