import { createAction } from 'redux-actions';

const MODULE = 'app/admin/utilities';

const ADD_CONTRACTS_TO_UTILITY = `${MODULE}/ADD_CONTRACTS_TO_UTILITY`;
const CREATE_UTILITY_REQUEST = `${MODULE}/CREATE_UTILITY_REQUEST`;
const CREATE_UTILITY_RESPONSE = `${MODULE}/CREATE_UTILITY_RESPONSE`;
const FETCH_ALL_UTILITIES_REQUEST = `${MODULE}/FETCH_ALL_UTILITIES_REQUEST`;
const FETCH_ALL_UTILITIES_RESPONSE = `${MODULE}/FETCH_ALL_UTILITIES_RESPONSE`;
const FETCH_UTILITY_DETAILS_REQUEST = `${MODULE}/FETCH_UTILITY_DETAILS_REQUEST`;
const FETCH_UTILITY_DETAILS_RESPONSE = `${MODULE}/FETCH_UTILITY_DETAILS_RESPONSE`;
const FETCH_UTILITY_REQUEST = `${MODULE}/FETCH_UTILITY_REQUEST`;
const FETCH_UTILITY_RESPONSE = `${MODULE}/FETCH_UTILITY_RESPONSE`;
const UPDATE_UTILITY_REQUEST = `${MODULE}/UPDATE_UTILITY_REQUEST`;
const UPDATE_UTILITY_RESPONSE = `${MODULE}/UPDATE_UTILITY_RESPONSE`;

export const addContractsToUtility = createAction(ADD_CONTRACTS_TO_UTILITY);
export const createUtilityRequest = createAction(CREATE_UTILITY_REQUEST);
export const createUtilityResponse = createAction(CREATE_UTILITY_RESPONSE);
export const fetchAllUtilitiesRequest = createAction(FETCH_ALL_UTILITIES_REQUEST);
export const fetchAllUtilitiesResponse = createAction(FETCH_ALL_UTILITIES_RESPONSE);
export const fetchUtilityDetailsRequest = createAction(FETCH_UTILITY_DETAILS_REQUEST);
export const fetchUtilityDetailsResponse = createAction(FETCH_UTILITY_DETAILS_RESPONSE);
export const fetchUtilityRequest = createAction(FETCH_UTILITY_REQUEST);
export const fetchUtilityResponse = createAction(FETCH_UTILITY_RESPONSE);
export const updateUtilityRequest = createAction(UPDATE_UTILITY_REQUEST);
export const updateUtilityResponse = createAction(UPDATE_UTILITY_RESPONSE);
