export class PaymentService {
  http;

  constructor(http) {
    this.http = http;
  }

  // POST /payments/payment/source
  async createPayment({ amount, customerId, tokenOrSourceId }) {
    return this.http.post('/payments/payment/source', {
      amount,
      customerId,
      tokenOrSourceId
    });
  }

  async getCardFee(amount, provider = 0) {
    return this.http.get('/payments/payment/card-fee', {
      params: {
        amount,
        provider
      }
    });
  }

  async exportPaymentSource(params) {
    params.renderMasthead = params.renderMasthead || false;

    return this.http.get('/payments/customer-source/export', {
      params
    });
  }

  async getFeeSchedule() {
    return this.http.get('/payments/payment/card-fee-schedule');
  }
}
