export class AllocationService {
  http;

  constructor(http) {
    this.http = http;
  }

  async create(gardenId, allocation) {
    return this.http.post('/allocations', {
      gardenId,
      ...allocation
    });
  }

  async update(id, allocation) {
    return this.http.put('/allocations', {
      id,
      ...allocation
    });
  }

  async delete(id) {
    return this.http.delete('/allocations', {
      params: {
        allocationId: id
      }
    });
  }

  async export(args = {}) {
    args.renderMasthead = args.renderMasthead || false;
    const params = args.statusValues
      ? {
          projectId: args.projectId,
          portfolioId: args.portfolioId,
          status: args.statusValues,
          renderMasthead: args.renderMasthead
        }
      : {
          projectId: args.projectId,
          portfolioId: args.portfolioId,
          renderMasthead: args.renderMasthead
        };

    return this.http.get('/allocations/export', { params });
  }

  async exportByProject(args = {}) {
    args.renderMasthead = args.renderMasthead || false;

    return this.http.get('/allocations/garden/export', {
      params: {
        portfolioId: args.portfolioId,
        renderMasthead: args.renderMasthead
      }
    });
  }

  async exportBySubscriber(args = {}) {
    args.renderMasthead = args.renderMasthead || false;

    return this.http.get('/allocations/customer/export', {
      params: {
        effectiveEndDate: args.endDate,
        effectiveBeginDate: args.beginDate,
        renderMasthead: args.renderMasthead
      }
    });
  }

  async exportDelta(args = {}) {
    args.renderMasthead = args.renderMasthead || false;

    return this.http.get('/allocations/delta/export', {
      params: {
        currentProjectId: args.projectId,
        modifiedBeginDate: args.beginDate,
        modifiedEndDate: args.endDate,
        renderMasthead: args.renderMasthead
      }
    });
  }

  async getStatuses() {
    return this.http.get('allocations/valid-statuses');
  }
}
