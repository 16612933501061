class Model {
  mapValues(model, json) {
    const values = {};

    Object.keys(model).forEach(key => {
      if (json[key] !== undefined && json[key] !== null) {
        values[key] = json[key];
      } else {
        values[key] = model[key];
      }
    });

    return {
      ...json,
      ...values
    };
  }
}

export default Model;
