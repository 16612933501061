import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { parse } from 'query-string';

import { AuthLayout } from 'shared/components/AuthLayout';
import { LoginForm } from './forms/LoginForm';

export function Login() {
  const { search } = useLocation();
  const { loginWithRedirect } = useAuth0();
  const { invitation, organization, connection } = parse(search, { parseBooleans: true });

  const acceptInvite = useCallback(async () => {
    if (invitation && organization) {
      try {
        await loginWithRedirect({
          authorizationParams: {
            invitation: invitation.toString(),
            organization: organization.toString(),
            redirect_uri: `${window.location.origin}/after-login`,
            connection: connection?.toString()
          }
        });
      } catch (error) {
        console.error('Auth0 loginWithRedirect Error (Login.tsx)', error);
      }
    }
  }, [invitation, organization, connection, loginWithRedirect]);

  useEffect(() => {
    if (invitation && organization) {
      acceptInvite();
    }
  }, [acceptInvite, invitation, organization]);

  return (
    <AuthLayout title="Login" showLoginMesage={true}>
      <LoginForm />
      <small className="d-block text-center mt-4" style={{ fontWeight: 700 }}>
        <a
          target="_blank"
          href="https://suncentral.net/support?hsCtaTracking=d3a58b16-6b83-48b0-8bce-38b2bed53c08%7C89ae1e6d-5c08-4a6b-99de-541e95afa33f"
          rel="noreferrer"
        >
          Need help? Contact us.
        </a>
      </small>

      {/*<small className="d-block text-center mt-3">
        <Link to="/forgot-password">Forgot Password?</Link>
      </small>*/}
    </AuthLayout>
  );
}
