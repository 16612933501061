import { createAction } from 'redux-actions';

const MODULE = 'app/admin/allocations';

const CREATE_ALLOCATION_REQUEST = `${MODULE}/CREATE_ALLOCATION_REQUEST`;
const CREATE_ALLOCATION_RESPONSE = `${MODULE}/CREATE_ALLOCATION_RESPONSE`;
const UPDATE_ALLOCATION_REQUEST = `${MODULE}/UPDATE_ALLOCATION_REQUEST`;
const UPDATE_ALLOCATION_RESPONSE = `${MODULE}/UPDATE_ALLOCATION_RESPONSE`;
const DELETE_ALLOCATION_REQUEST = `${MODULE}/DELETE_ALLOCATION_REQUEST`;
const DELETE_ALLOCATION_RESPONSE = `${MODULE}/DELETE_ALLOCATION_RESPONSE`;
const GET_STATUSES_REQUEST = `${MODULE}/GET_STATUSES_REQUEST`;
const GET_STATUSES_RESPONSE = `${MODULE}/GET_STATUSES_RESPONSE`;

export const createAllocationRequest = createAction(CREATE_ALLOCATION_REQUEST);
export const createAllocationResponse = createAction(CREATE_ALLOCATION_RESPONSE);
export const updateAllocationRequest = createAction(UPDATE_ALLOCATION_REQUEST);
export const updateAllocationResponse = createAction(UPDATE_ALLOCATION_RESPONSE);
export const deleteAllocationRequest = createAction(DELETE_ALLOCATION_REQUEST);
export const deleteAllocationResponse = createAction(DELETE_ALLOCATION_RESPONSE);
export const getAllocationStatusesRequest = createAction(GET_STATUSES_REQUEST);
export const getAllocationStatusesResponse = createAction(GET_STATUSES_RESPONSE);
