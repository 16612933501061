import { createAction } from 'redux-actions';

const MODULE = 'app/admin/autoAllocations';

const AUTOALLOCATION_EXPORT_REQUEST = `${MODULE}/AUTOALLOCATION_EXPORT_REQUEST`;
const AUTOALLOCATION_EXPORT_RESPONSE = `${MODULE}/AUTOALLOCATION_EXPORT_RESPONSE`;
const AUTOALLOCATION_EXPORT_LOG_REQUEST = `${MODULE}/AUTOALLOCATION_EXPORT_LOG_REQUEST`;
const AUTOALLOCATION_EXPORT_LOG_RESPONSE = `${MODULE}/AUTOALLOCATION_EXPORT_LOG_RESPONSE`;
const CREATE_REQUEST = `${MODULE}/CREATE_REQUEST`;
const CREATE_RESPONSE = `${MODULE}/CREATE_RESPONSE`;
const DELETE_REQUEST = `${MODULE}/DELETE_REQUEST`;
const DELETE_RESPONSE = `${MODULE}/DELETE_RESPONSE`;
const EXPORT_REQUEST = `${MODULE}/EXPORT_REQUEST`;
const EXPORT_RESPONSE = `${MODULE}/EXPORT_RESPONSE`;
const FETCH_ALL_REQUEST = `${MODULE}/FETCH_ALL_REQUEST`;
const FETCH_ALL_RESPONSE = `${MODULE}/FETCH_ALL_RESPONSE`;
const FETCH_ONE_REQUEST = `${MODULE}/FETCH_ONE_REQUEST`;
const FETCH_ONE_RESPONSE = `${MODULE}/FETCH_ONE_RESPONSE`;
const HEALTHCHECK_REQUEST = `${MODULE}/HEALTHCHECK_REQUEST`;
const HEALTHCHECK_RESPONSE = `${MODULE}/HEALTHCHECK_RESPONSE`;
const WAITLIST_EXPORT_REQUEST = `${MODULE}/WAITLIST_EXPORT_REQUEST`;
const WAITLIST_EXPORT_RESPONSE = `${MODULE}/WAITLIST_EXPORT_RESPONSE`;
const WAITLIST_REQUEST = `${MODULE}/WAITLIST_REQUEST`;
const WAITLIST_RESPONSE = `${MODULE}/WAITLIST_RESPONSE`;

export const autoAllocationExportRequest = createAction(AUTOALLOCATION_EXPORT_REQUEST);
export const autoAllocationExportResponse = createAction(AUTOALLOCATION_EXPORT_RESPONSE);
export const autoAllocationExportLogRequest = createAction(AUTOALLOCATION_EXPORT_LOG_REQUEST);
export const autoAllocationExportLogResponse = createAction(AUTOALLOCATION_EXPORT_LOG_RESPONSE);
export const createRequest = createAction(CREATE_REQUEST);
export const createResponse = createAction(CREATE_RESPONSE);
export const deleteRequest = createAction(DELETE_REQUEST);
export const deleteResponse = createAction(DELETE_RESPONSE);
export const exportRequest = createAction(EXPORT_REQUEST);
export const exportResponse = createAction(EXPORT_RESPONSE);
export const fetchAllRequest = createAction(FETCH_ALL_REQUEST);
export const fetchAllResponse = createAction(FETCH_ALL_RESPONSE);
export const fetchOneRequest = createAction(FETCH_ONE_REQUEST);
export const fetchOneResponse = createAction(FETCH_ONE_RESPONSE);
export const healthcheckRequest = createAction(HEALTHCHECK_REQUEST);
export const healthcheckResponse = createAction(HEALTHCHECK_RESPONSE);
export const waitlistExportRequest = createAction(WAITLIST_EXPORT_REQUEST);
export const waitlistExportResponse = createAction(WAITLIST_EXPORT_RESPONSE);
export const waitlistRequest = createAction(WAITLIST_REQUEST);
export const waitlistResponse = createAction(WAITLIST_RESPONSE);
