import invert from 'lodash/invert';

const ActionEntityType = {
  Billing: 0,
  CreditAllocation: 1,
  Customer: 2,
  Document: 3,
  Garden: 4,
  GeneralLedgerItem: 5,
  Invoice: 6,
  MonthlyGardenReading: 7,
  MonthlyPremiseAllocation: 8,
  Portfolio: 9,
  Premise: 10,
  Subscription: 11,
  SubscriptionTemplate: 12,
  User: 13,
  Utility: 14,
  UtilityMap: 15,
  UtilityRateSchedule: 16,
  UtilityRateType: 17
};

export default Object.freeze({
  ...ActionEntityType,
  ...invert(ActionEntityType)
});
