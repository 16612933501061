import classNames from 'classnames';
import { Button, DropdownMenu, DropdownToggle, NavItem, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Icon from 'shared/components/Icon';
import { RootState } from 'shared/store/types';
import { CustomerType, UserRole } from 'shared/models';
import { getIsNavOpen } from 'shared/store/modules/nav/selectors';
import { selectActiveAccount, selectNumberOfAccounts } from 'shared/store/modules/accounts/selectors';
import { toggleNav } from 'shared/store/modules/nav/actions';

import styles from './Header.styles';

export function Header() {
  const account = useSelector(selectActiveAccount);
  const user = useSelector((state: RootState) => state.user);

  const { firstName } = user;

  const dispatch = useDispatch();
  const isNavOpen = useSelector(getIsNavOpen);
  const numberOfAccounts = useSelector(selectNumberOfAccounts);

  const cls = classNames({
    nav: true,
    'nav-toggler': true,
    active: isNavOpen
  });

  return (
    <div css={styles}>
      <header className="header">
        <nav className="nav-utility">
          <ul className={cls}>
            <NavItem>
              <Button
                className="account-toggle text-white"
                color="link"
                onClick={() => dispatch(toggleNav())}
                outline
                tag="span"
              >
                <Icon icon={isNavOpen ? 'chevron_left' : 'menu'} />
              </Button>
            </NavItem>
          </ul>

          <ul className="nav ml-auto">
            <UncontrolledDropdown inNavbar nav>
              <DropdownToggle className="account-toggle" nav>
                <div className="d-flex align-items-center">
                  <div>
                    <span className="account-name">{firstName}</span>
                    {account.customerType === CustomerType.Commercial ? (
                      <p className="mb-0 mr-4 subscriber-name">{account.name}</p>
                    ) : null}
                  </div>
                  <Icon icon="account_circle" />
                </div>
              </DropdownToggle>

              <DropdownMenu className="ddm-account" end>
                <Link className="dropdown-item" to="/profile">
                  My Profile
                </Link>

                {account.role === UserRole['Account Manager'] && (
                  <Link className="dropdown-item" to="/settings">
                    Account Settings
                  </Link>
                )}

                {numberOfAccounts > 1 && (
                  <Link className="dropdown-item" to="/accounts">
                    Change Account
                  </Link>
                )}

                <Link className="dropdown-item" to="/logout">
                  Sign out
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          </ul>
        </nav>
      </header>
    </div>
  );
}
