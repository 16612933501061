import { combineReducers } from 'redux';

import allIds from './allIds';
import byId from './byId';
import statuses from './statuses';

export const reducer = combineReducers({
  allIds,
  byId,
  statuses
});
