export class PortfolioService {
  http;

  constructor(http) {
    this.http = http;
  }

  async fetchAll() {
    return this.http.get('/portfolios');
  }

  async fetchOne(id) {
    return this.http.get(`/portfolios/${id}`);
  }

  async fetchDetails(id) {
    return this.http.get(`/portfolios/${id}/details`);
  }

  async create(portfolio) {
    return this.http.post('/portfolios', portfolio);
  }

  async update(id, portfolio) {
    return this.http.put('/portfolios', { id, ...portfolio });
  }

  async delete(id) {
    return this.http.delete(`/portfolios/${id}`);
  }

  // TODO: Hook these up to the correct endpoints
  async exportPaymentsLifetime(id, type) {
    return this.http.get(`/billing/wip/non-existing/portfolio-billing-detail/${id}/${type}/lifetime/export`);
  }

  async exportPaymentsByMonth(id, year, month, type) {
    return this.http.get(`/billing/wip-non-existing/portfolio-billing-detail/${id}/${year}/${month}/${type}/export`);
  }

  async exportProductionLifetime(id) {
    return this.http.get(`/portfolios/${id}/lifetime/export`);
  }

  async exportProductionByMonth(id, year, month) {
    return this.http.get(`/portfolios/${id}/${year}/${month}/export`);
  }

  async fetchSettlementDetails(portfolioId) {
    return this.http.get('/portfolios/settlement-details', {
      params: {
        portfolioId
      }
    });
  }

  async settleBalance(portfolioId, amount, selectedBucketType) {
    return this.http.post('/portfolios/settle', {
      portfolioId,
      amount,
      bucketType: ~~selectedBucketType
    });
  }

  async exportActivities(portfolioId) {
    return this.http.get(`/portfolios/${portfolioId}/account-activity/export`);
  }

  async exportAccrual(renderMasthead = false) {
    return this.http.get('/portfolios/balances/export', {
      params: {
        renderMasthead
      }
    });
  }

  async exportActivity(args = {}) {
    args.renderMasthead = args.renderMasthead || false;
    return this.http.get(`/portfolios/all-activity/export`, {
      params: {
        renderMasthead: args.renderMasthead,
        portfolioId: args.portfolioId,
        beginDate: args.beginDate,
        endDate: args.endDate
      }
    });
  }
}
