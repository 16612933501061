import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'shared/store/types';
import { selectUserId } from 'shared/store/modules/auth/selectors';
import { fetchUser } from './effects';

export function useActiveUser() {
  const dispatch = useDispatch<AppDispatch>();
  const userId = useSelector(selectUserId);

  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [dispatch, userId]);
}
