import merge from 'lodash/merge';
import { handleActions, combineActions } from 'redux-actions';

import { createPortfolioActivityResponse } from 'shared/store/modules/activities/actions';
import { addEntities, deleteEntities } from 'shared/store/modules/entities';
import {
  settlePortfolioBalanceResponse,
  fetchPortfolioSettlementDetailsResponse,
  updatePortfolioResponse
} from 'shared/store/modules/portfolios/actions';
import { createResponse } from 'shared/store/modules/users/actions';

const defaultState = {};

export default handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => {
        return merge({}, state, payload.entities.portfolios);
      }
    },

    [deleteEntities]: {
      next: (state, { payload }) => {
        const newState = Object.assign({}, state);
        delete newState[payload.id];
        return newState;
      }
    },

    [createResponse]: {
      next: (state, { payload }) => {
        const { portfolioId } = payload.entities.users[payload.result];

        if (portfolioId) {
          return {
            ...state,
            [portfolioId]: {
              ...state[portfolioId],
              users: [...state[portfolioId].users, payload.result]
            }
          };
        }
      }
    },

    [updatePortfolioResponse]: {
      next: (state, { payload }) => ({
        ...state,
        [payload.result]: {
          ...state[payload.result],
          ...payload.entities.portfolios[payload.result]
        }
      })
    },

    [combineActions(createPortfolioActivityResponse, settlePortfolioBalanceResponse)]: {
      next: (state, { payload }) => {
        const { balance, matureBalance, portfolioId } = payload.entities.activities[payload.result];

        return {
          ...state,
          [portfolioId]: {
            ...state[portfolioId],
            activities: [payload.result, ...state[portfolioId].activities],
            currentBalance: balance,
            currentMatureBalance: matureBalance
          }
        };
      }
    },

    [fetchPortfolioSettlementDetailsResponse]: {
      next: (state, { payload }) => {
        const settlementAccount = payload.result;
        const { portfolio } = payload.entities.settlementAccounts[settlementAccount];

        return {
          ...state,
          [portfolio]: {
            ...state[portfolio],
            settlementAccount: settlementAccount
          }
        };
      }
    }
  },

  defaultState
);
