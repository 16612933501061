import { Billing, Document, Premise, Project, Subscription, User } from 'shared/models';

class Subscriber {
  address = '';
  address2 = '';
  billingDashboard = {};
  city = '';
  company = '';
  customerType = 0;
  documents = [];
  externalId = '';
  subscriberFirstName = '';
  subscriberLastName = '';
  subscriberId = '';
  userFirstName = '';
  userLastName = '';
  userId = '';
  phoneNumber = '';
  email = '';
  invoiceNote = '';
  isDisabled = false;
  premises = [];
  projects = [];
  stateId = '';
  subscriptions = [];
  users = [];
  zipCode = '';

  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(Subscriber.fromJSON);
    }

    const subscriber = new Subscriber();

    subscriber.address = json.address || subscriber.address;
    subscriber.address2 = json.address2 || subscriber.address2;
    subscriber.city = json.city || subscriber.city;
    subscriber.company = json.company || subscriber.company;
    subscriber.customerType = json.customerType || subscriber.customerType;
    subscriber.externalId = json.externalId || subscriber.externalId;
    subscriber.subscriberFirstName = json.subscriberFirstName || subscriber.subscriberFirstName;
    subscriber.subscriberId = json.subcriberId || subscriber.subcriberId;
    subscriber.userId = json.userId || subscriber.userId;
    subscriber.invoiceNote = json.invoiceNote || subscriber.invoiceNote;
    subscriber.isDisabled = json.isDisabled || subscriber.isDisabled;
    subscriber.subscriberLastName = json.subscriberLastName || subscriber.subscriberLastName;
    subscriber.userFirstName = json.userFirstName || subscriber.userFirstName;
    subscriber.userLastName = json.userLastName || subscriber.userLastName;
    subscriber.stateId = json.stateId || subscriber.stateId;
    subscriber.zipCode = json.zipCode || subscriber.zipCode;
    subscriber.email = json.email || subscriber.email;
    subscriber.phoneNumber = json.phoneNumber || subscriber.phoneNumber;

    subscriber.premises = [...Premise.fromJSON(json.premises || subscriber.premises)];

    subscriber.subscriptions = [...Subscription.fromJSON(json.subscriptions || subscriber.subscriptions)];

    subscriber.projects = [...Project.fromJSON(json.projects || subscriber.projects)];

    subscriber.documents = [...Document.fromJSON(json.documents || subscriber.documents)];

    subscriber.users = [...User.fromJSON(json.users || subscriber.users)];

    subscriber.billingDashboard = Billing.fromJSON(json.billingDashboard || subscriber.billingDashboard);

    return {
      ...json,
      ...subscriber
    };
  }
}

export default Subscriber;
