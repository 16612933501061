import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import styled from 'styled-components';
import Icon from 'shared/components/Icon';

const NavIcon = styled(Icon)`
  margin-right: 10px;
`;

export const PMNavigation = () => (
  <Nav className="navigation-primary" vertical>
    <NavItem>
      <NavLink exact tag={Link} to="/">
        <NavIcon icon="folder" /> My Portfolio
      </NavLink>
    </NavItem>

    <NavItem>
      <NavLink tag={Link} to="/projects">
        <NavIcon icon="layers" /> Projects
      </NavLink>
    </NavItem>

    <NavItem>
      <NavLink tag={Link} to="/subscribers">
        <NavIcon icon="people" /> Subscribers
      </NavLink>
    </NavItem>
  </Nav>
);
