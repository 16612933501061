import merge from 'lodash/merge';
import { handleActions } from 'redux-actions';

import { addEntities } from 'shared/store/modules/entities';

const defaultState = {};

export default handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => {
        return merge({}, state, payload.entities.documents);
      }
    }
  },

  defaultState
);
