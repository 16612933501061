export enum LedgerEntryType {
  // Credits
  ManualCredit = '0',
  AchPayment = '10',
  CardPayment = '20',
  ReferralCredit = '30',

  // Charges
  ManualCharge = '1000',
  PaymentProcessingFee = '1010',
  Invoice = '1020',
  Deposit = '1030',
  ReferralCharge = '1040'
}
