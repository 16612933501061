import React, { Component } from 'react';
import cx from 'classnames';
import ReactLoading from 'react-loading';
import { Button, Card, CardBody } from 'reactstrap';
import sctheme from 'app/styles/sctheme';

export class Loading extends Component {
  static defaultProps = {
    width: 75,
    height: 75,
    color: sctheme.blues.primary
  };

  render() {
    const { color, width, height } = this.props;
    return (
      <div className="d-flex h-100 w-100 justify-content-center align-items-center">
        <ReactLoading color={color} height={height} type="bubbles" width={width} />
      </div>
    );
  }
}

export function LoadingCard({ className }) {
  return (
    <Card className={className}>
      <CardBody className="d-flex align-items-center p-5">
        <Loading />
      </CardBody>
    </Card>
  );
}

export function LoadingButton(props) {
  const { children, className, isLoading, ...restProps } = props;
  return (
    <Button className={cx(className, 'd-flex justify-content-center')} {...restProps}>
      {children}
      {isLoading && <Loading color={sctheme.white} height={25} width={25} />}
    </Button>
  );
}

export default Loading;
