import Model from './Model';

class DeferredCharge extends Model {
  description = '';
  amount = '';

  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(DeferredCharge.fromJSON);
    }

    const deferredCharge = new DeferredCharge();

    return deferredCharge.mapValues(deferredCharge, json);
  }
}

export default DeferredCharge;
