import { createAction } from 'redux-actions';

const MODULE = 'app/admin/dashboard';

const CLEAR_ALL_PORTFOLIOS_FILTER = `${MODULE}/CLEAR_ALL_PORTFOLIOS_FILTER`;
const DASHBOARD_FETCH_REQUEST = 'DASHBOARD_FETCH_REQUEST';
const DASHBOARD_FETCH_RESPONSE = 'DASHBOARD_FETCH_RESPONSE';
const FETCH_DASHBOARD_REQUEST = `${MODULE}/FETCH_DASHBOARD_REQUEST`;
const FETCH_DASHBOARD_RESPONSE = `${MODULE}/FETCH_DASHBOARD_RESPONSE`;
const FILTER_ALL_PORTFOLIOS = `${MODULE}/FILTER_ALL_PORTFOLIOS`;
const FILTER_PORTFOLIOS = `${MODULE}/FILTER_PORTFOLIOS`;

export const clearAllPortfoliosFilter = createAction(CLEAR_ALL_PORTFOLIOS_FILTER);
export const dashboardFetchRequest = createAction(DASHBOARD_FETCH_REQUEST);
export const dashboardFetchResponse = createAction(DASHBOARD_FETCH_RESPONSE);
export const fetchDashboardRequest = createAction(FETCH_DASHBOARD_REQUEST);
export const fetchDashboardResponse = createAction(FETCH_DASHBOARD_RESPONSE);
export const filterAllPortfolios = createAction(FILTER_ALL_PORTFOLIOS);
export const filterPortfolios = createAction(FILTER_PORTFOLIOS);
