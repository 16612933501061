import React, { PropsWithChildren } from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

type BugsnagProps = {
  key: string;
};

export function Bugsnag(props: PropsWithChildren<BugsnagProps>) {
  const { children, key } = props;

  if (process.env.NODE_ENV.toLowerCase() === 'development' || !key) {
    return <>{children}</>;
  }

  const client = bugsnag({
    apiKey: key,
    appVersion: process.env.REACT_APP_VERSION
  });

  client.use(bugsnagReact, React);

  const Component = client.getPlugin('react');

  return <Component>{children}</Component>;
}
