export class AutoAllocationService {
  http;

  constructor(http) {
    this.http = http;
  }

  async create(utilityId, title, effectiveDate, customerIds, projectIds) {
    return this.http.post('/allocations/auto-allocations', {
      utilityId,
      title,
      effectiveDate,
      customerIds,
      projectIds
    });
  }

  async delete(autoAllocationId) {
    return this.http.delete(`/allocations/auto-allocations/${autoAllocationId}`);
  }

  async fetchAutoAllocation(autoAllocationId) {
    return this.http.get(`/allocations/auto-allocations/${autoAllocationId}`);
  }

  async fetchDetails(autoAllocationId) {
    return this.http.get(`/allocations/auto-allocations/${autoAllocationId}/log`);
  }

  async fetchWaitlist(utilityId, effectiveDate) {
    return this.http.get(`/allocations/auto-allocations/${utilityId}/wait-list/${effectiveDate}`, {});
  }

  async fetchHealthCheck(utilityId, effectiveDate) {
    return this.http.get(`/allocations/auto-allocations/${utilityId}/health-check/${effectiveDate.split('T')[0]}`);
  }

  async fetchWaitlistExport(utilityId, effectiveDate, customerType) {
    return this.http.get(`/allocations/auto-allocations/${utilityId}/wait-list/${effectiveDate.split('T')[0]}/export`, {
      responseType: 'blob',
      params: {
        customerType: customerType ? customerType : 1
      }
    });
  }

  async fetchAll(utilityId) {
    return this.http.get(`/allocations/auto-allocations/`, {
      params: {
        utilityId,
        days: 10
      }
    });
  }

  async export(autoAllocationId) {
    return this.http.get(`/allocations/auto-allocations/${autoAllocationId}/export`, {
      responseType: 'blob'
    });
  }

  async exportLog(autoAllocationId) {
    return this.http.get(`/allocations/auto-allocations/${autoAllocationId}/log`, {
      responseType: 'blob'
    });
  }
}
