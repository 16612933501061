import { LOCATION_CHANGE } from 'connected-react-router';
import { handleActions } from 'redux-actions';

import { toggleNav } from 'shared/store/modules/nav/actions';

const defaultState = false;

export default handleActions(
  {
    [toggleNav]: {
      next: state => !state
    },

    [LOCATION_CHANGE]: {
      next: () => false
    }
  },

  defaultState
);
