import { withFormik, FormikProps } from 'formik';
import React, { useEffect, useRef } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import * as Yup from 'yup';

interface ForgotPasswordFormValues {
  username: string;
}

type ForgotPasswordFormWrapperProps = {
  onError: React.Dispatch<React.SetStateAction<string | undefined>>;
  onSubmit: (username: string) => any;
  onSuccess?: () => void;
};

function ForgotPasswordForm(props: FormikProps<ForgotPasswordFormValues> & ForgotPasswordFormWrapperProps) {
  const { onError, ...formikProps } = props;
  const { handleChange, handleSubmit, isSubmitting, isValid, submitCount, values } = formikProps;

  const usernameInput = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (usernameInput && usernameInput.current) {
      usernameInput.current.focus();
    }
  }, [submitCount]);

  function handleBlur(e: React.FocusEvent<any>) {
    onError(undefined);
    formikProps.handleBlur(e);
  }

  return (
    <Form className="form-auth form-forgot-password" onSubmit={handleSubmit}>
      <FormGroup>
        <Label className="sr-only" for="username">
          Email Address
        </Label>

        <Input
          autoFocus
          disabled={isSubmitting}
          id="username"
          innerRef={usernameInput}
          name="username"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Email Address"
          type="email"
          value={values.username}
        />
      </FormGroup>

      <Button block color="primary" disabled={isSubmitting || !isValid} size="lg" type="submit">
        {isSubmitting ? 'Submitting ...' : 'Send password reset email'}
      </Button>
    </Form>
  );
}

const ForgotPasswordFormWrapper = withFormik<ForgotPasswordFormWrapperProps, ForgotPasswordFormValues>({
  enableReinitialize: true,

  mapPropsToValues: () => ({
    username: ''
  }),

  validationSchema: Yup.object().shape({
    username: Yup.string()
      .email()
      .label('Email Address')
      .required()
  }),

  handleSubmit: async (values, bag) => {
    const { props, setSubmitting, resetForm } = bag;
    const { username } = values;
    const response = await props.onSubmit(username);
    setSubmitting(false);

    if (response.error) {
      props.onError('An error occurred, please try again later.');
    } else {
      typeof props.onSuccess === 'function' && props.onSuccess();
    }

    resetForm();
  }
})(ForgotPasswordForm);

export { ForgotPasswordFormWrapper as ForgotPasswordForm };
