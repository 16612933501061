import merge from 'lodash/merge';
import { handleActions } from 'redux-actions';

import { createActivityResponse } from 'shared/store/modules/activities/actions';
import { uploadDocumentResponse } from 'shared/store/modules/documents/actions';
import { addEntities, deleteEntities } from 'shared/store/modules/entities';
import { createPremiseResponse } from 'shared/store/modules/premises/actions';
import { fetchSubscriberDetailsResponse } from 'shared/store/modules/subscribers/actions';
import { createResponse } from 'shared/store/modules/users/actions';

const defaultState = {};

export default handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => {
        return merge({}, state, payload.entities.subscribers);
      }
    },

    [createPremiseResponse]: {
      next: (state, { payload }) => {
        if (payload.subscriberId) {
          let { premises } = state[payload.subscriberId];

          premises.push(payload.premiseId);

          return state;
        }
      }
    },

    [deleteEntities]: {
      next: (state, { payload }) => {
        const newState = Object.assign({}, state);
        delete newState[payload.id];
        return newState;
      }
    },

    [fetchSubscriberDetailsResponse]: {
      next: (state, { payload }) => {
        const subscriberId = payload.result;

        if (subscriberId) {
          return {
            ...state,
            [subscriberId]: {
              ...payload.entities.subscribers[subscriberId]
            }
          };
        }
      }
    },

    [createResponse]: {
      next: (state, { payload }) => {
        const subscriberId = payload.entities.users[payload.result].customerId;

        if (subscriberId) {
          return {
            ...state,
            [subscriberId]: {
              ...state[subscriberId],
              users: [...state[subscriberId].users, payload.result]
            }
          };
        }
      }
    },

    [uploadDocumentResponse]: {
      next: (state, { payload }) => {
        const subscriberId = payload.entities.documents[payload.result].customerId;

        return {
          ...state,
          [subscriberId]: {
            ...state[subscriberId],
            documents: [...state[subscriberId].documents, payload.result]
          }
        };
      }
    },

    [createActivityResponse]: {
      next: (state, { payload }) => {
        const subscriberId = payload.entities.activities[payload.result].customerId;

        return {
          ...state,
          [subscriberId]: {
            ...state[subscriberId],
            activities: [payload.result, ...state[subscriberId].activities]
          }
        };
      }
    }
  },

  defaultState
);
