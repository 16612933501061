import { handleActions } from 'redux-actions';

import {
  addCustomerDocument,
  fetchCustomerSubscriptionRequest,
  fetchCustomerSubscriptionResponse
} from 'shared/store/modules/subscriptions/actions';

const defaultState = {
  data: null,
  error: null
};

export default handleActions(
  {
    [fetchCustomerSubscriptionRequest]: () => defaultState,
    [fetchCustomerSubscriptionResponse]: {
      next: (state, action) => ({
        ...state,
        error: null,
        data: action.payload
      }),

      throw: (state, action) => ({
        ...state,
        data: null,
        error: {
          message: action.payload.message
        }
      })
    },

    [addCustomerDocument]: {
      next: (state, action) => {
        if (state && state.data && state.data.documents) {
          return {
            ...state,
            data: {
              ...state.data,
              documents: [action.payload, ...state.data.documents]
            }
          };
        }

        return state;
      }
    }
  },

  defaultState
);
