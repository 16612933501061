import { schema } from 'normalizr';

export const allocation = new schema.Entity(
  'allocations',
  {},
  {
    processStrategy: value => ({
      ...value,
      customer: value.customer,
      premiseId: value.id
    })
  }
);
export const allocations = [allocation];

allocation.define({
  customer: require('shared/store/modules/subscribers/schemas').subscriber,
  project: require('shared/store/modules/projects/schemas').project
});
