import Model from './Model';

class User extends Model {
  firstName = '';
  lastName = '';
  phoneNumber = '';
  title = '';

  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(User.fromJSON);
    }

    const portfolio = new User();

    return portfolio.mapValues(portfolio, json);
  }
}

export default User;
