import { PropsWithChildren } from 'react';
import { Global, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { selectThemeColors } from 'shared/store/modules/theme/selectors';

export function Theme(props: PropsWithChildren<{}>) {
  const { children } = props;

  const colors: { [key: string]: string } = useSelector(selectThemeColors);
  const variables = Object.keys(colors).map(key => ({
    [`--theme-${key}`]: colors[key]
  }));

  return (
    <ThemeProvider theme={colors}>
      <Global
        styles={css`
          :root {
            ${variables}
          }

          html,
          body,
          #root {
            @media (min-width: 720px) {
              overflow: hidden;
            }
          }
        `}
      />

      {children}
    </ThemeProvider>
  );
}
