export class RateTypeService {
  http;

  constructor(http) {
    this.http = http;
  }

  async fetchOne(id) {
    return this.http.get(`/utilities/rate-types/${id}`);
  }

  async create(utilityId, rateType) {
    return this.http.post('/utilities/rate-types', { utilityId, ...rateType });
  }

  async update(id, utilityId, rateType) {
    return this.http.put('/utilities/rate-types', {
      id,
      utilityId,
      ...rateType
    });
  }

  async delete(utilityRateTypeId) {
    return this.http.delete(`/utilities/rate-types/${utilityRateTypeId}`);
  }
}
