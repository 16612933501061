import { handleActions } from 'redux-actions';

import { updateContractResponse } from 'shared/store/modules/contracts/actions';
import { addEntities } from 'shared/store/modules/entities';

const defaultState = {};

export default handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => ({
        ...state,
        ...payload.entities.contracts
      })
    },

    [updateContractResponse]: {
      next: (state, { payload }) => {
        const contract = payload.entities.contracts[payload.result];

        return {
          ...state,
          [payload.result]: {
            ...state[payload.result],
            name: contract.name,
            legal: {
              ...state[payload.result].legal,
              preamble: contract.legal.preamble,
              title: contract.legal.title,
              signature: contract.legal.signature
            }
          }
        };
      }
    }
  },

  defaultState
);
