import { createAction } from 'redux-actions';

const MODULE = 'app/accounts';

const FETCH_ALL_ACCOUNTS_REQUEST = `${MODULE}/FETCH_ALL_ACCOUNTS_REQUEST`;
const FETCH_ALL_ACCOUNTS_RESPONSE = `${MODULE}/FETCH_ALL_ACCOUNTS_RESPONSE`;
export const SET_ACTIVE_ACCOUNT_ID = `${MODULE}/SET_ACTIVE_ACCOUNT_ID`;

export const fetchAllAccountsRequest = createAction(FETCH_ALL_ACCOUNTS_REQUEST);
export const fetchAllAccountsResponse = createAction(FETCH_ALL_ACCOUNTS_RESPONSE);
export const setActiveAccountId = createAction(SET_ACTIVE_ACCOUNT_ID);
