import { handleActions } from 'redux-actions';

import { customerProjectFetchRequest, customerProjectFetchResponse } from 'shared/store/modules/projects/actions';

const defaultState = {
  data: null,
  error: null
};

export default handleActions(
  {
    [customerProjectFetchRequest]: () => defaultState,
    [customerProjectFetchResponse]: {
      next: (state, action) => ({
        ...state,
        error: null,
        data: action.payload
      }),

      throw: (state, action) => ({
        ...state,
        data: null,
        error: {
          message: action.payload.message
        }
      })
    }
  },

  defaultState
);
