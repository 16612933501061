import { createSelector } from 'reselect';
import CustomerAllocationStatus from 'shared/models/enums/CustomerAllocationStatus';

function selectActiveAccountId(state) {
  return state.accounts.activeId;
}

function selectAllAccountsById(state) {
  return state.accounts.byId;
}

export const selectAllAccounts = createSelector([selectAllAccountsById], accountsById =>
  Object.keys(accountsById).map(key => accountsById[key])
);

export const selectNumberOfAccounts = createSelector(
  [selectAllAccountsById],
  accountsById => Object.keys(accountsById).length
);

export const selectActiveAccount = createSelector(
  [selectAllAccountsById, selectActiveAccountId],
  (accountsById, activeId) => accountsById[activeId] || {}
);

export const selectAccountId = createSelector([selectActiveAccount], account => account.id);

export const selectAccountRole = createSelector([selectActiveAccount], account => account.role);

export const selectAccountType = createSelector([selectActiveAccount], account => account.customerType);

export const selectIsUserAllocated = createSelector(
  [selectActiveAccount],
  account => account.customerAllocationStatus === CustomerAllocationStatus.Allocated
);

export const selectIsUserPreallocated = createSelector(
  [selectActiveAccount],
  account => account.customerAllocationStatus === CustomerAllocationStatus.PreAllocated
);

export const selectIsUserUnallocated = createSelector(
  [selectActiveAccount],
  account =>
    account.customerAllocationStatus === CustomerAllocationStatus.Unallocated ||
    account.customerAllocationStatus === CustomerAllocationStatus.Expired
);

export const selectCustomerType = createSelector([selectActiveAccount], account => account.customerType);

export const selectNextStepToComplete = createSelector([selectActiveAccount], account => account.nextStepToComplete);
