import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

import { AuthLayout } from 'shared/components/AuthLayout';
import { forgotPassword } from 'shared/store/modules/account/effects';

import { ForgotPasswordForm } from './forms/ForgotPasswordForm';

export function ForgotPassword() {
  const dispatch = useDispatch();
  const [error, setError] = useState<string | undefined>(undefined);

  function handleSubmit(username: string) {
    return dispatch(forgotPassword(username));
  }

  return (
    <AuthLayout title="Forgot Password">
      {!!error && (
        <Alert className="text-center" color="danger">
          {error}
        </Alert>
      )}

      <ForgotPasswordForm onError={setError} onSubmit={handleSubmit} />

      <small className="d-block text-center mt-3">
        <Link to="/login">Back to Login</Link>
      </small>
    </AuthLayout>
  );
}
