import { AxiosResponse, AxiosError } from 'axios';

import { AppThunk } from 'shared/store/types';

import { auth0Response, renewSessionRequest, renewSessionResponse } from './actions';
import { selectRefreshToken } from './selectors';
import { LoginResponse } from './types';

export function auth0Login(payload: any, userId: string): AppThunk<Promise<string>> {
  return function auth0LoginThunk(dispatch, _getState) {
    return new Promise<string>((resolve, _reject) => {
      dispatch(auth0Response({ ...payload, userId }));

      return resolve(payload.access_token);
    });
  };
}

export function renewSession(): AppThunk<Promise<LoginResponse>> {
  return function renewSessionThunk(dispatch, getState, { api }) {
    return new Promise<LoginResponse>((resolve, reject) => {
      dispatch(renewSessionRequest());

      const refreshToken = selectRefreshToken(getState());

      return api.auth
        .renewSession(refreshToken)
        .then(({ data }: AxiosResponse<LoginResponse>) => {
          dispatch(renewSessionResponse(data));
          return resolve(data);
        })
        .catch((error: AxiosError) => {
          dispatch(renewSessionResponse(error));
          return reject({ error: 'Session has expired' });
        });
    });
  };
}
