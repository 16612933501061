export class ReferralCodeService {
  http;

  constructor(http) {
    this.http = http;
  }

  async fetchAll() {
    return this.http.get('/referralcodes');
  }

  async fetchOne(id) {
    return this.http.get(`/referralcodes/${id}`);
  }

  async create(referralCode) {
    return this.http.post('/referralcodes', referralCode);
  }

  async update(referralCode) {
    return this.http.put('/referralcodes', referralCode);
  }

  async delete(referralCodeId) {
    return this.http.delete('/referralcodes', {
      params: {
        referralCodeId
      }
    });
  }
}
