export class ProjectService {
  http;

  constructor(http) {
    this.http = http;
  }

  // GET /projects
  async fetchAll(params) {
    return this.http.get('/projects', {
      params
    });
  }

  async fetchAutoAllocatableCustomers(utilityId) {
    return this.http.get(`/utilities/${utilityId}/projects/auto-allocatable`);
  }

  async fetchOne(id) {
    return this.http.get(`/projects/${id}`);
  }

  async fetchDetails(id) {
    return this.http.get(`/projects/${id}/details`);
  }

  async fetchAllocations(id) {
    return this.http.get(`/projects/${id}/allocations`);
  }

  async create(project) {
    return this.http.post('/projects', project);
  }

  async update(id, project) {
    return this.http.put('/projects', { id, ...project });
  }

  async delete(projectId) {
    return this.http.delete(`/projects/${projectId}`);
  }

  async uploadImage(formData) {
    return this.http.post('/projects/upload-image', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  // TODO: Hook these up to the correct endpoints - and hook up `type`
  async exportPaymentsLifetime(id, type) {
    return this.http.get(`/billing/project-billing-detail/${id}/${type}/lifetime/export`);
  }

  async exportPaymentsByMonth(id, year, month, type) {
    return this.http.get(`/billing/project-billing-detail/${id}/${year}/${month}/export`);
  }

  async exportProductionLifetime(id, type) {
    return this.http.get(`/billing/wip-non-existing/project-production-detail/${id}/lifetime/${type}/export`);
  }

  async exportProductionByMonth(id, year, month, type) {
    return this.http.get(`/billing/wip-non-existing/project-production-detail/${id}/${year}/${month}/${type}/export`);
  }

  async exportProductionVariance(args = {}) {
    args.renderMasthead = args.renderMasthead || false;
    return this.http.get('/projects/readings/production-variance/export', {
      params: {
        beginDate: args.beginDate,
        endDate: args.endDate,
        portfolioId: args.portfolioId,
        projectId: args.projectId,
        renderMasthead: args.renderMasthead
      }
    });
  }
}
