import { LOCATION_CHANGE } from 'connected-react-router';
import { handleActions } from 'redux-actions';

import { validateRequest, importRequest, importResponse } from 'shared/store/modules/bulkedit/actions';

const defaultState = false;

export default handleActions(
  {
    [LOCATION_CHANGE]: () => defaultState,

    [validateRequest]: () => defaultState,

    [importRequest]: {
      next: () => defaultState,
      throw: () => true
    },

    [importResponse]: {
      next: () => true,
      throw: () => defaultState
    }
  },

  defaultState
);
