export default Object.freeze({
  0: 'Processing',
  1: 'Complete',
  2: 'Canceled',
  99: 'Error',
  Processing: 0,
  Complete: 1,
  Canceled: 2,
  Error: 99
});
