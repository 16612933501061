import Model from './Model';

class Subscription extends Model {
  annualEscalator = '';
  capacity = '';
  effectiveDate = '';
  expiryDate = '';
  name = '';
  paymentTerms = '';
  rateCapType = '';
  rateCeiling = '';
  rateFloor = '';
  startingRate = '';
  utilityId = '';

  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(Subscription.fromJSON);
    }

    const subscription = new Subscription();

    return subscription.mapValues(subscription, json);
  }
}

export default Subscription;
