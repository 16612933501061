import { handleActions } from 'redux-actions';

import {
  clearAllPortfoliosFilter,
  dashboardFetchResponse,
  fetchDashboardResponse,
  filterAllPortfolios,
  filterPortfolios
} from 'shared/store/modules/dashboard/actions';

const defaultState = {
  data: {},
  filteredPortfolios: [],
  loaded: false
};

export const reducer = handleActions(
  {
    [fetchDashboardResponse]: {
      next: (state, { payload }) => ({
        ...state,
        ...payload.entities.dashboard
      })
    },

    [dashboardFetchResponse]: {
      next: (state, action) => ({
        ...state,
        data: action.payload,
        loaded: true
      })
    },

    [filterPortfolios]: {
      next: (state, { payload }) => {
        let { filteredPortfolios } = state;

        return {
          ...state,
          hasFilteredPortfolios: true,
          filteredPortfolios: filteredPortfolios.includes(payload)
            ? filteredPortfolios.filter(portfolioId => portfolioId !== payload).filter(n => n)
            : filteredPortfolios.concat(payload)
        };
      }
    },

    [filterAllPortfolios]: {
      next: state => ({
        ...state,
        filteredPortfolios: state.data.portfolios ? state.data.portfolios : []
      })
    },

    [clearAllPortfoliosFilter]: {
      next: state => ({
        ...state,
        hasFilteredPortfolios: false,
        filteredPortfolios: []
      })
    }
  },

  defaultState
);
