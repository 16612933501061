import Model from './Model';

class Utility extends Model {
  address = '';
  address2 = '';
  city = '';
  contactEmail = '';
  contactFullName = '';
  contactPhone = '';
  id = '';
  isDisabled = false;
  name = '';
  programDescription = '';
  programName = '';
  programUrl = '';
  rateTypes = [];
  stateId = '';
  zipCode = '';
  premiseMaxAllocationPercent = 0.4;
  gardenMaxAllocationPercent = 1;
  minimumAllocationAmount = 1;
  minimumAllocationLowIncomeAmount = 0.5;
  requiresAdjacentCounty = false;
  shouldSuppressRateChoice = false;

  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(Utility.fromJSON);
    }

    const utility = new Utility();

    return utility.mapValues(utility, json);
  }
}

export default Utility;
