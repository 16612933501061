import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import styled from 'styled-components';

import Icon from 'shared/components/Icon';
import {
  dashboardPath,
  portfoliosPath,
  projectsPath,
  subscribersPath,
  utilitiesPath
} from 'shared/utilities/AdminPathHelper';

const NavIcon = styled(Icon)`
  margin-right: 10px;
`;

export const AdminNavigation = () => (
  <>
    <Nav className="navigation-primary" vertical>
      <NavItem>
        <NavLink exact tag={Link} to={dashboardPath()}>
          <NavIcon icon="dashboard" /> Dashboard
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink tag={Link} to={portfoliosPath()}>
          <NavIcon icon="folder" /> Portfolios
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink tag={Link} to={projectsPath()}>
          <NavIcon icon="layers" /> Projects
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink tag={Link} to={subscribersPath()}>
          <NavIcon icon="people" /> Subscribers
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink tag={Link} to={utilitiesPath()}>
          <NavIcon icon="location_city" /> Utilities
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink tag={Link} to="/admin/promos">
          <NavIcon icon="campaign" /> Promos
        </NavLink>
      </NavItem>
    </Nav>

    <Nav className="navigation-secondary" vertical>
      <NavItem>
        <NavLink tag={Link} to="/admin/invoices">
          <NavIcon icon="assignment" /> Statements
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink tag={Link} to="/admin/reports">
          <NavIcon icon="grid_on" /> Reports
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink tag={Link} to="/admin/tools">
          <NavIcon icon="build" /> Tools
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink tag={Link} to="/admin/settings">
          <NavIcon icon="settings" /> Settings
        </NavLink>
      </NavItem>
    </Nav>
  </>
);
