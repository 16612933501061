import Model from './Model';

class Schedule extends Model {
  creditRate = '';
  effectiveDate = '';
  expiryDate = '';

  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(Schedule.fromJSON);
    }

    const schedule = new Schedule();

    return schedule.mapValues(schedule, json);
  }
}

export default Schedule;
