import { combineReducers } from 'redux';

import activeTicket from './activeTicket';
import byId from './byId';
import error from './error';
import isComplete from './isComplete';
import isImporting from './isImporting';
import isValidating from './isValidating';

export const reducer = combineReducers({
  activeTicket,
  byId,
  error,
  isComplete,
  isImporting,
  isValidating
});
