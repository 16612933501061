import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchContent } from 'shared/store/modules/content/effects';

export function useContent(slug) {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    dispatch(fetchContent(slug))
      .then(setData, setError)
      .finally(() => setIsLoading(false));
  }, [dispatch, slug]);

  return { data, error, isLoading };
}
