import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

type IconProps = {
  className?: string;
  icon: string;
  style?: object;
};

const Icon = ({ className, icon, style, ...rest }: IconProps) => {
  const I = styled.i`
    display: inline-block;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    text-align: left;
  `;

  return (
    <I className={classNames('material-icons', className)} style={style}>
      {icon}
    </I>
  );
};

export default Icon;
