import { handleActions } from 'redux-actions';

import { fetchContractsResponse } from 'shared/store/modules/contracts/actions';

const defaultState = [];

export default handleActions(
  {
    [fetchContractsResponse]: {
      next: (_, { payload }) => [...payload.result]
    }
  },

  defaultState
);
