export class UserService {
  http;

  constructor(http) {
    this.http = http;
  }

  async fetchAdmins() {
    return this.http.get('/users/admin-users');
  }

  // POST /users/register
  async registerUser(model) {
    return this.http.post('/users/register', model);
  }

  // POST /users/forgot-password
  async forgotPassword(username) {
    return this.http.post('/users/forgot-password', null, {
      params: {
        username,
        shouldReset: ''
      }
    });
  }

  // POST /users/reset-password
  async resetPassword(model) {
    return this.http.post('/users/reset-password', model);
  }

  async changePasswordRequest() {
    return this.http.post('/users/change-password');
  }

  // GET /users
  async getAccount() {
    return this.http.get('/users');
  }

  // GET /users/{userId}
  async getAccountByUserId(userId) {
    return this.http.get(`/users/${userId}`);
  }

  // PUT /users
  async updateAccount(model) {
    return this.http.put('/users', model);
  }

  // GET /users/customers/{customerId}
  async getAccountUsers(customerId) {
    return this.http.get(`/users/customers/${customerId}`);
  }

  // GET /users/invited-subscribers/export
  async getInvitedUsersExport() {
    return this.http.get('/users/invited-subscribers/export', {
      responseType: 'blob'
    });
  }

  // PATCH /users/disable
  async disableUser(model) {
    return this.http.patch('/users/disable', model);
  }

  // PATCH /users/disable
  async enableUser(userId) {
    return this.http.patch('/users/disable', { userId });
  }

  // POST /users/resend-invite
  async resendInvite(email) {
    return this.http.post('/users/resend-invite', null, {
      params: {
        email
      }
    });
  }

  // GET users/roles
  async fetchUserRoles() {
    return this.http.get('/users/roles');
  }

  // POST /users/roles/assign
  async assignUserRole(customerId, userId, roleName) {
    return this.http.post('/users/roles/assign', { customerId, userId, roleName });
  }

  // PATCH /users/update-email
  async updateUserEmail(userDetailId, emailAddress) {
    return this.http.patch('/users/update-email', null, {
      params: {
        userDetailId,
        emailAddress
      }
    });
  }

  async fetchHistory(userId) {
    return this.http.get(`/users/history/${userId}`);
  }

  async exportAllUsers(args = {}) {
    args.renderMasthead = args.renderMasthead || false;
    return this.http.get('/users/export', {
      params: {
        renderMasthead: args.renderMasthead,
        userRole: args.userRoleId
      }
    });
  }

  async exportAllSubscriberUsers(args = {}) {
    args.renderMasthead = args.renderMasthead || false;

    return this.http.get('/users/subscribers/export', {
      params: {
        renderMasthead: args.renderMasthead,
        portfolioId: args.portfolioId
      }
    });
  }

  async fetchAccounts() {
    return this.http.get('/users/accounts');
  }

  async unlockUser(userId) {
    return this.http.post('/users/unlock', null, {
      params: {
        userId
      }
    });
  }

  async updateDefaultUser(customerId, email, isDefault) {
    return this.http.put(`/customers/${customerId}/default-status`, null, {
      params: {
        email,
        isDefault
      }
    });
  }
}
