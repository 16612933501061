import merge from 'lodash/merge';
import { handleActions } from 'redux-actions';

import { addEntities, deleteEntities } from 'shared/store/modules/entities';
import { deletePremiseScheduleResponse } from 'shared/store/modules/premises/actions';

const defaultState = {};

export default handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => {
        return merge({}, state, payload.entities.schedules);
      }
    },

    [deleteEntities]: {
      next: (state, { payload }) => {
        const newState = Object.assign({}, state);
        delete newState[payload.id];
        return newState;
      }
    },

    [deletePremiseScheduleResponse]: {
      next: (state, { payload }) => {
        const schedule = state[payload.id];
        return merge({}, state, {
          [schedule.id]: {
            deleted: true,
            ...schedule
          }
        });
      }
    }
  },

  defaultState
);
