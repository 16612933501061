import { css } from '@emotion/react';

const styles = () => css`
  .wrapper {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
    @media (max-width: 720px) {
      height: 100vh;
    }
    @media (min-width: 721px) {
      overflow: hidden;
    }
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  .layout {
    @media (max-width: 720px) {
      position: relative;
      top: 62px;
    }
    @media (min-width: 721px) {
      display: flex;
    }
    align-items: stretch;
    height: calc(100vh - 62px);
  }
`;

export default styles;
