import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'shared/store/types';
import { UserRole } from 'shared/models';
import { selectActiveAccount } from 'shared/store/modules/accounts/selectors';
import { fetchCustomer } from './effects';

export function useActiveCustomer() {
  const dispatch = useDispatch<AppDispatch>();
  const account = useSelector(selectActiveAccount);

  useEffect(() => {
    if (account.role === UserRole['Account Manager'] || account.role === UserRole['Read Only']) {
      dispatch(fetchCustomer(account.id));
    }
  }, [account.id, account.role, dispatch]);
}
