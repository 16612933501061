export function selectThemeFavicon(state) {
  return state.theme.favIcon;
}

export function selectThemeImage(state) {
  return state.theme.bgImage;
}

export function selectThemeLogo(state) {
  return state.theme.logo;
}

export function selectPartnerLogo(state) {
  return state.theme.residential.partnerLogo;
}

export function selectThemeColors(state) {
  const { primaryColor, secondaryColor } = state.theme;

  return {
    primary: primaryColor,
    secondary: secondaryColor
  };
}
