import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { ApplicationLayout } from 'shared/components/ApplicationLayout';
import { UserRole } from 'shared/models';
import { selectRole, selectIsStreamlinedProspect } from 'shared/store/modules/auth/selectors';

import { useAuth0Session } from 'shared/store/modules/auth/hooks';
import Loading from '../Loading';
import { useAccounts } from 'shared/store/modules/accounts/hooks';


const AdminSession = withAuthenticationRequired(() => {
  const userRole = useSelector(selectRole);
  const { renewSession } = useAuth0Session();

  useEffect(() => {
    if (!userRole) {
      renewSession()
    }
  }, [userRole, renewSession])

  if (!userRole) return <Loading />

  return <ApplicationLayout />;
}, { returnTo: window.location.pathname });


const UserSession = withAuthenticationRequired(() => {
  const userRole = useSelector(selectRole);
  const isStreamlinedProspect = useSelector(selectIsStreamlinedProspect);
  const { accounts, activeAccountId } = useAccounts();
  const { startSession, isRenewing } = useAuth0Session();

  useEffect(() => {
    if (!userRole && !isRenewing) {
      startSession()
    }
    // eslint-disable-next-line
  }, [userRole, isRenewing])

  if (!userRole || isRenewing || accounts.length === 0) return <Loading />

  if (userRole === UserRole.Prospect) {
    if (isStreamlinedProspect === true) return <Redirect to="/signup/next" />;
    return <Redirect to="/residential/next" />;
  }

  if (accounts.length > 1 && !activeAccountId) return <Redirect to="/accounts" />

  return <ApplicationLayout />;
}, { returnTo: window.location.pathname });


export const Session = () => {
  const { error, isAdmin } = useAuth0Session();

  if (error) {
    console.error(error);

    // @ts-ignore
    return <Redirect to={`/login?error=${error.message}&error_type=${error.error}`} />;
  }

  if (isAdmin()) return <AdminSession />
  return <UserSession />
}
