import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  padding: 25px;
`;

type LogoProps = {
  name: string;
  url: string;
};

export const Logo = ({ name, url }: LogoProps) => (
  <StyledLink className="brand" to="/">
    <img alt={`${name} Logo`} className="img-fluid" src={url} title={name} />
  </StyledLink>
);
