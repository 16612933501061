import React from 'react';
import { useSelector } from 'react-redux';
import { faCastle, faInfoCircle, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getShardType } from 'shared/store/modules/provider/selectors';
import styles from './EnvironmentHeader.styles';

export const EnvironmentHeader = () => {
  const { isSeedShard, isDemoShard, isSandboxShard } = useSelector(getShardType);

  const Seed = () => {
    return (
      <div className="banner seed">
        <FontAwesomeIcon icon={faExclamationTriangle} />
        Seed Data
      </div>
    )
  }
  const Sandbox = () => {
    return (
      <div className="banner sandbox">
        <FontAwesomeIcon icon={faCastle} />
        Sandbox
      </div>
    )
  }
  const Demo = () => {
    return (
      <div className="banner demo">
        <FontAwesomeIcon icon={faInfoCircle} />
        Demo
      </div>
    )
  }

  if (!isSeedShard && !isDemoShard && !isSandboxShard) return null;

  return (
    <div css={styles}>
      {isSeedShard && (<Seed />)}
      {isDemoShard && (<Demo />)}
      {isSandboxShard && (<Sandbox />)}
    </div>
  )
}
