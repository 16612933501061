import invert from 'lodash/invert';

const CustomerType = {
  Commercial: 0,
  Residential: 1,
  All: 2,
  Provisional: 3
};

export default Object.freeze({
  ...CustomerType,
  ...invert(CustomerType)
});
