import { handleActions } from 'redux-actions';
import { fetchAllPortfoliosRequest, fetchAllPortfoliosResponse } from 'shared/store/modules/portfolios/actions';
import { AsyncStatus } from 'shared/models/enums/AsyncStatus';

const defaultState = AsyncStatus.Idle;

export default handleActions(
  {
    [fetchAllPortfoliosRequest.toString()]: {
      next: () => AsyncStatus.Pending
    },

    [fetchAllPortfoliosResponse.toString()]: {
      next: () => AsyncStatus.Fulfilled,
      throw: () => AsyncStatus.Rejected
    }
  },

  defaultState
);