import {
  fetchUserRequest,
  fetchUserResponse,
  updateUserProfileRequest,
  updateUserProfileResponse
} from 'shared/store/modules/user/actions';
import toast from 'shared/utilities/toast';

export const fetchUser = id => async (dispatch, _, { api }) => {
  dispatch(fetchUserRequest());

  try {
    const { data } = await api.user.getAccountByUserId(id);

    return dispatch(fetchUserResponse(data));
  } catch (err) {
    return dispatch(fetchUserResponse(err));
  }
};

export const updateUserProfile = user => async (dispatch, _, { api }) => {
  dispatch(updateUserProfileRequest());

  try {
    await api.user.updateAccount(user);
    toast.success('Profile updated');
    return dispatch(updateUserProfileResponse(user));
  } catch (err) {
    toast.error(err);
    return dispatch(updateUserProfileResponse(err));
  }
};
