export function getProviderClarityId(state) {
  return state.provider.clarityId;
}

export function getProviderEmail(state) {
  return state.provider.emailAddress;
}

export function getProviderLegalName(state) {
  return state.provider.legalName;
}

export function getProviderName(state) {
  return state.provider.name;
}

export function getProviderPhoneNumber(state) {
  return state.provider.phoneNumber;
}

export function getProviderHostname(state) {
  return state.provider.hostname;
}

export function getPasswordRules(state) {
  const { password } = state.provider;

  if (password && password.rules) {
    return password.rules;
  }

  return {};
}

export function getShardType(state) {
  return {
    isDemoShard: state.provider.isDemoShard,
    isSeedShard: state.provider.isSeedShard,
    isSandboxShard: state.provider.isSandboxShard
  };
}

export function getAuth0Config(state) {
  return {
    clientId: state.provider.auth0ClientId,
    organizationId: state.provider.auth0OrganizationId
  }
}

export function getProviderUrl({ provider: { resourceHostname } }) {
  if (process.env.NODE_ENV === 'development') {
    return window.location.origin;
  }
  return `https://${resourceHostname}`;
}

export function selectProviderPortfolioDaysToMature(state) {
  const payments = state.provider.payments || {};

  return payments.portfolioDaysToMature;
}
