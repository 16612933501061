import { handleActions } from 'redux-actions';

import {
  fetchUsersRequest,
  fetchUsersResponse,
  disableResponse,
  updateUserResponse,
  assignUserRoleResponse,
  updateUserEmailResponse,
  registerUserResponse
} from 'shared/store/modules/users/actions';

const defaultState = [];

export default handleActions(
  {
    [fetchUsersRequest]: () => defaultState,
    [fetchUsersResponse]: (_state, action) => {
      return action.payload;
    },

    [disableResponse]: {
      next: (state, { payload }) => {
        return state.filter(user => {
          return user.id !== payload.id;
        });
      }
    },

    [updateUserResponse]: {
      next: (state, { payload }) => {
        return state.map(user => {
          if (user.id !== payload.id) {
            return user;
          }

          return {
            ...user,
            ...payload
          };
        });
      }
    },

    [assignUserRoleResponse]: {
      next: (state, { payload }) => {
        return state.map(user => {
          if (user.id !== payload.id) {
            return user;
          }

          return {
            ...user,
            role: payload.role
          };
        });
      }
    },

    [updateUserEmailResponse]: {
      next: (state, { payload }) => {
        return state.map(user => {
          if (user.id !== payload.id) {
            return user;
          }

          return {
            ...user,
            email: payload.email
          };
        });
      }
    },

    [registerUserResponse]: {
      next: (state, { payload }) => {
        return [...state, payload];
      }
    }
  },

  defaultState
);
