import { createAction } from 'redux-actions';

const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
const FETCH_USER_RESPONSE = 'FETCH_USER_RESPONSE';
const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
const UPDATE_USER_PROFILE_RESPONSE = 'UPDATE_USER_PROFILE_RESPONSE';

export const fetchUserRequest = createAction(FETCH_USER_REQUEST);
export const fetchUserResponse = createAction(FETCH_USER_RESPONSE);
export const updateUserProfileRequest = createAction(UPDATE_USER_PROFILE_REQUEST);
export const updateUserProfileResponse = createAction(UPDATE_USER_PROFILE_RESPONSE);
