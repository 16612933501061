import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { getProviderClarityId, getProviderName } from 'shared/store/modules/provider/selectors';
import { selectThemeFavicon } from 'shared/store/modules/theme/selectors';
import { selectUserId } from 'shared/store/modules/auth/selectors';

declare global {
  interface Window {
    clarity: any;
  }
}

export function Head() {
  const providerName = useSelector(getProviderName);
  const clarityId = useSelector(getProviderClarityId);
  const favicon = useSelector(selectThemeFavicon);
  const userId = useSelector(selectUserId);

  const titleTemplate = providerName ? `%s \u2014 ${providerName}` : '%s';

  const getClarityId = () => {
    return clarityId ? clarityId : process.env.REACT_APP_DEFAULT_CLARITY_ID;
  };

  useEffect(() => {
    if (userId && getClarityId()) {
      window.setTimeout(() => {
        window.clarity('identify', userId);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clarityId, userId]);

  return (
    <Helmet defaultTitle={providerName} titleTemplate={titleTemplate}>
      <link href={favicon} rel="shortcut icon" type="image/x-icon" />
      {getClarityId() && (
        <script type="text/javascript">
          {`(function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${getClarityId()}");`}
        </script>
      )}
    </Helmet>
  );
}
