import Model from './Model';

class Reading extends Model {
  date = undefined;
  dasAmount = '';
  utilityAmount = '';
  month = '';
  year = '';

  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(Reading.fromJSON);
    }

    const rateType = new Reading();

    return rateType.mapValues(rateType, json);
  }
}

export default Reading;
