import Model from './Model';

class Allocation extends Model {
  allocation = '';
  projectId = '';
  premiseId = '';
  allocationStatus = '';

  static fromJSON(json) {
    if (Array.isArray(json)) {
      return json.map(Allocation.fromJSON);
    }

    const allocation = new Allocation();

    return allocation.mapValues(allocation, json);
  }
}

export default Allocation;
