import { handleActions } from 'redux-actions';

import {
  fetchPortfolioSettlementDetailsRequest,
  fetchPortfolioSettlementDetailsResponse
} from 'shared/store/modules/portfolios/actions';

const initialState = false;

export const isFetching = handleActions(
  {
    [fetchPortfolioSettlementDetailsRequest]: () => true,
    [fetchPortfolioSettlementDetailsResponse]: () => false
  },

  initialState
);
