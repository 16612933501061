import merge from 'lodash/merge';
import { handleActions } from 'redux-actions';

import { createAllocationResponse, deleteAllocationResponse } from 'shared/store/modules/allocations/actions';
import { addEntities, deleteEntities } from 'shared/store/modules/entities';
import { uploadProjectImageRequest, uploadProjectImageResponse } from 'shared/store/modules/projects/actions';

const defaultState = {};

export default handleActions(
  {
    [addEntities]: {
      next: (state, { payload }) => {
        return merge({}, state, payload.entities.projects);
      }
    },

    [deleteEntities]: {
      next: (state, { payload }) => {
        const newState = Object.assign({}, state);
        delete newState[payload.id];
        return newState;
      }
    },

    [uploadProjectImageRequest]: {
      next: (state, { payload }) => {
        return {
          ...state,
          isUploading: true
        };
      }
    },

    [uploadProjectImageResponse]: {
      next: (state, { payload }) => {
        const { photo, id } = payload;

        return {
          ...state,
          isUploading: false,
          [id]: {
            ...state[id],
            photo
          }
        };
      },

      throw: state => {
        return {
          ...state,
          isUploading: false
        };
      }
    },

    [createAllocationResponse]: {
      next: (state, { payload }) => {
        const { projectId } = payload.entities.allocations[payload.result];

        return {
          ...state,
          [projectId]: {
            ...state[projectId],
            allocations: [...state[projectId].allocations, payload.result]
          }
        };
      }
    },

    [deleteAllocationResponse]: {
      next: (state, { payload }) => {
        const newState = { ...state };

        let allocationList = newState[payload.projectId].allocations;

        let index = allocationList.indexOf(payload.allocationId);

        allocationList.splice(index, 1);

        return newState;
      }
    }
  },

  defaultState
);
