import { handleActions } from 'redux-actions';

import { fetchHistoryResponse } from 'shared/store/modules/history/actions';

const defaultState = [];

export const reducer = handleActions(
  {
    [fetchHistoryResponse]: {
      next: (_, { payload }) => payload
    }
  },

  defaultState
);
